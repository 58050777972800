import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom'
import useGlobal from "../store";
import axios from 'axios';
import { get } from 'lodash/fp';
import FlexRow from '../components/common/FlexRow';
import ContentWrapper from '../components/common/ContentWrapper';
import StyledLink from '../components/common/StyledLink';
import RoundButton from '../components/common/RoundButton';
import InputField from '../components/common/InputField';

const ErrorMessage = styled.div`
    font-weight: bold;
    color: red; 
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    top: 25px;
    position: relative;
`;

const Links = styled.div`
    text-align: center;
    margin-top: 50px;
`;

function Login(props) {

    const defaultEmail = process.env.NODE_ENV === 'development' ? 'gal.tamir@gmail.com' : ''

    const { t } = useTranslation();

    const [email, setEmail] = useState(defaultEmail);
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [needEmailValidate, setNeedEmailValidate] = useState(false);

    const history = useHistory();
    const [globalState, globalActions] = useGlobal();

    const urlSearchParams = new URLSearchParams(history.location.search);
    const url = urlSearchParams.get('url');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setErrorMessage('');
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setErrorMessage('');
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('/api/login', { email, password })
            .then(response => {
                const { accessToken } = response.data;
                const role = get('data.data.role', response);
                const isSelfPayment = get('data.data.isSelfPayment', response);
                const userId = get('data.data.userId', response);
                localStorage.setItem('token', accessToken);
                globalActions.setIsLoggedIn(true);
                globalActions.setLoggedInRole(role);
                globalActions.setUserId(userId);
                globalActions.setIsSelfPayment(isSelfPayment);
                history.push(url || '/my-games');    
            })
            .catch(err => {
                const errorMessage = get('response.data.message', err);
                setErrorMessage(errorMessage);

                const errorMessageCode = get('response.data.messageCode', err);
                setNeedEmailValidate(errorMessageCode === 'need_email_validate');
            })
    };

    return (
        <React.Fragment>
            <ContentWrapper withButton>
                <h1>{t('page.title.login')}</h1>

                <form onSubmit={handleSubmit} style={{ width: '300px', margin: '0 auto' }} >
                    <FlexRow>
                        <InputField label={t('email')} value={email} onChange={handleEmailChange} placeholder={t('email')} type="email" required />
                    </FlexRow>

                    <FlexRow>
                        <InputField label={t('password')} type="password" value={password} onChange={handlePasswordChange} placeholder={t('password')} required />
                    </FlexRow>

                    <ErrorMessage>
                        {errorMessage} &nbsp;
                </ErrorMessage>
                    {
                        needEmailValidate && (
                            <FlexRow>

                                <div style={{ paddingTop: '20px;' }}>
                                    {t('login.resend.validation.email')} &nbsp;
                                <Link to="/validate-email/resend">{t('click.here')}</Link>
                                </div>
                            </FlexRow>
                        )
                    }

                    <ButtonWrapper>
                        <RoundButton type="submit" value="submit" width={190} text={t('submit')} />
                    </ButtonWrapper>
                </form>

            </ContentWrapper>

            <Links>
                <StyledLink to="/" >{t('page.title.home')}</StyledLink>
                <StyledLink to="/forgot-password" >{t('page.title.forgot.password')}</StyledLink>
                <StyledLink to="/signup" >{t('page.title.registration')}</StyledLink>
            </Links>
        </React.Fragment>

    )
}

export default Login;