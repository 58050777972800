import React from 'react';
import styled from '@emotion/styled';
import FlexCol from './FlexCol';
import Select from 'react-select';


const StyledLabel = styled.label`
    width: 100%;
`;

const StyledFlexCol = styled(FlexCol)`
    width: 100%; 
`;

const Spacer = styled.div`
    height: 10px;
`;

const FlexRow = styled.div`
    display: inline-block;
    width: 100%;
`;

const StyledInput = styled.input`
    display: block;
    width: 100%;
    background-color: #ddf1f0;
    ${props => props.isWhite ? 'background-color: white;' : ''}
    font-size: 1.5em;
    height: 34px;
    padding: 0 15px;
    border-radius: 17px;
    border: 1px solid silver;
    box-shadow: inset 1px 1.5px 10px 0px rgba(0,0,0,0.15);
    &:focus {
        outline: none;
    }
`;

function SelectField({ label, value = '', options, onChange, placeholder, errorMessage, isWhite, ...rest }) {

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            //   width: state.selectProps.width,
            //   borderBottom: '1px dotted pink',
            //   color: state.selectProps.menuColor,
            //   padding: 20,
            backgroundColor: '#ddf1f0'
        }),

        control: (provided, { selectProps: { width } }) => ({
            ...provided,
            fontSize: '1.5em',
            backgroundColor: '#ddf1f0',
            borderRadius: '25px',
            border: '1px solid silver',
            boxShadow: 'inset 1px 1.5px 10px 0px rgba(0,0,0,0.15)'
        }),

        singleValue: (provided, state) => {
            //   const opacity = state.isDisabled ? 0.5 : 1;
            //   const transition = 'opacity 300ms';
            return { ...provided };
            // return { ...provided };
        }
    }

    const selectedValue = options.find(opt => opt.value === value);
    return (
        <StyledFlexCol>
            <StyledLabel  > {label}
                <FlexRow>
                    <Select
                        styles={customStyles}
                        value={selectedValue}
                        options={options}
                        onChange={onChange}
                        placeholder={placeholder}
                        {...rest}
                    />
                </FlexRow>
            </StyledLabel>
            {/* <div>{errorMessage}&nbsp;</div> */}
            <Spacer></Spacer>
        </StyledFlexCol>
    );
}

export default SelectField;