import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import ContentWrapper from './../components/common/ContentWrapper';
import GameFeedback from './../components/GameFeedback';

const PageTitle = styled.div`
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    padding: 10px 0;
`;

function GameFeedbackPage(props) {

    const { t } = useTranslation();

    const { match: { params: { gameId, token } } } = props;
    console.log({gameId, token});
    
    if (!gameId || !token) {
        return null;
    }

    return (
        <React.Fragment>
            <ContentWrapper>
                <PageTitle>
                    { t('feedback.title') }
                </PageTitle>
                { t('feedback.text1')}
                <br />
                { t('feedback.text2')}
                <br /><br /><br />
                <GameFeedback gameId={ gameId } />

            </ContentWrapper>
        </React.Fragment>
    );
}

export default GameFeedbackPage;