import React from 'react';
import styled from '@emotion/styled';


const StyledPin = styled.div`
    border-radius: 50%;
    width: 26px;
    height: 26px;
    padding: 4px;
    
    position: relative;
    right: -13px;
    top: -13px;

    background: ${ props => props.color };
    border: 1px solid black;
    color: black;
    text-align: center;

    font: 18px Arial, sans-serif;
`;

function MapPin({text, onClick, color='red'}) {
    return (
        <StyledPin onClick={ onClick } color={ color }>{text}</StyledPin>
    );
  }

  export default MapPin;