import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import FeedbackButtonActive from './../images/feedback/feedback-button-active.png';
import FeedbackButtonDisabled from './../images/feedback/feedback-button-disabled.png';

import FlexRow from './common/FlexRow';
import EditorButton from './common/EditorButton';
import { useEffect } from 'react';

const FeedbackWrapper = styled.div`
    padding: 10px 20px; 
    margin: 0 auto; 
    
    & h1 {
        padding-bottom: 5px; 
    }
`;

const FlexRowLtr = styled(FlexRow)`
    direction: ltr;
    flex: 1;
    justify-content: space-between;
`;
const FlexRowNext = styled(FlexRow)`
    flex: 1;
    justify-content: flex-end;
    padding: 0 0 10px 0;
`;

const Star = styled.div`
    flex: 1;
    text-align: center;
    /* width: 30px;
    height: 30px; 
    background-color: cyan; */
    cursor: pointer;

    &.selected {
        background-color: orange;
    }
/* 
    &:hover {
        background-color: orange;
    } */

`;

function Feedback({ header, text, optionsText = [], onClick }) {

    const { t } = useTranslation();
    const [selectedValue, setSelectedValue] = useState();

    useEffect(() => {
        setSelectedValue(null);
    }, [header])

    let option1Text, option3Text, option5Text = '';
    if (optionsText.length === 2) {
        option1Text = optionsText[0];
        option5Text = optionsText[1];
    }

    if (optionsText.length === 3) {
        option1Text = optionsText[0];
        option3Text = optionsText[1];
        option5Text = optionsText[2];
    }

    return (
        <React.Fragment>
            <FeedbackWrapper>
                <h1>{header}</h1>
                <FlexRow style={{ paddingBottom: '5px', justifyContent: 'center' }}>
                    {text}
                </FlexRow>
                <FlexRowLtr>
                    {
                        [...Array(5)].map((e, i) => (
                            <Star key={i} onClick={() => setSelectedValue(i + 1)}>
                                <img width="44px" height="40px" src={selectedValue && selectedValue >= i + 1 ? FeedbackButtonActive : FeedbackButtonDisabled} />
                            </Star>
                        ))
                    }
                </FlexRowLtr>
                <FlexRowLtr>
                    <div>{option1Text}</div>
                    <div>{option3Text}</div>
                    <div>{option5Text}</div>
                </FlexRowLtr>
                <FlexRowNext style={{ justifyContent: 'center' }}>
                    <EditorButton disabled={!selectedValue} onClick={() => selectedValue && onClick(selectedValue)} >{t('continue')}</EditorButton>
                </FlexRowNext>
            </FeedbackWrapper>
        </React.Fragment>
    )
}

export default Feedback;