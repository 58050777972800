import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import ContentWrapper from '../components/common/ContentWrapper';
import FlexRow from './common/FlexRow';
import UIfx from 'uifx';
import okSound from '../sounds/ok_beep.mp3';
import wrongSound from '../sounds/bad_answer.mp3';

import axios from 'axios';

import GoodIcon from './../images/good_icon.png';
import BadIcon from './../images/bad_icon.png';

import useGlobal from "../store";
// import { getGameIntroPage, getGameStartTime, getGameEndTime } from '../store/selectors';
import RoundButton from './common/RoundButton';
import { useEffect } from 'react';
import GamePayment from './GamePayment';
import EditorButton from './common/EditorButton';



const AnswerIcon = styled.img`
    width: 2em;
    height: 2em;
    padding: 0; 
`;

const CenteredFlexRow = styled(FlexRow)`
    justify-content: center;
    align-items: center;
`;

const Spacer = styled.div`
    width: 50px;
`;

const StyledInput = styled.input`
    display: block;
    background-color: #ddf1f0;
    /* font-size: 1.5em; */
    font-size: 24px;
    width: 190px;
    text-align: center;
    height: 34px;
    border-radius: 17px;
    border: 1px solid silver;
    box-shadow: inset 1px 1.5px 10px 0px rgba(0,0,0,0.15);
    &:focus {
        outline: none;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    top: 25px;
    position: relative;
    /* text-align: center; */
    /* margin-bottom: 20px;  */
`;

function GamePaymentCode(props) {

    const { gameId, onShowPayment } = props;

    const soundWrong = new UIfx(wrongSound);
    const soundOk = new UIfx(okSound);

    const { t } = useTranslation();
    const [globalState, globalActions] = useGlobal();
    // const introPage = getGameIntroPage(globalState);

    const [secretCode, setSecretCode] = useState('');
    const [secretCodeToFetch, setSecretCodeToFetch] = useState('');
    const [secretCodeIsValid, setSecretCodeIsValid] = useState(null);
    const [isLoading, setIsLoading] = useState('');

    // const gameId = '5e57c834e4de2790c2c9bace';
    useEffect(() => {

        let isMounted = true;
        const checkSecret = async () => {
            setIsLoading(true);
            const response = await axios.post(`/api/game-progress/`, {
                "action": "set-start-time",
                "gameId": gameId,  // "5e57c834e4de2790c2c9bace",
                "secretCode": secretCodeToFetch // "qweqwe"
            });  // TODO catch error (Server 500)

            const json = response.data;
            setIsLoading(false);
            const { game_id, game_secret } = json && json.gameProgress || {};

            if (game_id && game_secret) {
                localStorage.setItem('active-game', JSON.stringify({ game_id, game_secret }));
                globalActions.setGameProgress(json.gameProgress);
            }

            if (json.status === 'OK') {
                setSecretCodeIsValid(true);
                soundOk.play();
                if (isMounted) {
                    // globalActions.setGameStartTime();
                    //     globalActions.setDisplayPage('questions');
                }
            } else {
                setSecretCodeIsValid(false);
                soundWrong.play();
            }
        };

        if (secretCodeToFetch !== '') {
            checkSecret();
        }

        return () => isMounted = true;

    }, [secretCodeToFetch]);

    const handleSecretCodeChange = (e) => {
        const trimmedValue = (e.target.value || '').trim();
        setSecretCode(trimmedValue);
        setSecretCodeIsValid(null);
    }

    const handleCheckSecretCode = () => {
        setSecretCodeToFetch(secretCode);
    }

    const handleContinue = () => {
        globalActions.setDisplayPage('questions');
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            secretCodeIsValid
                ? handleContinue()
                : handleCheckSecretCode();
        }
    }

    return (
        <React.Fragment>
            <ContentWrapper withButton>
                <h1>{t('game.payment.enter.code')}</h1>

                {t('game.payment.enter.code.description')}

                <br />&nbsp;

                <CenteredFlexRow>
                    <Spacer></Spacer>
                    <StyledInput type="text" dir="ltr" value={secretCode} onChange={handleSecretCodeChange} onKeyDown={handleKeyDown} autoCorrect="off" autoCapitalize="none" />
                    <Spacer>
                        {
                            (secretCodeIsValid === true) && (<AnswerIcon src={GoodIcon} alt='Good Answer' className="not-content" />)
                        }
                        {
                            (secretCodeIsValid === false) && (<AnswerIcon src={BadIcon} alt='Bad Answer' className="not-content" />)
                        }
                    </Spacer>
                </CenteredFlexRow>

                <div style={{ textAlign: 'center' }}>
                    <br />
                    {t('game.payment.to.purchase.game.code')}
                    <br />
                    <div>
                        <EditorButton onClick={ () => onShowPayment() } size="small" >{t('click.here')}</EditorButton>
                    </div>
                </div>

                <ButtonWrapper>
                    {
                        secretCodeIsValid
                            ? (<RoundButton width={200} text={t('click.to.continue')} onClick={handleContinue}></RoundButton>)
                            : (<RoundButton width={200} text={t('game.payment.send.code')} onClick={handleCheckSecretCode}></RoundButton>)
                    }
                </ButtonWrapper>
            </ContentWrapper>
        </React.Fragment>
    );
}

export default GamePaymentCode;