import React from 'react';
import { useTable, usePagination, useFilters, useGlobalFilter } from 'react-table'
import styled from '@emotion/styled';
import SimpleTablePagination from './SimpleTablePagination';
import SimpleTableGlobalFilter from './SimpleTableGlobalFilter';
import SimpleTableDefaultColumnFilter from './SimpleTableDefaultColumnFilter';

const StyledTable = styled.div`
    padding: 1rem;

    a[target="_blank"]:after {
        content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
        margin: 0 3px;
    }
    table {
        border-spacing: 0;
        border: 1px solid #bbbbbb;

        thead > tr {
            background-color: #162447;
            color: white;
            th {
                text-align: start;
            }
            
        }

        tbody > tr:hover {
            background-color: #dddddd;
        }

        tr {
            background-color: #ffffff;
            :nth-of-type(2n) {
                background-color: #eeeeee;
            }
            :last-child {
            td {
                border-bottom: 0;
            }
            }
        }

        th,
        td {
            margin: 0;
            padding: 1rem;
            border-bottom: 1px solid #bbbbbb;
            /* border-right: 1px solid #222222; */
            border-left: 1px solid #bbbbbb;

            :last-child {
            /* border-right: 0; */
            border-left: 0;
            }

        }
    }
`;
const defaultPropGetter = () => ({})

const SimpleTable = ({ columns, data = [], withPagination, initialState, tableName }) => {

    const defaultColumn = React.useMemo(
        () => ({
            // Default Filter UI
            Filter: SimpleTableDefaultColumnFilter,
        }),
        []
    )

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,

        getColumnProps = defaultPropGetter,
        getRowProps = defaultPropGetter,
        getCellProps = defaultPropGetter,
      

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,

        // Filter
        preGlobalFilteredRows,
        setGlobalFilter,

    } = useTable({
        columns,
        data,
        defaultColumn,
        initialState: { pageIndex: null, pageSize: 10, ...initialState }
    }, useFilters, useGlobalFilter, usePagination)

    // Render the UI for your table
    return (
        <StyledTable>
            <SimpleTableGlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                tableName={tableName}
            />
            {
                withPagination && (
                    <SimpleTablePagination
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    pageOptions={pageOptions}
                    pageCount={pageCount}
                    gotoPage={gotoPage}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    setPageSize={setPageSize}
                    state={state}
                />
    
    
                )
            }
            <table width="100%" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {(withPagination ? page : rows).map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()} >
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps([
                                        {
                                          className: cell.column.className,
                                          style: cell.column.style,
                                        },
                                        getColumnProps(cell.column),
                                        getCellProps(cell),
                                      ])} >{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </StyledTable>
    )
}

export default SimpleTable;
