import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import ContentWrapper from '../components/common/ContentWrapper';
import RoundButton from '../components/common/RoundButton';
import StyledLink from '../components/common/StyledLink';

const StyledWrapper = styled.div`
    width: 600px;
    max-width: 100vw;
    margin: 0 auto;
`;


function Builders(props) {


    return (
<StyledWrapper>
        <ContentWrapper>
            <h1>בואו להיות שותפים ולהרוויח כסף</h1>

            <h2><u>רוצים לעשות כסף?</u></h2>
            יש לך זמן פנוי? רוצה לנצל אותו וליצור הכנסה פאסיבית?<br/>
            בונים משחקים ללא השקה כספית, ויכולים להתחיל ולהרוויח כסף מיידית<br/>
            <Link to='affiliates/make-money' >לחצו כאן</Link>
            <br/><br/>
            
            <h2><u>מורים? מדריכי טיולים?</u></h2>
            יש לכם ידע בתחום מסויים? בואו לנצל אותו להרוויח ממנו בצורה פאסיבית<br/>
            אתם מורים? אז בטוח שאתם יכולים לייצר מסלולים עם שאלות איכותיות<br/>
            אתם מדריכי טיולים? הוסיפו את המסלול שלך אצלנו להפעלה באון-ליין, ותגדילו את ההכנסה שלכם בקלות.<br/>
            <Link to='affiliates/guides' >לחצו כאן</Link>
            <br/><br/>

            {/* <h2>בעלים של חדרי בריחה? </h2>
            אין ספק שיש לכם את היכולת להרכיב חידות, וליצור מסלול מהתחלה ועד הסוף<br/>
            יש לכם קהל לקוחות? תעשו להם UpSale, ותמכרו להם חוויה ייחודית, שונה, וזולה במיוחד.<br/>
            <Link to='' >לחצו כאן</Link>
            <br/><br/> */}

            {/* <h2>מנהלים של בתי מלון? בעלי צימרים?</h2>
            א. אתם יכולים ליצור מסלולים הקרובים לאזור שאתם נמצאים בו<br/>
            ב. צרו איתנו קשר, ואולי כבר יש לנו מסלולים קרובים לעסק שלכם.<br/>
            <br/><br/> */}

            <br/><br/>
            יש לכם את זה? 
            <br/>
            הצטרפו אלינו עכשיו, ונהיה שותפים אמיתיים. 
            &nbsp;&nbsp; 
            <Link to="/signup">הצטרפו עכשיו - הרשמה</Link>
        </ContentWrapper>
        </StyledWrapper>
    )
}

export default Builders;