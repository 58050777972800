import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
// import useFetch from 'use-http'
import axios from 'axios';
import { get } from 'lodash/fp';

import { getFormattedCurrency } from './../utils';

import Loader from './../components/common/Loader';

// import defaultGameCover from './../images/placeholder-150x150.png';
import { getMyGames } from '../store/selectors';
import useGlobal from '../store';
import styled from '@emotion/styled';
import GameRow from '../components/GameRow';
import FlexRow from '../components/common/FlexRow';
import SimpleTable from '../components/common/simple-table/SimpleTable';

const StyledLink = styled(Link)`
    text-decoration: 'none';

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;


function AdminPendingGames(props) {

    const [isLoading, setIsLoading] = useState(false);
    const [globalState, globalActions] = useGlobal();
    const myGames = getMyGames(globalState);

    const columns = useMemo(() => ([
        {
            Header: '#',
            accessor: 'index',
            Cell: ({ row }) => (row.index + 1)
        },
        {
            Header: 'שם המשחק',
            accessor: 'metadata.name',
        },
        {
            Header: 'עיר',
            accessor: 'metadata.locationCity',
        },
        {
            Header: 'מחיר',
            accessor: 'metadata.price',
        },
        {
            Header: 'מטבע',
            accessor: 'metadata.currencyCode',
        },
        {
            Header: 'סטטוס',
            accessor: 'status',
        },
        {
            Header: 'אשר משחק',
            accessor: 'approve',
            Cell: ({ row }) => (
                <button onClick={() => handleApproveGame(row.original._id)} >
                    אשר משחק
                </button>)
        },
        {
            Header: 'ערוך משחק',
            accessor: 'edit',
            Cell: ({ row }) => (
                <StyledLink to={`/game-editor/${row.original._id}`} >
                    <button>
                        ערוך משחק
                    </button>
                </StyledLink>
            )
        },
        // {
        //     Header: 'צור קוד',
        //     accessor: 'code',
        //     Cell: ({ row }) => (
        //         <button onClick={() => handleGenerateSecretCode(row.original._id)} >
        //             צור קוד משחק
        //         </button>
        //     )
        // }
    ]), []);

    const handleApproveGame = async (gameId) => {
        const result = await axios.post(`/api/games/approve/${gameId}`);
        //window.prompt('קוד משחק', get('data.game_secret', result));
        window.alert('המשחק אושר \n gameId');
    }



    useEffect(() => {
        const loadPendingGames = async () => {
            const token = localStorage.getItem('token') || '';
            setIsLoading(true);
            const result = await axios('/api/games/pending-games')
                .catch(error => { console.log('error: ', error); return []; });
            setIsLoading(false);
            // console.log(result.data);
            globalActions.setMyGames(result.data || []);
            console.log('FIX LINE ABOVE?');

        };

        loadPendingGames();
    }, []);


    return (
        <div>
            <Loader active={isLoading} text='Loading Your Games...' >
                {
                    myGames && (<SimpleTable columns={columns} data={myGames} />)
                }
            </Loader>
        </div>
    )


}

export default AdminPendingGames;