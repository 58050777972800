import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const TextContainer = styled.div`
  padding: 0 10px; 
`;

function GameMapEditorPreview() {

  const { t } = useTranslation();

    return (
      <TextContainer>
         <u>{t('ge.map.preview.title')}</u> <br /><br/>
        {t('ge.map.preview.text1')}
        <br />
        {t('ge.map.preview.text2')}
        <br /><br />
        {t('ge.map.preview.text3')}
        <br /><br />
        {t('ge.map.preview.text4')}
        <br />
        {t('ge.map.preview.text5')}
      </TextContainer>
    );
  }

  export default GameMapEditorPreview;