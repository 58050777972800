import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import ContentWrapper from '../components/common/ContentWrapper';
import RoundButton from '../components/common/RoundButton';
import StyledLink from '../components/common/StyledLink';

const StyledWrapper = styled.div`
    width: 600px;
    max-width: 100vw;
    margin: 0 auto;
`;


function Affiliates(props) {


    return (
<StyledWrapper>
        <ContentWrapper>
            <h1>שותפים עסקיים</h1>

            <h2>מי הם השותפים העסקיים שלנו?</h2>
            שותפים עסקיים מבחינתנו הם כל מי שלוקח חלק במערכת של Street-Hunters. <br />
            זה יכול להיות כל אחד. החל מאנשים בעלי יכולות שיווק טובות, שיודעים איך להגיע ולהניע אנשים. 
            (אנשי שיווק)
            <br />
            ועד לאנשים שממש רוצים לבנות משחקים במערכת ולהרוויח מהם בצורה פאסיבית לאורך השנים. 
            (אנשי תוכן)
            <br /><br />
            אנשי שיווק - 
            <br />
            אנשי השיווק הם אותם אנשים שיודעים להביא לקוחות מתאימים למערכת Strret-Hunters. 
            <br/>
            האנשים שאנחנו מעוניינים להגיע אליהם מתחלקים ל-2 סוגים: 
            <br />
            1. שחקנים
            <br />
            2. מפתחי משחקים
            <br />
            
            
            <br/><br/>
            
            <h2>מה מקבל שותף עסקי?</h2>
            טקסט טקסט טקסט<br/>
            טקסט טקסט טקסט 
            <br/><br/>

            יש לכם את זה? 
            <br/>
            הצטרפו אלינו עכשיו, ונהיה שותפים אמיתיים. 
            &nbsp;&nbsp; 
            <Link to="/signup">הצטרפו עכשיו</Link>
        </ContentWrapper>
        </StyledWrapper>
    )
}

export default Affiliates;