import React from 'react';
import styled from '@emotion/styled';
import FlexCol from './FlexCol';

const StyledLabel = styled.label`
    width: 100%;
`;

const StyledFlexCol = styled(FlexCol)`
    width: 100%; 
`;

const Spacer = styled.div`
    height: 10px;
`;


const StyledInput = styled.input`
    display: block;
    width: 100%;
    background-color: #ddf1f0;
    ${props => props.isWhite ? 'background-color: white;' : ''}
    font-size: 1.5em;
    height: 34px;
    padding: 0 15px;
    border-radius: 17px;
    border: 1px solid silver;
    box-shadow: inset 1px 1.5px 10px 0px rgba(0,0,0,0.15);
    &:focus {
        outline: none;
    }
`;

function InputField({label, value, onChange, placeholder, errorMessage, isWhite, ...rest}) {

    return (
        <StyledFlexCol>
            <StyledLabel  > {label}
            <StyledInput value={ value} placeholder={ placeholder } onChange={ onChange } isWhite={isWhite} {...rest} />
            </StyledLabel>
            {/* <div>{errorMessage}&nbsp;</div> */}
            <Spacer></Spacer>
        </StyledFlexCol>
    );
  }

  export default InputField;