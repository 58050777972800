import React, { useState, useEffect} from 'react';
import styled from '@emotion/styled';
import Menu from './Menu';

import { getIsMenuOpen } from '../store/selectors';
import useGlobal from '../store';
import { useLocation } from 'react-router-dom';


const MenuButtonWrapper = styled.div`
* {
  margin: 0;
  padding: 0; 
}
/* width: 30px; 
height: 22px; */

/* Icon 1 */

#nav-icon1 {
  width: 30px;
  height: 22px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 1s ease-in-out;
  -moz-transition: 1s ease-in-out;
  -o-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
  cursor: pointer;
}

#nav-icon1 span, #nav-icon3 span, #nav-icon4 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: white;
  border-radius: 2px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
}

#nav-icon1 span:nth-of-type(1) {
  top: 0px;
}

#nav-icon1 span:nth-of-type(2) {
  top: 11px;
}

#nav-icon1 span:nth-of-type(3) {
  top: 22px;
}

#nav-icon1.open span:nth-of-type(1) {
  top: 11px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

#nav-icon1.open span:nth-of-type(2) {
  opacity: 0;
  left: -60px;
}

#nav-icon1.open span:nth-of-type(3) {
  top: 11px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
`;

function MenuButton({ isOpen }) {

    // const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [globalState, globalActions] = useGlobal();
    const isMenuOpen = getIsMenuOpen(globalState);
    const location = useLocation();

    useEffect(() => {
      //console.log('Location changed', location);
      globalActions.setIsMenuOpen(false);
    }, [location]);
  
    const handleMenuClick = (isOpen) => {
      globalActions.setIsMenuOpen(isOpen);
    }

    return (
      <React.Fragment>
        <MenuButtonWrapper onClick={ () => handleMenuClick(!isMenuOpen)}>
        <div id="nav-icon1" className={ isMenuOpen ? 'open': ''} >
            <span></span>
            <span></span>
            <span></span>
        </div>
        </MenuButtonWrapper>
        <Menu isOpen={isMenuOpen} />
        </React.Fragment>
    )
}

export default MenuButton;