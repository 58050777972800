import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { set, get, isEmpty, isBoolean } from 'lodash/fp';
import useGlobal from '../store';
import { getGame } from './../store/selectors';
import axios from 'axios';
import Tree from 'rc-tree';
import { isNumber } from './../utils';

import Loader from './../components/common/Loader';
import { NotificationManager } from 'react-notifications';


import EditIntroPage from '../components/EditIntroPage';
import EditMetadata from '../components/EditMetadata';
import FlexRow from '../components/common/FlexRow';
import EditGameOverPage from '../components/EditGameOverPage';
import EditGamePreview from '../components/EditGamePreview';
import EditQuestionPage from '../components/EditQuestionPage';
import { setQuestionStatus } from '../store/actions';
import EditQuestionHint from '../components/EditQuestionHint';
import EditGameMap from '../components/EditGameMap';
import EditAnswerPage from '../components/EditAnswerPage';
import TreeNode from '../components/TreeNode';
import EditorButton from '../components/common/EditorButton';
import ModalAlert from '../components/common/ModalAlert';
import ValidationError from '../components/ValidationError';

const TreeWrapper = styled.div`
    margin-right: 10px;
    width: 290px;
    min-width: 290px; 
    height: 492px;
    min-height: 492px;
`;

const TreeTitle = styled.div`
    background-color: #50a5b7;
    height: 46px;
    border-top-left-radius: 46px;
    border-top-right-radius: 46px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    line-height: 46px;
    color: white;
`;

const TreeMessages = styled.div`
    height: 60px; 
    background-color: #e9e9eb;
    color: #c80000;
`;

const TreeFooter = styled.div`
    background-color: #50a5b7;
    height: 46px;
    padding-top: 2px;
    border-bottom-left-radius: 46px;
    border-bottom-right-radius: 46px;
    text-align: center;
    line-height: 46px;
    color: white;
`;

const RightSpacer = styled.div`
    background-color: white;
    height: 400px; 
    width: 10px; 
    margin-top: 46px; 
`;

const RoundCorners = styled.div`
    background-color: white;
    height: 400px; 
    width: 46px; 
    margin-top: 46px; 
    border-top-right-radius: 46px;
    border-bottom-right-radius: 46px; 

    div[dir="rtl"] & {
        border-top-right-radius: initial;
        border-bottom-right-radius: initial; 
        border-top-left-radius: 46px;
        border-bottom-left-radius: 46px; 
    }
`;

const isTruthy = (val) => val === true || val === false;

function GameEditor(props) {

    const { match: { params: { gameId } } } = props;

    const { t } = useTranslation();

    const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
    const [alertModalText, setAlertModalText] = useState('');
    const [alertModalApproveText, setAlertModalApproveText] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [previewPage, setPreviewPage] = useState('');
    const [questionId, setQuestionId] = useState(null);
    const [hintId, setHintId] = useState(null);
    const [answerMode, setAnswerMode] = useState(null);
    const [selectedKey, setSelectedKey] = useState('');
    const [treeData, setTreeData] = useState([]);
    const [validationErrors, setValidationErrors] = useState([]);

    const [globalState, globalActions] = useGlobal();
    window.globalState = globalState;

    const game = getGame(globalState);
    // console.log('game: ', game);

    const openAlert = (text, onClose, approveText) => {
        setAlertModalText(text);
        setAlertModalApproveText(approveText);
        setIsAlertModalOpen(true);
    };

    if (game && !game.questions) { game.questions = []; }
    let questions = game && game.questions || [];

    const handleOnSelect = (selectedKey, event) => {
        const key = event.node.key;
        setSelectedKey(key);

        let previewPage = key;
        if (key.startsWith('q')) {
            previewPage = 'questions';
            const questionAndHintIndexes = key.replace('qh-', '').replace('q-', '').split('-');
            const questionIndex = Number(questionAndHintIndexes[0]);
            const hintIndex = Number(questionAndHintIndexes[1]);
            setQuestionId(questionIndex);
            setHintId(hintIndex);
            globalActions.setGameProgress(setEditorProgress({ questionIndex, hintIndex }));
        }

        if (key === 'gameover') {
            globalActions.setGameProgress(setEditorProgressForGameover());
        }

        if (key === 'add-q') {
            game.questions = [...game.questions, { content: '' }];
            globalActions.setGame(game);
            setTreeData(buildTreeData(game));

            const newQuestionIndex = game.questions.length - 1
            setQuestionId(newQuestionIndex);
            globalActions.setGameProgress(setEditorProgress({ questionIndex: newQuestionIndex }));
            setSelectedKey(`q-${newQuestionIndex}`);
            previewPage = 'questions';
        }

        if (key.startsWith('add-h')) {
            const questionIndex = Number(key.replace('add-h-', ''));
            game.questions[questionIndex].hints = [...(game.questions[questionIndex].hints || []), { content: '' }];
            globalActions.setGame(game);
            setTreeData(buildTreeData(game));

            const newHintIndex = game.questions[questionIndex].hints.length - 1
            setQuestionId(questionIndex);
            setHintId(newHintIndex)
            globalActions.setGameProgress(setEditorProgress({ questionIndex, hintIndex: newHintIndex }));
            setSelectedKey(`qh-${questionIndex}-${newHintIndex}`);
            previewPage = 'questions';
        }

        if (key.startsWith('answer-q')) {
            previewPage = 'answer';
            setAnswerMode('answer');
            const questionIndex = key.replace('answer-q-', '');
            setQuestionId(Number(questionIndex));
            setHintId(null);
            globalActions.setGameProgress(setEditorProgress({ questionIndex }));
        }

        if ((key.startsWith('text-for-answer-q')) || (key.startsWith('text-for-good-answer-q'))) {
            previewPage = 'answer';
            const questionIndex = key.replace('text-for-good-answer-q-', '').replace('text-for-answer-q-', '');
            setQuestionId(Number(questionIndex));
            setHintId(null);
            const questionStatus = key.startsWith('text-for-good-answer-q') ? 'ANSWERED' : 'STARTED';
            setAnswerMode(key.startsWith('text-for-good-answer-q') ? 'answer-after' : 'answer-before');
            globalActions.setGameProgress(setEditorProgress({ questionIndex, questionStatus }));
        }



        if (key === 'map-metadata') {
            setQuestionId(null);
            previewPage = 'map';
        }

        if (key.startsWith('map-q')) {
            const questionIndex = Number(key.replace('map-q-', ''));
            setQuestionId(questionIndex);
            globalActions.setGameProgress(setEditorProgress({ questionIndex }));
            previewPage = 'map';
        }

        // console.log('previewPage: ', previewPage);

        setPreviewPage(previewPage);
    }

    const buildTreeData = (gameData) => {

        const withMap = get('metadata.map', gameData);

        const questionsTreeData = gameData.questions && gameData.questions.map((q, qIndex) => {
            // Question Location
            const questionLocationTreeData = {
                key: `map-q-${qIndex}`,
                title: null,
                nodeTitle: t('ge.location'),
                children: [],
                isChild: true
            };

            // Question Answer
            const questionAnswerTreeData = {
                key: `answer-q-${qIndex}`,
                title: null,
                nodeTitle: t('ge.answer'),
                isChild: true,
                children: [
                    {
                        key: `text-for-answer-q-${qIndex}`,
                        title: null,
                        nodeTitle: t('ge.answer.text'),
                        children: [],
                        isChild: true
                    },
                    {
                        key: `text-for-good-answer-q-${qIndex}`,
                        title: null,
                        nodeTitle: t('ge.after.answer.text'),
                        children: [],
                        isLastChild: true
                    }

                ]
            };


            const questionHintsTreeData = q.hints && q.hints.map((h, hIndex) => {
                return (
                    {
                        key: `qh-${qIndex}-${hIndex}`,
                        title: null,
                        nodeTitle: h.title || `${t('hint')} ${hIndex + 1}`,
                        children: []
                    }
                )
            }) || [];

            const returnObject =  {
                key: `q-${qIndex}`,
                title: null,
                nodeTitle: `${t('question')} ${qIndex + 1}`,
                children: [
                    questionLocationTreeData,
                    questionAnswerTreeData,
                    ...questionHintsTreeData
                ]
            }

            // Remove map from question is its a game without map
            if (!withMap) {
                returnObject.children.shift();
            }

            return returnObject;
        }) || [];


        questionsTreeData.forEach(dataTree => {
            if (dataTree.key.startsWith('q-')) {
                const addHintTreeData = {
                    key: `add-h-${dataTree.key.replace('q-', '')}`,
                    title: null,
                    nodeTitle: t('ge.add.hint'),
                    children: [],
                    isLastChild: true
                }

                if (Array.isArray(dataTree.children)) {
                    dataTree.children = [...dataTree.children, addHintTreeData];
                } else {
                    dataTree.children = [addHintTreeData];
                }
            }

        });

        const defaultTreeData = [
            {
                key: 'metadata', title: null, nodeTitle: t('ge.general.details'), children: [
                    { key: 'map-metadata', title: null, nodeTitle: t('ge.location'), children: [], isLastChild: true }
                ],
            },
            { key: 'intro', title: null, nodeTitle: t('intro.page'), children: [] },
            ...questionsTreeData,
            { key: 'add-q', title: null, nodeTitle: t('ge.add.question'), children: [] },
            { key: 'gameover', title: null, nodeTitle: t('ge.gameover.page'), children: [] },
        ];

        if (!withMap) {
            defaultTreeData[0].children = [];
        }

        // Adding error class 
        if (validationErrors.length > 0) {
            const errorPath = validationErrors[0].field;
            console.log('errorPath:', errorPath);

            defaultTreeData.forEach((nodeData) => {
                console.log('nodeData.key: ', nodeData.key);

                const isError = errorPath.includes(nodeData.key);
                if (isError) {
                    nodeData.className = 'error';
                }

                // Second Level

                if (nodeData.children && Array.isArray(nodeData.children)) {
                    nodeData.children.forEach((nodeData) => {
                        const isError = errorPath.includes(nodeData.key);
                        if (isError) {
                            nodeData.className = 'error';
                        }
                    })
                }
            })
        }
        return defaultTreeData;
    }

    useEffect(() => {
        const loadGame = async () => {
            setIsLoading(true);
            const result = await axios(`/api/games/${gameId}`);  // TODO catch error (Server 500)
            setIsLoading(false);
            globalActions.setGame(result.data);
            setTreeData(buildTreeData(result.data));
        };
        // if (!game) {
        loadGame();
        // }
    }, [gameId]);

    useEffect(() => {
        if (game) {
            // console.log('game: ', game);
            // console.log({previewPage, selectedKey});
            //            handleOnSelect(null, { node: { key: ''}});            
            setTreeData(buildTreeData(game));
        }
    }, [game])

    useEffect(() => {
        if (game) {
            setTreeData(buildTreeData(game));
        }
    }, [validationErrors])


    const setEditorProgress = ({ questionIndex, questionStatus, hintIndex }) => {
        let progress = {};
        if (isNumber(questionIndex)) {
            progress = set(`questions[${questionIndex}].status`, questionStatus || 'STARTED', progress);
            if (isNumber(hintIndex)) {
                progress = set(`questions[${questionIndex}].hints[${hintIndex}].isOpen`, true, progress);
            }
        }
        return progress;
    }

    const setEditorProgressForGameover = () => {
        let endTime = new Date();
        let startTime = new Date();
        startTime.setMinutes(startTime.getMinutes() - 83)
        startTime.setSeconds(startTime.getSeconds() - 45)

        let progress = {
            startTime: startTime,
            endTime: endTime,
            questions: game.questions.map(q => ({ status: 'DONE' })),
        }
        return progress;
    }

    if (!game) return null;

    const isHtmlEmpty = (content) => {
        const htmlElement = window.document.createElement('div');
        htmlElement.innerHTML = content;
        // img tag is long (more then 15 chars with the url) 
        return (content.length < 15 && htmlElement.innerText.length === 0) ? true : false;
    }
    const isEmptyContent = (content) => {
        // return isEmpty(content) || content.length < 15;
        return isEmpty(content) || isHtmlEmpty(content);
    }

    const gameDataIsValid = () => {
        const gameData = globalState.game;
        // console.log('gameData: ', gameData);
        const withMap = get('metadata.map', gameData);

        const errors = [];
        // metadata 
        if (isEmpty(get('metadata.name', gameData))) { errors.push({ field: 'metadata.name', error: 'required' }) };
        if (isEmpty(get('metadata.locationCity', gameData))) { errors.push({ field: 'metadata.locationCity', error: 'required' }) };
        if (isEmpty(get('metadata.locationText', gameData))) { errors.push({ field: 'metadata.locationText', error: 'required' }) };
        if (Number.isNaN(get('metadata.price', gameData))) { errors.push({ field: 'metadata.price', error: 'required' }) };
        if (Number(get('metadata.price', gameData)) < 30) { errors.push({ field: 'metadata.price', error: 'minimun' }) };
        if (isEmpty(get('metadata.currencyCode', gameData))) { errors.push({ field: 'metadata.currencyCode', error: 'required' }) };
        if (isEmpty(get('metadata.language', gameData))) { errors.push({ field: 'metadata.language', error: 'required' }) };
        if (!isTruthy(get('metadata.map', gameData))) { errors.push({ field: 'metadata.map', error: 'required' }) };
        console.log('TODO: Check for cover image');
        if (withMap){
            if (isEmpty(get('metadata.location', gameData))) { errors.push({ field: 'map-metadata', error: 'required' }) };
        }

        // introPage
        if (isEmptyContent(get('introPage.content', gameData))) { errors.push({ field: 'introPage.content', error: 'required' }) };

        // questions
        if (isEmpty(get('questions', gameData))) { errors.push({ field: 'questions', error: 'required' }) };
        (get('questions', gameData) || []).forEach((question, questionId) => {
            if (isEmptyContent(get('content', question))) { errors.push({ field: `q-${questionId}.content`, error: 'required' }) };
            if (withMap){
                if (isEmpty(get('location', question))) { errors.push({ field: `map-q-${questionId}.location`, error: 'required' }) };
            }
            if (isEmpty(get('answer.value', question))) { errors.push({ field: `q-${questionId}.answer.value`, error: 'required' }) };
            const answer = get('answer.value', question);
            const trimedAnswer = answer && answer.replace(/,/g, '').replace(/ /g, '');
            if (isEmpty(trimedAnswer)) { errors.push({ field: `q-${questionId}.answer.value`, error: 'required' }) };

            

            // question hints
            (get('hints', question) || []).forEach((hint, hintId) => {
                if (isEmptyContent(get('content', hint))) { errors.push({ field: `qh-${questionId}-${hintId}.content`, error: 'required' }) };
            });

        });
        // gameoverPage
        if (isEmptyContent(get('gameoverPage.content', gameData))) { errors.push({ field: 'gameoverPage.content', error: 'required' }) };

        setValidationErrors(errors);
        if (errors.length === 0) {
            return true;
        }

        return false;
    }

    const saveGame = async () => {
        const gameData = globalState.game;
        return await axios.post(`/api/games/${gameId}`, gameData)
            .then((result) => {
                // console.log('saveGame result: ', result);
                NotificationManager.success(t('game.editor.game.saved'), null);
            })
            .catch(error => console.log('saveGame error: ', error));
    }

    const sendToApproval = async () => {
        return await axios.post(`/api/games/status/${gameId}`, { status: 'PENDING', notifyAdmin: true })
            .then((result) => {
                // console.log('sendToApproval result: ', result);
                globalActions.setGame(result.data);
                NotificationManager.success(t('game.editor.game.sentToApproval'), null);
            })
            .catch(error => console.log('sendToApproval error: ', error));
    }

    const saveDraft = async () => {
        setValidationErrors([]);
        globalActions.gameEditorSetStatus('DRAFT');
        // console.log('===> BEFORE SAVE');        
        await saveGame();
        // console.log('===> AFTER SAVE');
    }

    const validateAndSaveGame = async () => {
        if (gameDataIsValid()) {
            await saveGame();
            if (game.status !== 'APPROVED' && game.status !== 'PENDING') {
                await sendToApproval();
            }
        } else {
            setTreeData(buildTreeData(game));
            // console.log('validationErrors: ', validationErrors);

            // // console.log('validationErrors[0]: ', validationErrors[0]);

            // // const {field, error} = validationErrors[0]; 
            // alert('Missing Some Data');
            // //alert(`Missing Some Data:\n\nField: ${field}\nError: ${error}`)
        }
    }

    const handlePreviewClick = (e) => {
        e.stopPropagation();
    }

    // console.log('===> selectedKey: ', selectedKey);
    // console.log('===> previewPage: ', previewPage);
    // console.log('===> questionId: ', questionId);
    // console.log('===> hintId: ', hintId);
    // console.log('===> answerMode: ', answerMode);

    const isApprovedOrPending = ['PENDING', 'APPROVED'].includes(game.status);

    return (
        <Loader active={isLoading}>
            <ModalAlert
                isOpen={isAlertModalOpen}
                text={alertModalText}
                approveText={alertModalApproveText}
                onClose={() => { setIsAlertModalOpen(false) }}
            />
            <FlexRow>
                <div>
                    <TreeWrapper>
                        <TreeTitle>{t('ge.game.edit')}</TreeTitle>
                        <Tree style={{ backgroundColor: '#e9e9eb', padding: '10px', height: '340px', overflow: 'scroll' }}
                            onSelect={handleOnSelect}
                            defaultExpandedKeys={[]}
                            expandedKeys={[selectedKey]}
                            autoExpandParent={true}
                            treeData={treeData}
                            icon={TreeNode}
                        />
                        <TreeMessages>
                            {
                                validationErrors.length > 0 && (
                                    <ValidationError
                                        field={validationErrors[0].field}
                                        error={validationErrors[0].error}
                                    />
                                )
                            }

                        </TreeMessages>
                        <TreeFooter>
                            {
                                !isApprovedOrPending && (
                                    <EditorButton onClick={saveDraft} width="123px">{t('save')}</EditorButton>
                                )
                            }
                            <EditorButton onClick={validateAndSaveGame} width="123px">
                                {isApprovedOrPending ? t('save') : t('ge.send.to.approval')}
                            </EditorButton>
                        </TreeFooter>
                    </TreeWrapper>

                </div>

                <RightSpacer />

                <div style={{ 'width': '400px', marginTop: '46px', height: '400px', backgroundColor: 'white' }}>
                    {
                        previewPage === 'metadata' && (<EditMetadata metadata={game.metadata} />)
                    }
                    {
                        previewPage === 'intro' && (<EditIntroPage />)
                    }
                    {
                        previewPage === 'questions' && isNumber(hintId) && (<EditQuestionHint questionIndex={questionId} hintIndex={hintId} />)
                    }
                    {
                        previewPage === 'questions' && !isNumber(hintId) && (<EditQuestionPage questionId={questionId} />)
                    }
                    {
                        previewPage === 'answer' && (<EditAnswerPage questionId={questionId} answerMode={answerMode} />)
                    }
                    {
                        previewPage === 'gameover' && (<EditGameOverPage />)
                    }
                    {
                        previewPage === 'map' && (<EditGameMap questionId={questionId} />)
                    }
                </div>

                <RoundCorners />

                <div style={{ 'width': '425px', marginTop: '46px', position: 'relative' }} >
                    <div style={{ position: 'absolute', width: '100%', height: '110%', zIndex: '10' }} onClick={handlePreviewClick}></div>
                    <EditGamePreview previewPage={previewPage} questionIndex={questionId} gameId={gameId} />
                </div>

            </FlexRow>

        </Loader>
    )


}

export default GameEditor;