import React, { useEffect, useState } from 'react';
import axios from 'axios';

function TestRequest(props) {

    console.log('X X X X X X X X X X X X X');
    
    const [isLoading, setIsLoading] = useState(false);
    const [reqData, setReqData] = useState({});


    useEffect(() => {
        const loadRequestData = async () => {
            const token = localStorage.getItem('token') || '';
            setIsLoading(true);
            const result = await axios.get('/api/test/request')
            .catch(error => {console.log('error: ', error); return [];});
            setIsLoading(false);
            
            setReqData(result.data);
        };

        loadRequestData();

    },[])

    const formattedJson = JSON.stringify(reqData, null, 2); // spacing level = 2
    return (
        <div>
            <h1>Test Request Page</h1>
            <pre style={ {direction:'ltr'}}>
                { formattedJson }
            </pre>
        </div>
    )


}

export default TestRequest;