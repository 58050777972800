import React from 'react';
import EditContent from './EditContent';

function EditQuestionPage({ questionId }) {

    const path = `questions[${questionId}].content`;

    return (
        <React.Fragment>
            <EditContent path={path} />
        </React.Fragment>
    );
}

export default EditQuestionPage;