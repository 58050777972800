import React, { useState } from 'react';
import {
  useLocation,
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import SocketContext from './utils/socket-context';
import {NotificationContainer} from 'react-notifications';

import cookiesUtil from './utils/cookies';
import io from 'socket.io-client';
import './App.css';
import { get } from 'lodash/fp';
import { getLocalStorage } from './store/actions/index';
import { useTranslation } from 'react-i18next';

import useGlobal from './store';

import Home from './pages/Home';
import About from './pages/About';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Signup from './pages/Signup';
import Game from './pages/Game';
import Games from './pages/Games';
import Header from './components/Header';
import MyGames from './pages/MyGames';
import MyGamesFeedbacks from './pages/MyGamesFeedbacks';
import MyActiveGames from './pages/MyActiveGames';
// import FileUploader from './components/common/FileUploader';
import GameEditor from './pages/GameEditor';
import GameMenu from './components/GameMenu';
import GameFeedbackPage from './pages/GameFeedbackPage';
import Splash from './pages/Splash';
import Test from './pages/Test';
import TestRequest from './pages/TestRequest';
import GameEditorNewGame from './pages/GameEditorNewGame';
import ValidateEmail from './pages/ValidateEmail';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Affiliates from './pages/Affiliates';
import AffiliatesEscapeRoomOwners from './pages/affiliates/EscapeRoomOwners';
import AffiliatesMakeMoney from './pages/affiliates/MakeMoney';
import AffiliatesGuides from './pages/affiliates/Guides';
import Builders from './pages/Builders';
import Reports from './pages/Reports';
import Page404 from './pages/Page404';
import PaymentSuccess from './pages/PaymentSuccess';
import AdminPendingGames from './pages/AdminPendingGames';
import AdminUsers from './pages/AdminUsers';
import 'react-notifications/lib/notifications.css';
import SiteTerms from './pages/SiteTerms';
import ExampleGame from './pages/ExampleGame';
import AutoLogin from './components/AutoLogin';
import GenerateCodes from './pages/GenerateCodes';


const socket = io();

function App() {

  const [globalState, globalActions] = useGlobal();
  const [isGamePage, setIsGamePage] = useState(false);
  const { t } = useTranslation();

  // Keep Affilate for 7 Days
  const urlSearchParams = new URLSearchParams(window.document.location.search);
  const affCode = urlSearchParams.get('aff');
  if (affCode && cookiesUtil.getCookie('aff') === '') {
    cookiesUtil.setCookie('aff', affCode, 7);
  }
  
  

  // console.log('MAP: https://stackblitz.com/edit/react-aogjvt?file=MyMapComponent.js');
  // https://stackblitz.com/edit/react-aogjvt?file=MyMapComponent.js

  return (
    <SocketContext.Provider value={socket}>
      <AutoLogin />
    <Router>
      {/* <div className={ `app ${t('css.dir')}`} dir={ t('css.dir') }> */}
      <div dir={ `${t('css.dir')}`}>
        { isGamePage ? <GameMenu />: <Header /> }
        {/* <Header /> */}
        <Switch>
          {/* <Route exact path="/" component={Home} /> */}
          <Route exact path="/" component={Splash} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/splash" component={Splash} />
          <Route path="/about" component={About} />
          <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout} />
          <Route path="/signup" component={Signup} />
          <Route path="/validate-email/:token" component={ValidateEmail} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route exact path="/affiliates" component={Affiliates} />
          {/* <Route path="/affiliates/escape-room-owners" component={AffiliatesEscapeRoomOwners} /> */}
          <Route path="/affiliates/make-money" component={AffiliatesMakeMoney} />
          <Route path="/affiliates/guides" component={AffiliatesGuides} />
          <Route path="/builders" component={Builders} />
          <Route path="/reports" component={Reports} />
          <Route path="/games" component={Games} />
          {/* <Route exact path="/games/user/:userId" component={Games} /> */}
          <Route exact path="/my-games" component={MyGames} />
          <Route path="/my-games/feedbacks" component={MyGamesFeedbacks} />
          <Route path="/my-active-games" component={MyActiveGames} />
          <Route path="/test/request" component={TestRequest} />
          <Route path="/test" component={Test} />
          <Route path="/game-editor/:gameId" component={GameEditor} />
          <Route path="/game-editor-new-game" component={GameEditorNewGame} />
          <Route path="/game/:gameId" component={Game} />
          <Route path="/game-feedback/:gameId/:token" component={GameFeedbackPage} />
          <Route path="/payment-success" component={PaymentSuccess} />
          <Route path="/admin-pending-games" component={AdminPendingGames} />
          <Route path="/admin-users" component={AdminUsers} />
          <Route path="/terms" component={SiteTerms} />
          <Route path="/admin-generate-codes" component={GenerateCodes} />
          {/* <Route path="/example-game" component={ExampleGame} /> */}
          <Route path="*" component={Page404} />
        </Switch>
      </div>
    </Router>
    <NotificationContainer/>
    </SocketContext.Provider>
  );
}

export default App;
