import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash/fp';
import axios from 'axios';
import FlexRow from './FlexRow';
import useGlobal from './../../store';
import { getIsLoggedIn } from './../../store/selectors';
import { LoadScript } from '@react-google-maps/api';
import Loader from './Loader';
import RoundButton from './RoundButton';

// import { cloudinary } from 'cloudinary';

// const StyledButton = styled.button`
//     border: 2px solid black;
//     font-size: 1.5em;
//     font-weight: bold;
//     display: block;
//     background-color: cyan;
//     margin: 0 auto; 
//     min-width: 50%;
// `;
const InputFileLabel = styled.label`
    input[type="file"] {
        display: none;
    }
`;

const InputFileButton = styled.span`
    padding: 5px 10px;
    border: 1px solid;
    cursor: pointer;
    &:hover {
        background-color: #5dbed1;
    }
`;

const ThumbnailsTitle = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ThumbnailContainer = styled.div`
    display: flex;
    justify-items: center;
    flex-wrap: wrap;
`;

const MediaImageThumbnail = styled.div`
    ${props => props.size && `width: ${props.size};`}
    ${props => props.size && `min-width: ${props.size};`}
    ${props => props.size && `height: ${props.size};`}
    ${props => props.size && `min-height: ${props.size};`}
    ${props => props.imageUrl && `background-image: url('${props.imageUrl}');`}
    
    margin: 10px 5px; 
    padding: 5px;
    border: 2px solid #5dbed1;
    border-radius: 10px;

    float: left;
    background-position: center;
    background-size: contain;
    background-repeat:no-repeat;
    cursor: pointer;

    &:hover {
        background-color: #e1eef0;
    }
`;

const MediaVideoThumbnail = styled.div`
    ${props => props.size && `width: ${props.size};`}
    ${props => props.size && `min-width: ${props.size};`}
    /* ${props => props.size && `height: ${props.size};`} */
    ${props => props.size && `min-height: ${props.size};`}
    ${props => props.imageUrl && `background-image: url('${props.imageUrl}');`}
    
    margin: 10px 5px; 
    padding: 5px;
    border: 2px solid #5dbed1;
    border-radius: 10px;

    float: left;
    background-position: center;
    background-size: contain;
    background-repeat:no-repeat;
    cursor: pointer;

    &:hover {
        background-color: #e1eef0;
    }

`;

const SelectButton = styled.div`
    padding: 10px; 
    text-align: center;

    & >button {
        width: 100px;
    }
`;

function FileUploader({ mediaType = 'image', onSelect }) {
    
    const { t } = useTranslation();
    const [globalState, globalActions] = useGlobal();

    const [resources, setResources] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [refreshList, setRefreshList] = useState(0);
    const [previewSize, setPreviewSize] = useState('200px');

    useEffect(() => {

        const loadMedia = async () => {
            setIsLoading(true);
            const response = await axios.get(`/api/media/${mediaType}`)
            .then(response => { 
                const responseResources = get('data.resources', response);
                setResources(responseResources || []);
            } )
            .catch(error => {
                // console.log('error: ', error);
                setIsLoading(false);
            });
            setIsLoading(false);
//             console.log('response: ', response);
            
            //console.log('data: ', response.data);

        }
        loadMedia();
    }, [refreshList])

    // const handleFormSubmit = (e) => {
    //     e.preventDefault();
    //     uploadFile(selectedFile)
    //         .then(response => console.log('response: ', response));
    // }

    const refreshView = () => {
        setRefreshList(refreshList + 1);
    }

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        setIsLoading(true);
        await uploadFile(file)
            .then(response => {
                console.log('response: ', response);
                refreshView();
            })
            .catch(error => {
                console.log('error: ', error);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const uploadFile = async (file) => {
        console.log('file: ', file);

        const formData = new FormData();
        formData.append('file', file)
        return axios.post('/api/media/upload/', formData)
    };

    const changePreviewSize = (size) => {
        setPreviewSize(size);
    }

    return (
        <React.Fragment>
            <Loader text="Loading..." active={isLoading}>

                <form id="frmUploader" encType="multipart/form-data" action="api/Upload/" method="post">
                    <InputFileLabel>
                        <InputFileButton>{ mediaType === 'video' ? t('ge.add.video') : t('ge.add.image')}</InputFileButton>
                        <input type="file" name="imgUploader" onChange={handleFileChange} accept={`${mediaType}/*`} multiple={false} />
                    </InputFileLabel>

                    {/* <input type="submit" name="submit" id="btnSubmit" value="Upload" />  */}
                </form>

                <hr />

                <ThumbnailsTitle>
                    <div>
                        <span>{ t('media.total.files')}: </span> {resources.length}
                    </div>
                    <div>
                        <span onClick={() => changePreviewSize('400px')}>[Large]</span>
                        <span onClick={() => changePreviewSize('200px')}>[Small]</span>
                        <button onClick={() => refreshView()}>Refresh</button>
                    </div>
                </ThumbnailsTitle>
                {/* <div height="200px" >
                    <video width="320" height="240" controls controlsList="nodownload" src="https://res.cloudinary.com/hkoaaa0fo/video/upload/v1591107196/5e662fd1ebfbdbfd613bfe79/Movie_-_Clock_-_6066_fx3ptw.mp4" />
                </div> */}
                <ThumbnailContainer>
                    {
                        resources && resources.map(resource => (
                            resource.resource_type === 'video'
                                ? (
                                    <MediaVideoThumbnail 
                                        key={resource.public_id} 
                                        size={previewSize} >
                                        <video width="100%" 
                                            src={resource.secure_url}  
                                            controls controlsList="nodownload"/>
                                            <SelectButton>
                                            <button onClick={() => onSelect(resource.secure_url)} >{ t('select') }</button>
                                            </SelectButton>
                                    </MediaVideoThumbnail>
                                )
                                : (
                                    <MediaImageThumbnail
                                        key={resource.public_id}
                                        onClick={() => onSelect(resource.secure_url)}
                                        size={previewSize}
                                        imageUrl={resource.secure_url}
                                    />)

                        ))
                    }
                </ThumbnailContainer>
            </Loader>
        </React.Fragment>
    );
}

export default FileUploader;