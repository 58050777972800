import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const SkipQuestionWrapper = styled.div`
    text-align: end;

    & span {
        padding: 0 10px; 
        border-radius: 10px; 
        cursor: pointer;
        font-weight: bold; 
        color: white;
        background-color: #3babab;
        
        min-width: 125px;
        display: inline-block;
        text-align: center;
    }
`;


function SkipQuestion({ onClick }) {

    const { t } = useTranslation();

    return (
        <SkipQuestionWrapper>
            <span onClick={ onClick }>{ t('skip')}</span>
        </SkipQuestionWrapper>
    )
  }

  export default SkipQuestion;