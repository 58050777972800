//import React, {useEffect} from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { get } from 'lodash/fp';
import useGlobal from "../store";


const AutoLogin = () => {

    const [globalState, globalActions] = useGlobal();

    useEffect(() => {
        axios.post('/api/auto-login', {})
            .then(response => {
                console.log('response.data: ', response.data);
                if (get('data.error',response)) {
                    localStorage.removeItem('token');
                    globalActions.setIsSelfPayment(null);
                    globalActions.setIsLoggedIn(null);
                    globalActions.setLoggedInRole(null);
                    globalActions.setUserId(null);
                    return;
                }
                if (response.data) {
                    const { accessToken } = response.data;
                    const role = get('data.data.role', response);
                    const isSelfPayment = get('data.data.isSelfPayment', response);
                    const userId = get('data.data.userId', response);
                    localStorage.setItem('token', accessToken);
                    globalActions.setIsLoggedIn(true);
                    globalActions.setLoggedInRole(role);
                    globalActions.setUserId(userId);
                    globalActions.setIsSelfPayment(isSelfPayment);
                    console.log('AutoLogin - OK ', role);
                }
            })
            .catch(err => {
                // console.log('AutoLogin - Failed');
            })

    }, [])
    return null;
}

export default AutoLogin;
