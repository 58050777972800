import React from 'react';
import FlexRow from '../../components/common/FlexRow';
import styled from '@emotion/styled';
import ArrowDiv from '../../components/common/ArrowDiv';
import LandingPageForm from '../../components/LandingPageForm';
// import { useTranslation } from 'react-i18next';
// import { Link } from 'react-router-dom';
// import ContentWrapper from './..../components/common/ContentWrapper';

// const StyledAbout = styled.div`
//     // text-align: center;
// `;


// const MakeMoney = styled.div`
//     padding-bottom: 20px; 
// `;

const LandingPageWrapper = styled.div`

    font-size: 20px; 
    background-color: white;

    h2 {
        font-size: 30px;
    }
    ul, ol {
        list-style-type: circle;        
    }

    .section {
        padding: 20px 36px;
        position: relative;
        
    }

    .section1 {
        
        color: #0f2547;
        background-color: white; /* #3babab; */
        background-image: url('/images/lp/lp_header_bg.png');
        background-repeat: no-repeat;
        background-position: right top;

        .page-header {
            font-size: 70px;
            min-height: 210px; 

            @media screen and (max-width: 600px) {
                font-size: 38px;
                text-align: center;
            }

        /* background-image: url('/images/lp/lp_header_bg.png');
        background-repeat: no-repeat;
        background-position: right bottom;
        padding-right: -36px; */

        }

        .text {
            max-width: 540px; 
        }

        .girl {
            position: absolute;
            bottom: 0;
            right: 540px;

            @media screen and (max-width: 940px) {
                display: none;
            }

        }
    }    

    .section2 {
        color: white;
        background-color: purple;

        .text {
            max-width: 587px; 
        }

        .map-icon {
            position: absolute;
            bottom: 20px;
            right: 650px;
            width: 200px;

            @media screen and (max-width: 940px) {
                display: none;
            }
        }
    }    

`;

const ArrowsFlexRow = styled(FlexRow)`
    flex-wrap: wrap;
`;

const FormWrapper = styled.div`
    @media screen and (min-width: 1200px) {   
    position: absolute;
    top: 360px;
    right: 890px;
    }
`;

function EscapeRoomOwners(props) {

    // const { t } = useTranslation();
    return null; //Temp, Not ready yet.

    return (
        <LandingPageWrapper>

            <div className='section section1'>
                <div className='page-header'>מנהלי חדרי בריחה<br /> שימו לב !!!</div>
                <div className='text'>
                    <h2>הדברים האלה מוכרים לכם?</h2>
                    <ul>
                        <li>מרגישים שיש יותר מדיי הוצאות תחזוקה מסביב ואין לכם איך לשלם על זה בתקופת הקורונה? </li>
                        <li>עדיין לא החזרתם את ההשקעה בחדר ומפחדים שכבר מאוחר מידי ?</li>
                        <li>השכירות החודשית גבוהה מדיי ואין לכם מאיפה להחזיר את השקעה? </li>
                        <li>תיקון אביזרים והבלאי מבאסים אתכם כי זה גורר עוד ועוד עלויות לא מתוכננות בנוסף להכל?</li>
                        <li>מכשירים עובדים חדשים, ואז הם עוזבים פתאום? </li>
                        <li>הלקוחות שלכם מפחדים להגיע בגלל הקורונה? ואתם צריכים פתרון אחר?</li>
                        <li>מרגישים שאתם מדשדשים במקום ולא מצליחים לקדם את העסק שלכם?</li>
                        <li>חייבים הכנסה נוספת כדי להציל את עצמכם מהבור הכלכלי שנקלעתם אליו אבל עדיין רוצים לנצל את היכולות שלכם מהתחום?</li>
                    </ul>
                <img className='girl' src='/images/lp/lp_girl_pointing_up.png' alt='Pointing Girl' />

                </div>
            </div>

            <div className='section section2'>
                <div className='text'>
                <h2>הפעילות שלנו היא בדיוק בשבילכם!</h2>
                בואו להקים אצלנו <b>חדרי בריחה וירטואליים</b>, ו/או <b>משחקי רחוב</b> ללא עלות שיאפשרו לכם: 
                <br />&nbsp;
                <ul>
                    <li>לבנות אפיק הכנסה חדש ללא השקעה כלכלית מצידכם בשום צורה. </li>
                    <li>להכניס עוד כסף <b>לעסק הקיים</b> בלי ההוצאות הנלוות אליו.</li>
                    <li>כל הבירוקרטיה והתפעול הטכני נעשה אוטומטית על ידינו עבורכם, הכל כולל הכל. *אחסון, *סליקה, *זמינות אתר.</li>
                    <li>להפסיק לנהל ולהכשיר עובדים חדשים. המשחק עובד בשבילכם. </li>
                    <li>לשחק במשחקים שלכם גם בימי קורונה כיוון שהם בשטח הפתוח, או משחקי און-ליין מהבית. בפלטפורמה נוחה שמגדילה את הפוטנציאל הקיים בעסק.</li>
                    <li>שקט ורוגע כיוון שאין צורך בתיאום משחק מראש שמצריך הקפדה על לו"זים מוגדרים- ניתן לשחק בכל שעה.</li>
                    <li>אתם יכולים לנצל את מאגר הלקוחות שלכם, לתת להם אטרקציה חדשה, ולא פחות חשוב להרוויח כסף.</li>
                </ul>
                </div>
            </div>



            <div className='section section3'>
                <div className='text'>
                <h2>במה מדובר בעצם?</h2>
                    Street-Hunters -
                    משחק חוויתי ברחבי העיר הכולל מסלול לאורך נקודות עניין, וחידות הקשורות לכל נקודה ונקודה.
                <br />
                לכל חידה במהלך המסלול ישנם רמזים אשר עוזרים להגיע לפתרון החידה במידה ולא מצליחים.
                <br />
                ניתן לשחק כמשחק תחרותי מול שעון,
                או מול קבוצות אחרות שכבר שיחקו בו בעבר,
                בדומה ל "מירוץ למיליון" או "חפשו את המטמון",
                <br />
                מצד שני, אפשר גם לשחק אותו כסיור חוויתי לימודי המעשיר את השחקנים בידע והכרות עם המקום / העיר.
                <img className='map-icon' src='/images/lp/lp_map_icon.svg' alt='Map Icon' />
                </div>
            </div>


            <div className='section section3'>
                <h2>רוצים להתחיל להרוויח כסף?</h2>
                <b>כל אחד יכול ליצור משחק, מכל מקום בעולם, וללא השקעה כספית.</b>
                <br /><br />
                <ArrowsFlexRow>
                    <ArrowDiv backgroundColor='#91d5d1' >יצירת משחק מוצלח</ArrowDiv>
                    <ArrowDiv backgroundColor='#5dbed1' >דירוג גבוה יותר</ArrowDiv>
                    <ArrowDiv backgroundColor='#6eabdb' >יותר שחקנים</ArrowDiv>
                    <ArrowDiv backgroundColor='#c79ef5' >יותר כסף לכם בכל חודש</ArrowDiv>
                </ArrowsFlexRow>
                <br />
                <div>
                    אנחנו דואגים לטכנולוגיה, הכוללת את המשחק עצמו, מערכת לבניה וניהול של משחקים וסליקה אונליין
                    <br />
                    <br />
                    <b>בתכלס, אפשר להתחיל להרוויח כסף מכל מקום בעולם כבר עכשיו.</b>

                    <div>
                        בין אם אתם נמצאים בישראל, אנגליה, תאילנד, אוסטרליה, או ארה"ב,
                        ובין אם אתם אזור כפרי או עירוני,
                        תוכלו בהשקעה של ימים ספורים להקים לכם עסק רווחי.
                    </div>
                </div>
            </div>

            <div>
            <div style={ { maxWidth: '500px', margin: '0 auto' } }>
            <FormWrapper>
                <LandingPageForm formType='make-money'/>
            </FormWrapper>
                </div>
            </div>

            <br /><br /><br />

        </LandingPageWrapper>
    )
}

export default EscapeRoomOwners;