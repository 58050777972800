import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { get } from 'lodash/fp';
import ContentWrapper from '../components/common/ContentWrapper';
import { useLocation } from 'react-router-dom';

import axios from 'axios';
import RoundButton from '../components/common/RoundButton';


const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    top: 25px;
    position: relative;
`;

const CenteredDiv = styled.div`
    display: flex;
    justify-content: center;
`;

function PaymentSuccess(props) {

    const { t } = useTranslation();


    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [paymentError, setPaymentError] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [gameId, setGameId] = useState('');
    const [secretCode, setSecretCode] = useState('');

    const urlSearchParams = new URLSearchParams(location.search);
    const token = urlSearchParams.get('Token');

    useEffect(() => {
        const getPaymentDetails = () => {
            // console.log('getPaymentDetails.token: ', token);
            setIsLoading(true);
            axios.post('/api/payment/checkToken', { token })
                .then((res) => {
                    console.log('res: ', res);
                    setPaymentSuccess(true);
                    setGameId(get('data.gameId', res));
                    setSecretCode(get('data.secretCode', res))
                })
                .catch((error) => {
                    console.log('Error: ', error);
                    setPaymentError(true);
                })
                .finally(() => {
                    setIsLoading(false);
                })
        }
        if (token) {
            getPaymentDetails();
        }

    }, [])

    const handleContinueGame = (e) => {
        window.location.href = `/game/${gameId}`;
    }

    return (
        <ContentWrapper withButton>
            <h1>{t('payment.approval')}</h1>

            {
                isLoading && (
                <CenteredDiv>
                    {t('payment.approval.validating')}
                    <br />
                    {t('payment.approval.please.wait')}
                </CenteredDiv>)
            }
            {
                paymentError && (<CenteredDiv>{t('payment.approval.error')}</CenteredDiv>)
            }

            {
                paymentSuccess && (
                    <React.Fragment>
                        <CenteredDiv>{t('payment.approval.success')}</CenteredDiv>
                        <br />
                        <div>
                            {t('payment.approval.email.was.sent')}
                        </div>

                        <br />
                        <CenteredDiv>{t('payment.approval.game.code')}: </CenteredDiv>
                        <br />
                        <CenteredDiv><h1>{secretCode}</h1></CenteredDiv>

                        <ButtonWrapper>
                            <RoundButton width={200} text={t('back.to.game')} onClick={handleContinueGame}></RoundButton>
                        </ButtonWrapper>
                    </React.Fragment>
                )
            }

        </ContentWrapper>
    )
}

export default PaymentSuccess;