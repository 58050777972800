import React from 'react';
import { useParams, useLocation, useHistory, useRouteMatch } from 'react-router-dom';

import logoHeaderTextImage from './../images/logo_header_text.png';
// import logoHeaderImage from './../images/logo_header.png';
import logoHeaderImage from './../images/logo_header_2_300.png';
import styled from '@emotion/styled';
import useGlobal from "../store";
import { getGame, getIsLoggedIn } from '../store/selectors';

import { Link } from "react-router-dom";

import { useEffect, useState } from 'react';
import RoundButton from './common/RoundButton';
import MenuButton from './MenuButton';
import GameTime from './GameTime';
import EnvironmentData from './EnvironmentData';
import { get } from 'lodash/fp';
import { useTranslation } from 'react-i18next';

const HeaderWrapper = styled.div`
  position: relative;
  margin-bottom: 45px;
`;

const LogoContainer = styled.div`
  height: 85px; 
  background-color: #162447;
  background: linear-gradient(245deg,#162447 0%,#2a2446 100%);
  /* border-bottom-left-radius: 150px 15px; */
  /* border-bottom-right-radius: 150px 15px; */
  color: white;
  direction: ltr;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  > div {
    flex: 1;
    justify-content: center;
  }
  > div:nth-of-type(2) {
    flex: 2;
    text-align: center;
    padding-top: 22px;
  }
`;

const HeaderMenuContainer = styled.div`
    padding: 20px 10px 0 0;
    direction: rtl;

    /* div[dir="rtl"] & {
      direction: ltr;
      padding: 20px 0 0 10px;
    } */

`;

const HeaderLogoContainer = styled.div`
    text-align: left;
    padding: 10px 0 0 24px; 
    img {
      width: 54px;
      height: 48px;
    }

    /* div[dir="rtl"] & {
      text-align: right;
      padding: 10px 24px 0 0; 
    } */
`;

const LogoWrapper = styled.div`
  height: 92px; 
  background-color: #c79ef5;
  background: linear-gradient(135deg,#c79ef5 0%, #6eabdb 30%, #5dbed1 60%, #adf0b8 100%);
  /* border-bottom-left-radius: 150px 15px; */
  /* border-bottom-right-radius: 150px 15px; */
`;

const HeaderButtons = styled.div`
  display: flex;
  justify-content: center;
  direction: ltr;
  text-align: left;
  padding: 0 0 10px 0;
  font-size: 2em;
  width: 100%;
  position: absolute;
  top: 57px;
    
  > * {
    margin: 0 10px;
  } 
`;

const MenuText = styled.div`
  font-size: 10px; 
  text-align: center;
  padding-top: 3px;
`;

const StyledLink = styled(Link)`
color: white;
`;

function Header() {

  const [globalState, globalActions] = useGlobal();
  const isLoggedIn = getIsLoggedIn(globalState);
  const [isGame, setIsGame] = useState(false);
  const game = getGame(globalState);
  const [isSplashPage, setIsSplashPage] = useState(true);
  const location = useLocation();
  const { t } = useTranslation();

  const withMap = get('metadata.map', game);
  const changePage = (page) => {
    globalActions.setDisplayPage(page);
  }

  useEffect(() => {
    setIsGame(location.pathname.includes('/game/'));
    setIsSplashPage(location.pathname === ('/') || location.pathname === ('') || location.pathname.includes('/affiliates/'))
  }, [location])

  if (isSplashPage) {
    return null;
  }

  // https://www.w3schools.com/css/css_navbar.asp

  return (
    <React.Fragment>
      <EnvironmentData env={process.env.NODE_ENV}>{process.env.NODE_ENV}</EnvironmentData>
      <HeaderWrapper>
        <LogoWrapper>

          <LogoContainer>
            <HeaderLogoContainer>
              <Link to="/games" >
                <img src={logoHeaderImage} />
              </Link>
            </HeaderLogoContainer>
            <div>
              {
                isGame
                  ? <GameTime />
                  : <img src={logoHeaderTextImage} />
              }
            </div>
            <HeaderMenuContainer>
              <MenuButton />
            </HeaderMenuContainer>
          </LogoContainer>
        </LogoWrapper>
        {
          isGame
            ? (
              <HeaderButtons>
                { withMap && (
                  <div onClick={() => changePage('map')} title="Map">
                    <RoundButton icon='game-map' />
                    <MenuText>{t('header.menu.map')}</MenuText>
                  </div>
                )}
                <div onClick={() => changePage('questions')} title="Questions">
                  <RoundButton icon='game-play' />
                  <MenuText>{t('header.menu.game')}</MenuText>
                </div>
                <div onClick={() => changePage('intro')} title="Intro">
                  <RoundButton icon='game-intro' />
                  <MenuText>{t('header.menu.intro')}</MenuText>
                </div>
              </HeaderButtons>
            )
            : (
              <HeaderButtons>
                <Link to="/home" title="Home"><RoundButton icon='home' /></Link>
                <Link to="/games" title="Games"><RoundButton icon='games' /></Link>
                <Link to="/about" title="About Us"><RoundButton icon='about' /></Link>
              </HeaderButtons>
            )
        }
      </HeaderWrapper>
    </React.Fragment>
  );
}

export default Header;