import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import FlexRow from './common/FlexRow';
import EditorButton from './common/EditorButton';
import { useState } from 'react';

const FeedbackWrapper = styled.div`
    padding: 10px; 
    margin: 0 auto; 
     & h1 {
         padding-bottom: 5px;
     }
`;

const StyledTextarea = styled.textarea`
    width: 100%;
    height: 100px;
    background-color: #ddf1f0;
`;

const FlexRowNext = styled(FlexRow)`
    flex: 1;
    justify-content: center;
    padding: 10px;
`;

function FeedbackOpen({ onClick }) {

    const { t } = useTranslation();
    const [ openAnswer, setOpenAnswer] = useState('');

    return (
        <React.Fragment>
            <FeedbackWrapper>
            <h1>עוד משהו?</h1>
            <FlexRow>
                <StyledTextarea value={openAnswer} onChange={(e) => {setOpenAnswer(e.target.value);} }></StyledTextarea>
            </FlexRow>
            <FlexRowNext style={ { flexDirection: 'row-reverse'} }>
                <EditorButton onClick={ () => onClick(openAnswer) } >{t('submit')}</EditorButton>
            </FlexRowNext>
            </FeedbackWrapper>
        </React.Fragment>
    )
}

export default FeedbackOpen;