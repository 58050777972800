import React from 'react';
import useGlobal from '../store';
import { getGameMetadata } from './../store/selectors';

import styled from '@emotion/styled';
import GameRow from './GameRow';

function PreviewMetadata() {

    const [globalState, globalActions] = useGlobal();
    const gameMetadata = getGameMetadata(globalState);
    const {coverImage, name, locationCity, locationText, price, currencyCode} = gameMetadata || {};

    return (
        <GameRow
            coverImage={coverImage}
            name={name}
            locationCity={locationCity}
            locationText={locationText}
            price={price}
            currencyCode={currencyCode}
        />
    );


}

export default PreviewMetadata;