import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const ErrorWrapper = styled.div`
    color: #c80000;
`;


function ValidationError({ field, error }) {

    const { t } = useTranslation();
    
    // metadata.name
    const getValidationMessage = ({error, field}) => {
        let traslationKey = field;
        let translationValues = {};

        if (field.startsWith('q-') || field.startsWith('map-q-') || field.startsWith('qh-')) {
            let values = field.replace('map-q-', '');
            values = values.replace('q-', '');
            values = values.replace('qh-', '');
            values = values.replace('.content', '');
            values = values.replace('.location', '');
            values = values.replace('.answer.value', '');
            console.log('values: ', values);
            traslationKey = field.replace(values, '')
            const valuesArray = values.split('-');
            translationValues = { questionNumber: Number(valuesArray[0])+1, hintNumber: Number(valuesArray[1])+1 }
        }
        console.log('translationValues:', translationValues);
        
        return t(`ge.validation.${error}.${traslationKey}`, translationValues);
    }
    
    return (
        <ErrorWrapper>
            <div>{getValidationMessage({field, error})}</div>
        </ErrorWrapper>
    )
  }

  export default ValidationError;