import React from 'react';
import styled from '@emotion/styled';
import FlexCol from './FlexCol';

const StyledLabel = styled.label`
    width: 100%;
    margin-top: 16px; 
`;

const StyledFlexCol = styled(FlexCol)`
    width: 100%; 
`;

const Spacer = styled.div`
    height: 10px;
`;


const StyledInput = styled.input`
    display: block;
    min-height: 38px; 
    width: 100%; 
    padding: 2px 8px; 
    border-radius: 4px; 
    /* &:focus {
        outline: none;
    } */
`;

function AdminInputField({label, value, onChange, placeholder, errorMessage, isWhite, ...rest}) {

    return (
        <StyledFlexCol>
            <StyledLabel  > {label}
            <StyledInput value={ value} placeholder={ placeholder } onChange={ onChange } isWhite={isWhite} {...rest} />
            </StyledLabel>
            {/* <div>{errorMessage}&nbsp;</div> */}
            <Spacer></Spacer>
        </StyledFlexCol>
    );
  }

  export default AdminInputField;