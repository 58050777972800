import React from 'react';

import LoadingOverlay from 'react-loading-overlay';
import { BarLoader } from 'react-spinners';

function Loader({ active, text = 'Loading...', children }) {
    return (
        <LoadingOverlay
            active={active}
            styles={{ wrapper: { top: '100px' } }}
            spinner={<BarLoader height={10} color={ 'red' }/>}
            text=''
        >
            { children }
        </LoadingOverlay>
    );
}

export default Loader;