import React from 'react';
import styled from '@emotion/styled';

const StyledContentWrapper = styled.div`
    background-color: white;
    ${props => props.isOnWhite ? 'background-color: #ddf1f0;' : ''}
    ${props => props.maxWidth ? `max-width: ${props.maxWidth};` : ''}
    border-radius: 52px; 
    border: 1px solid #a7a9ac;
    box-shadow: 3.5px 5px 10px 0px rgba(0,0,0,0.25);
    margin: 10px; 
    padding: 5px; 
    font-size: 16px; 
    tab-size: 4;

    h1 {
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        padding-bottom: 20px;
    }

    p {
        padding-bottom: 0; 
    }

    img {
        max-width: 100%;
        padding: 10px 0;
    }
    img.not-content {
        max-width: initial;
        padding: initial;
    }

    video {
        max-width: 100%;
    }

    ol, ul {
        padding-inline-start: 2.5em;
        list-style-type: disc;
        padding-bottom: 0;
    }
    ol {
        list-style-type: decimal;
    }

`;

const BottomSpacer = styled.div`
    height: 40px; 
`;

function ContentWrapper({ withButton, children, maxWidth, isOnWhite }) {
    return (
        <StyledContentWrapper maxWidth={maxWidth} isOnWhite={isOnWhite}>
            {children}
            {!!!withButton && <BottomSpacer />}
        </StyledContentWrapper>
    );
}

export default ContentWrapper;