import React from 'react';
import styled from '@emotion/styled';

const ColoredBackground = styled.div`
    background-color: ${ props => props.backgroundColor || 'white'};
    direction: ltr;
`;

const getColor = (env) => {
    switch (env) {
        case 'production':
            return 'red';

        case 'staging':
            return 'orange';

        case 'development':
            return 'yellow';

        default:
            return 'red';
    }
}

function EnvironmentData({ env }) {

    const productionHostNames = [
        'www.street-hunters.com',
        'street-hunters.herokuapp.com'
    ];
    const hostname = window.document.location.hostname;

    // return null; //
    // console.log('TODO: Remove line before');
    
    if (productionHostNames.includes(hostname) && env === 'production') {
        return null;
    }

    return (
        <React.Fragment>
            <ColoredBackground backgroundColor={getColor(env)}>
                Env: {env}
            </ColoredBackground>
        </React.Fragment>
    )
}

export default EnvironmentData;