import React from 'react';
import FlexRow from '../../components/common/FlexRow';
import styled from '@emotion/styled';
import ArrowDiv from '../../components/common/ArrowDiv';
import LandingPageForm from '../../components/LandingPageForm';
// import { useTranslation } from 'react-i18next';
// import { Link } from 'react-router-dom';
// import ContentWrapper from './..../components/common/ContentWrapper';

// const StyledAbout = styled.div`
//     // text-align: center;
// `;


// const MakeMoney = styled.div`
//     padding-bottom: 20px; 
// `;

const LandingPageWrapper = styled.div`

    font-size: 25px; 
    background-color: white;

    h2 {
        font-size: 35px;
    }

    .section {
        padding: 20px 36px;
        position: relative;
        
    }

    .section1 {
        
        color: #0f2547;
        background-color: white; /* #3babab; */
        background-image: url('/images/lp/lp_header_bg.png');
        background-repeat: no-repeat;
        background-position: right top;

        .page-header {
            font-size: 70px;
            min-height: 210px; 

            @media screen and (max-width: 600px) {
                font-size: 38px;
                text-align: center;
            }

        /* background-image: url('/images/lp/lp_header_bg.png');
        background-repeat: no-repeat;
        background-position: right bottom;
        padding-right: -36px; */

        }

        .text {
            max-width: 540px; 
        }

        .girl {
            position: absolute;
            bottom: 0;
            right: 540px;

            @media screen and (max-width: 940px) {
                display: none;
            }

        }
    }    

    .section2 {
        color: white;
        background-color: purple;

        .text {
            max-width: 587px; 
        }

        .map-icon {
            position: absolute;
            bottom: 20px;
            right: 650px;
            width: 200px;

            @media screen and (max-width: 940px) {
                display: none;
            }
        }
    }    

`;

const ArrowsFlexRow = styled(FlexRow)`
    flex-wrap: wrap;
`;

const FormWrapper = styled.div`
    @media screen and (min-width: 1200px) {   
    position: absolute;
    top: 360px;
    right: 890px;
    }
`;

function Guides(props) {

    // const { t } = useTranslation();

    return (
        <LandingPageWrapper>

            <div className='section section1'>
                <div className='page-header'>מדריכים? <br/>רוצים לקוחות משלמים?</div>
                <div className='text'>
                    <h2>שימו לב !!!</h2>
                    תוסיפו את המסלול שלכם במערכת שלנו, ללא עלות כספית, ותוכלו להגיע לעוד הרבה לקוחות.
                    <br /> <br />
                    קשה לכם למכור את המוצר שלכם לקבוצות קטנות?
                    <br/>
                    בשיטה שלנו זו לא בעיה.
                {/* <br /><br />
                קשה לכם לתאם סיורים במהלך היום? 
                <br/>
                אצלנו כל אחד יכול לשחק בכל שעה.  */}
                <img className='girl' src='/images/lp/lp_girl_pointing_up.png' alt='Pointing Girl' />

                </div>
            </div>
        
            <div className='section section2'>
                <div className='text'>
                <h2>במה מדובר בעצם?</h2>
                    Street-Hunters -
                    משחק חוויתי ברחבי העיר הכולל מסלול לאורך נקודות עניין, וחידות הקשורות לכל נקודה ונקודה.
                <br />
                לכל חידה במהלך המסלול ישנם רמזים אשר עוזרים להגיע לפתרון החידה במידה ולא מצליחים.
                <br />
                ניתן לשחק כמשחק תחרותי מול שעון,
                או מול קבוצות אחרות שכבר שיחקו בו בעבר,
                בדומה ל "מירוץ למיליון" או "חפשו את המטמון",
                <br />
                מצד שני, אפשר גם לשחק אותו כסיור חוויתי לימודי המעשיר את השחקנים בידע והכרות עם המקום / העיר.
                <img className='map-icon' src='/images/lp/lp_map_icon.svg' alt='Map Icon' />
                </div>
            </div>


            <div className='section section3'>
                <h2>רוצים להתחיל להרוויח כסף?</h2>
                <b>כל מדריך שיש לו ידע והתמחות בתחום מסויים, 
                    <br />
                    יכול ליצור משחק, ולהגדיל את כמות הלקוחות שלו.</b>
                <br /><br />
                <ArrowsFlexRow>
                    <ArrowDiv backgroundColor='#91d5d1' >יצירת משחק מוצלח</ArrowDiv>
                    <ArrowDiv backgroundColor='#5dbed1' >דירוג גבוה יותר</ArrowDiv>
                    <ArrowDiv backgroundColor='#6eabdb' >יותר שחקנים</ArrowDiv>
                    <ArrowDiv backgroundColor='#c79ef5' >יותר כסף לכם בכל חודש</ArrowDiv>
                </ArrowsFlexRow>
                <br />
                <div>
                    אנחנו דואגים לטכנולוגיה, הכוללת את המשחק עצמו, מערכת לבניה וניהול של משחקים וסליקה אונליין
                    <br />
                    אתם רק צריכים להכניס למערכת את המסלול שלכם, בתוספת חידות.
                    <br /><br />
                    <b>
                    בתכלס, אפשר להגדיל את מעגל הלקוחות שלכם כבר עכשיו, ובקלות. גם בימי הקורונה!!
                    </b>

                    <div>
                    בין אם העסק שלכם כבר ממש רווחי, 
                    ובין אם כרגע עדיין חסרים לכם לקוחות, 
                    אנחנו יכולים להנגיש אותו לעוד לקוחות, 
                    ואתם מרוויחים מזה כסף.
                    </div>
                </div>
            </div>

            <div>
            <div style={ { maxWidth: '500px', margin: '0 auto' } }>
            <FormWrapper>
                <LandingPageForm formType='guides'/>
            </FormWrapper>
                </div>
            </div>

            <br /><br /><br />

        </LandingPageWrapper>
    )
}

export default Guides;