import React from 'react';
import { useEffect } from 'react';
import { calculateGameScore } from '../utils';
import SimpleTable from './common/simple-table/SimpleTable';

function GameStatistic({ activeGameData }) {

    useEffect(() => {

    }, []);

    if (!activeGameData) {
        return null;
    }

    const { questions, payment } = activeGameData;

    const totalQuestions = questions && questions.length;
    const gameScore = calculateGameScore(activeGameData);

    const columns = [
        {
            Header: 'שאלה',
            accessor: 'id',
            Cell: ({ row }) => (row.index + 1)
        },
        {
            Header: 'סטטוס',
            accessor: 'status'
        },
        {
            Header: 'רמזים',
            accessor: 'hints',
            Cell: ({ row }) => (row.original.hints ? row.original.hints.filter(h => h && h.isOpen).length : 0)
        },
        {
            Header: 'רמזים שנפתחו',
            accessor: 'hints2',
            Cell: ({ row }) => (row.original.hints ? row.original.hints.reduce((acc, item, index) => ((item && item.isOpen) ? [...acc, index+1] : [...acc]) ,[]).join(',') : '')
        }        
    ];
// console.log('gameData: ', activeGameData);

    return (
        <React.Fragment>
            Total Score: {gameScore}
            <br />
            Total Questions: {totalQuestions}
            <br />
            <SimpleTable columns={columns} data={questions} />
            <hr />
            שם: {payment && payment.name} <br />
            אימייל: {payment && payment.email} <br />
            טלפון: {payment && payment.phone}
        </React.Fragment>
    )
}

export default GameStatistic;