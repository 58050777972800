import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import ReactQuill, { Quill } from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
// import Modal from './Modal';
import Modal from 'react-modal';
import FileUploader from './FileUploader';

const BlockEmbed = Quill.import('blots/block/embed');
class SimpleVideoBlot extends BlockEmbed {
  static create(url) {
    let node = super.create();
    node.setAttribute('src', url);
    node.setAttribute('controls', '');
    return node;
  }
  
  static value(node) {
    return node.getAttribute('src');
  }
}
SimpleVideoBlot.blotName = 'video';
SimpleVideoBlot.tagName = 'video';
Quill.register(SimpleVideoBlot);


const Wrapper = styled.div`
    .ql-toolbar {
        direction: ltr;
        border: none;

        &.ql-snow .ql-formats {
           margin-right: 8px;
        }
    }
    .ql-editor {
        text-align: left;
        direction: ltr;
        font-size: 16px;
        line-height: 1; 

        h1 {
            font-size: 24px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 20px; 
            
        }

        p {
            margin-bottom: 0; 
        }

        img {
            padding: 10px 0;
        }

        video {
            max-width: 100%;
        }

        ol > li {
            list-style-type: decimal;
        }
        ul > li {
            list-style-type: disc;
        }

        ol, ul {
            padding: 0;
            padding-inline-start: 1.5em;
            list-style-type: disc;

            counter-reset: initial;
            counter-increment: initial;
        }
        ol {
            list-style-type: decimal;
        }

        /* li {
            padding-right: 1.5em;
        } */

        li:not(.ql-direction-rtl)::before {
            margin-left: initial;
            margin-right: initial;
            text-align: initial;
        }
        ol li {
            counter-reset: initial;
            counter-increment: initial;
            padding-left: initial;
        }
        ol li:before,  ul li:before {
            content: initial;
        }
        li::before {
            display: initial;
            white-space: initial;
            width: initial;
        }

    }

    div[dir="rtl"] & {
        .ql-editor {
            text-align: right;
            direction: rtl;
        }
    }
`;

const CloseButton = styled.span`
    padding: 0 10px;
    float: left;
    border-radius: 5px;
    cursor: pointer;

    &:hover{
        background-color: #dddddd;
    }
`;

const CustomToolbar = ({ setMediaType, setIsModalOpen }) => (
    <div id="toolbar">
        <select className="ql-header" defaultValue="">
            <option value="1"></option>
            <option value=""></option>
        </select>

        <span className="ql-formats">
            <button className="ql-bold"></button>
            <button className="ql-italic"></button>
            <button className="ql-underline"></button>
            <button className="ql-clean"></button>
        </span>

        <span className="ql-formats">
            <button className="ql-list" value="ordered"></button>
            <button className="ql-list" value="bullet"></button>
        </span>

        <span className="ql-formats">
            <button className="ql-link"></button>
            <button className="ql-image" onClick={() => { setMediaType('image'); setIsModalOpen(true);} }></button>
            <button className="ql-video" onClick={() => { setMediaType('video'); setIsModalOpen(true);} }></button>
        </span>
    </div>
)


const HtmlEditor = ({ value, onChange, placeholder }) => {

    let reactQuillRef = null;
    let quillRef = null;

    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [mediaType, setMediaType] = useState('image');

    const openModal = () => {
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };

    const afterOpenModal = () => {
        // console.log('afterOpenModal()! ');
    }

    Modal.setAppElement('#root');

    const attachQuillRefs = () => {
        if (typeof reactQuillRef.getEditor !== 'function') return;
        quillRef = reactQuillRef.getEditor();
      }
    
    const insertMedia = (mediaUrl) => {
        attachQuillRefs();
        const range = quillRef.getSelection();
        const position = range ? range.index : 0;

        //quillRef.insertEmbed(position, 'image', mediaUrl, 'user');
    
        // const embedType = mediaUrl.endsWith('.mp4') ? 'video' : 'image';
        quillRef.insertEmbed(position, mediaType || 'image', mediaUrl, 'user');
        setIsModalOpen(false);
    }

    return (
        <React.Fragment>
            <Modal
                isOpen={isModalOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
            >
                <h1>{t('media.management')}
                <CloseButton onClick={closeModal} style={{ float: 'left' }}>X</CloseButton>
                </h1>
                <FileUploader mediaType={ mediaType} onSelect={ insertMedia } />
            </Modal>

            <Wrapper>
                <CustomToolbar setMediaType={setMediaType} setIsModalOpen={setIsModalOpen} />
                <ReactQuill
                    theme='snow'
                    ref={(el) => { reactQuillRef = el; }}
                    value={value}
                    onChange={onChange}
                    modules={HtmlEditor.modules}
                    formats={HtmlEditor.formats}
                    placeholder={placeholder}
                />
            </Wrapper>
        </React.Fragment>
    );
}

HtmlEditor.modules = {
    toolbar: {
        container: '#toolbar',
        handlers: {
          'image': () => { },
          'video': () => { }
        }
    },
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    }
}

/* 
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
HtmlEditor.formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    // 'direction', 'align',
    'link', 'image', 'video'
]


export default HtmlEditor;
