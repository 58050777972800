import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import Content from './Content';
import UIfx from 'uifx';
import wrongAnswerSound from '../sounds/bad_answer.mp3';
import goodAnswerSound from '../sounds/good_answer.mp3';
import ModalConfirmation from './common/ModalConfirmation';

import useGlobal from "../store";
import { getGameQuestion, getGameQuestionStatus } from '../store/selectors';
import axios from 'axios';

import FlexRow from './common/FlexRow';
import BadIcon from './../images/bad_icon.png';
import GoodIcon from './../images/good_icon.png';
import RoundButton from './common/RoundButton';
import SkipQuestion from './SkipQuestion';
import ModalReportQuestion from './common/ModalReportQuestion';
import ReportQuestion from './ReportQuestion';

const StyledInput = styled.input`
    display: block;
    background-color: #ddf1f0;
    /* font-size: 1.5em; */
    font-size: 24px; 
    width: 190px;
    text-align: center;
    height: 34px;
    border-radius: 17px;
    border: 1px solid silver;
    box-shadow: inset 1px 1.5px 10px 0px rgba(0,0,0,0.15);
    &:focus {
        outline: none;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    top: 25px;
`;

const CenteredFlexRow = styled(FlexRow)`
    justify-content: center;
    align-items: center;
`;
const AnswerCaption = styled.div`
    padding: 17px 0;
    font-size: 22px;
    font-weight: bold;
    color: #3babab;
`;

const Spacer = styled.div`
    width: 50px;
`;

const AnswerIcon = styled.img`
    width: 2em;
    height: 2em;
`;

function Answer({ questionIndex }) {

    const { t } = useTranslation();

    const [globalState, globalActions] = useGlobal();
    const question = getGameQuestion(globalState, questionIndex);
    const questionStatus = getGameQuestionStatus(globalState, questionIndex);;

    const [enteredAnswer, setEnteredAnswer] = useState('');
    const [answerIcon, setAnswerIcon] = useState('');
    const [isSkipModalOpen, setIsSkipModalOpen] = useState(false);
    const [isReportModalOpen, setIsReportModalOpen] = useState(false);

    const rightAnswer = question && question.answer && question.answer.value;
    const content = question && question.answer && question.answer.content;
    const contentAfterAnswer = question && question.answer && question.answer.contentAfterAnswer;

    const soundWrong = new UIfx(wrongAnswerSound);
    const soundGood = new UIfx(goodAnswerSound);

    const handleAnswerChanged = (e) => {
        setEnteredAnswer(e.target.value);
        setAnswerIcon('');
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleAnswerClick();
        }
    }
    const isGoodAnswer = (enteredAnswer, rightAnswer) => {
        return enteredAnswer && rightAnswer.split(',').map(answer=> answer.toLowerCase().trim()).includes(enteredAnswer.toLowerCase())
    }

    const handleAnswerClick = async () => {
        // if (rightAnswer.toLowerCase() === enteredAnswer.toLowerCase()) {
            if (isGoodAnswer(enteredAnswer, rightAnswer)){
            soundGood.play();
            // globalActions.setQuestionStatus(questionIndex, 'ANSWERED');

            const storageGameProgress = JSON.parse(localStorage.getItem('active-game'));
            const { game_id, game_secret } = storageGameProgress || {};

            await axios.post(`/api/game-progress`, {
                "action": "set-question-status",
                "gameId": game_id,
                "secretCode": game_secret,  // "qweqwe"
                "questionIndex": questionIndex,
                "questionStatus": 'ANSWERED'

            });  // TODO catch error (Server 500)

            setAnswerIcon('good');
        } else {
            soundWrong.play();
            setAnswerIcon('bad');
        }
    }

    const skipQuestion = async () => {
        const storageGameProgress = JSON.parse(localStorage.getItem('active-game'));
        const { game_id, game_secret } = storageGameProgress || {};
        setEnteredAnswer(rightAnswer.split(',')[0]);

        await axios.post(`/api/game-progress`, {
            "action": "set-question-status",
            "gameId": game_id,
            "secretCode": game_secret,
            "questionIndex": questionIndex,
            // "questionStatus": 'SKIPPED'
            "questionStatus": 'SOLUTION'
        });  // TODO catch error (Server 500)
        closeSkipQuestionModal();
    };

    const handleSkipQuestion = (e) => {
        setIsSkipModalOpen(true);
    }

    const closeSkipQuestionModal = () => {
        setIsSkipModalOpen(false);
    }

    const handleReportQuestion = (e) => {
        setIsReportModalOpen(true);
    }

    const closeReportQuestionModal = () => {
        setIsReportModalOpen(false);
    }

    const handleAnswerDone = async () => {
        const storageGameProgress = JSON.parse(localStorage.getItem('active-game'));
        const { game_id, game_secret } = storageGameProgress || {};

        await axios.post(`/api/game-progress`, {
            "action": "set-question-status",
            "gameId": game_id,
            "secretCode": game_secret,  // "qweqwe"
            "questionIndex": questionIndex,
            "questionStatus": questionStatus === 'SOLUTION' ? 'SKIPPED' : 'DONE'

        });  // TODO catch error (Server 500)

    }

    useEffect(() => {
        setEnteredAnswer('');
    }, [questionIndex]);

    useEffect(() => {
        if (questionStatus === 'SOLUTION') {
            setEnteredAnswer(rightAnswer.split(',')[0]);
        }
    }, []);

    return (
        <React.Fragment>
            <ModalConfirmation
                isOpen={isSkipModalOpen}
                text={ t('skip.question?') }
                onApprove={skipQuestion}
                onCancel={closeSkipQuestionModal} 
            />
            <ModalReportQuestion 
                isOpen={isReportModalOpen}
                onApprove={skipQuestion}
                onClose={closeReportQuestionModal} 
                questionIndex={questionIndex}
            />
            <CenteredFlexRow>
                <AnswerCaption>
                    { t('answer')}
                </AnswerCaption>
            </CenteredFlexRow>
            <CenteredFlexRow>
                <Spacer></Spacer>
                <StyledInput type="text" autoCorrect="off" autoCapitalize="none"
                    value={enteredAnswer}
                    readOnly={questionStatus === 'ANSWERED'}
                    onChange={handleAnswerChanged}
                    onKeyDown={handleKeyDown} />
                <Spacer>
                    {answerIcon === 'good' && <AnswerIcon src={GoodIcon} alt='Good Answer' className="not-content" />}
                    {answerIcon === 'bad' && <AnswerIcon src={BadIcon} alt='Bad Answer' className="not-content" />}
                </Spacer>
            </CenteredFlexRow>
            <div>
                {
                    (questionStatus !== 'ANSWERED' && questionStatus !== 'SOLUTION') && (
                        <React.Fragment>
                            <Content content={content} />
                            <SkipQuestion questionIndex={questionIndex} onClick={() => handleSkipQuestion()}>דלג</SkipQuestion>
                            <br/>
                            <ReportQuestion questionIndex={questionIndex} onClick={() => handleReportQuestion()}></ReportQuestion>
                        </React.Fragment>
                    )
                }
            </div>
            <div>
                {
                    (questionStatus !== 'ANSWERED' && questionStatus !== 'SOLUTION') && (
                        <ButtonWrapper>
                            <RoundButton width={200} text={ t('submit')} onClick={handleAnswerClick}></RoundButton>
                        </ButtonWrapper>
                    )
                }
                {
                    (questionStatus === 'ANSWERED' || questionStatus === 'SOLUTION') && (
                        <React.Fragment>
                            <Content content={contentAfterAnswer} />
                            <ButtonWrapper>
                                <RoundButton width={200} text={ t('continue')} onClick={handleAnswerDone}></RoundButton>
                            </ButtonWrapper>

                        </React.Fragment>
                    )
                }
            </div>
        </React.Fragment>
    );
}

export default Answer;