import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ContentWrapper from '../components/common/ContentWrapper';

const StyledAbout = styled.div`
    // text-align: center;
`;


const PageText = styled.div`
    padding-bottom: 20px; 
`;
function About(props) {

    const { t } = useTranslation();

    return (
        <ContentWrapper>
            <h1>{ t('page.title.about')}</h1>
            <PageText>
                { t('page.not.ready') } - { t('click')}&nbsp;
    <Link to="/games" >{ t('here') }</Link>
            </PageText>

            { 
                false && (
            <div>
            אחרי שנים שאני חושב איך לגרום לאנשים לצאת מהמסך, לטייל, ולהנות מהשמש,
            
            ואחרי שנים שאני חושב איך אני כמתכנת יכול לתרום לידע ולפיתוח המחשבה אצל אנשים ככלל וילדים בפרט,
            
            צץ לו הרעיון של פעילויות חוץ שמופעלות ע"י אפליקציה, וגורמות לאנשים לצאת ולשחק מחטץ לבית. 
            <br /><br />
            המשחקים שונים ומגוונים, חלק מהמשחקים הם משחקי חשיבה שבהם תוך כדי טיול בטבע או בעיר השחקנים (אתם) נדרשים לפתור חידות  
            (בסגנון חדרי הבריחה המוכרים), 
            וחלק מהמשחקים ויכולים להיות משחקי ידע שבהם המשתתפים מטיילים ורוכשים ידע תטך כדי הטיול עצמו. 
            <br />
            <br />
            למעשה המשחקים הראשונים נוצרו מראש, נבדקו, ונוספו לאתר באופן אישי, 
            ובהמשך נכנסו משחקים נוספים ע"י אנשים בעלי ידע שלמעשה בנו משחקים בעצמם, והם לגמרי שותפים בהצלחה של המהפיכה הזו.
            <br />
            <br />
            רוצים גם ליצור משחקים שכאלה? ואפילו להרוויח מהם כסף?
            <br />
            <a href="#" >לחצו כאן</a>
            
            <h2>קצת עליי</h2>
            בתחילת דרכי בעולם האינטרנט פיתחתי אתר הכרויות אשר נחשב מאוד מוצלח, ובו גלשו מאות אלפי רווקים ורווקות ישראליים, 
            ודרכו היו כ-800-900 חתונות. 
            השנים חלפו, והאתר הפך להיות פחות רלוונטי וחיפשתי לי את 
            "הדבר הבא". 
            <br />
            חלפו להן השנים והנה אני יוצא עם הלהיט הבא בו כל אחד ואחת יכול/ה לצאת ולשחק בחוץ עם חברים, 
            וכל משפחה יכולה לטייל בעיר / בטבע וללמוד תוך כדי על המקום בו מטיילים.
            <br /><br />
            אני תמיד שמח לשמוע מה דעתכם, אז אם יש לכם מה להגיד, או בא לך להציע משהו לאתר, 
            פשוט תכתבו לי ;-) 
            <br />
            <a href="mailto:xxx@xxx.com" >שלחו לי אימייל</a>

            </div>
            )
        }
        
        </ContentWrapper>
    )
}

export default About;