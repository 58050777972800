import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import ContentWrapper from '../components/common/ContentWrapper';
import FlexRow from './common/FlexRow';
import UIfx from 'uifx';
import okSound from '../sounds/ok_beep.mp3';
import wrongSound from '../sounds/bad_answer.mp3';

import axios from 'axios';

import GoodIcon from './../images/good_icon.png';
import BadIcon from './../images/bad_icon.png';

import useGlobal from "../store";
// import { getGameIntroPage, getGameStartTime, getGameEndTime } from '../store/selectors';
import RoundButton from './common/RoundButton';
import { useEffect } from 'react';
import GamePayment from './GamePayment';
import GamePaymentCode from './GamePaymentCode';
import EditorButton from './common/EditorButton';



const AnswerIcon = styled.img`
    width: 2em;
    height: 2em;
    padding: 0; 
`;

const CenteredFlexRow = styled(FlexRow)`
    justify-content: center;
    align-items: center;
`;

const Spacer = styled.div`
    width: 50px;
`;

const StyledInput = styled.input`
    display: block;
    background-color: #ddf1f0;
    font-size: 1.5em;
    width: 190px;
    text-align: center;
    height: 34px;
    border-radius: 17px;
    border: 1px solid silver;
    box-shadow: inset 1px 1.5px 10px 0px rgba(0,0,0,0.15);
    &:focus {
        outline: none;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    top: 25px;
    position: relative;
    /* text-align: center; */
    /* margin-bottom: 20px;  */
`;

function PaymentPage(props) {

    const { gameId } = props;

    const soundWrong = new UIfx(wrongSound);
    const soundOk = new UIfx(okSound);

    const { t } = useTranslation();
    const [globalState, globalActions] = useGlobal();
    // const introPage = getGameIntroPage(globalState);

    const [secretCode, setSecretCode] = useState('');
    const [secretCodeToFetch, setSecretCodeToFetch] = useState('');
    const [secretCodeIsValid, setSecretCodeIsValid] = useState(null);
    const [isLoading, setIsLoading] = useState('');
    const [showPayment, setShowPayment] = useState(false);


    // const gameId = '5e57c834e4de2790c2c9bace';
    useEffect(() => {

        let isMounted = true;
        const checkSecret = async () => {
            setIsLoading(true);
            const response = await axios.post(`/api/game-progress/`, {
                "action": "set-start-time",
                "gameId": gameId,  // "5e57c834e4de2790c2c9bace",
                "secretCode": secretCodeToFetch // "qweqwe"
            });  // TODO catch error (Server 500)

            const json = response.data;
            setIsLoading(false);
            const { game_id, game_secret } = json && json.gameProgress || {};

            if (game_id && game_secret) {
                localStorage.setItem('active-game', JSON.stringify({ game_id, game_secret }));
            }

            if (json.status === 'OK') {
                setSecretCodeIsValid(true);
                soundOk.play();
                if (isMounted) {
                    // globalActions.setGameStartTime();
                    //     globalActions.setDisplayPage('questions');
                }
            } else {
                setSecretCodeIsValid(false);
                soundWrong.play();
            }
        };

        if (secretCodeToFetch !== '') {
            checkSecret();
        }

        return () => isMounted = true;

    }, [secretCodeToFetch]);

    const handleSecretCodeChange = (e) => {
        setSecretCode(e.target.value);
        setSecretCodeIsValid(null);
    }

    const handleCheckSecretCode = () => {
        setSecretCodeToFetch(secretCode);
    }

    const handleContinue = () => {
        globalActions.setDisplayPage('questions');
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            secretCodeIsValid
                ? handleContinue()
                : handleCheckSecretCode();
        }
    }

    return (
        <React.Fragment>
            {
                showPayment
                    ? (<GamePayment gameId={gameId} />)
                    : (<GamePaymentCode gameId={gameId} onShowPayment={ () => { setShowPayment(true);} } />)
            }

            <br /><br /><br />

            {
                showPayment && 
                     (
                        <div style={{ textAlign: 'center' }}>
                            {t('game.payment.have.code')}
                            <br />
                            <EditorButton onClick={ () => setShowPayment(false) } size="small" >{t('back.to.game')}</EditorButton>
                        </div>

                    )
            }

        </React.Fragment>
    );
}

export default PaymentPage;