import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { get } from 'lodash/fp';
import axios from 'axios';
import utils from './../utils';
import { useHistory } from 'react-router-dom'
import cookiesUtil from '../utils/cookies';
import FlexRow from '../components/common/FlexRow';
import ContentWrapper from '../components/common/ContentWrapper';
import InputField from '../components/common/InputField';
import RoundButton from '../components/common/RoundButton';
import StyledLink from '../components/common/StyledLink';
import Loader from '../components/common/Loader';


const ErrorMessage = styled.div`
    font-weight: bold;
    color: red; 
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    top: 25px;
    position: relative;
    /* text-align: center; */
    /* margin-bottom: 20px;  */
`;

const Links = styled.div`
    text-align: center;
    margin-top: 50px;
`;

function Signup(props) {

    const { t } = useTranslation();
    
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isRegistered, setIsRegistered] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const history = useHistory();

    const handleSubmit = (e) => {
        e.preventDefault();
        const aff = cookiesUtil.getCookie('aff');
        
        setIsLoading(true);
        axios.post('/api/signup', { firstName, lastName, email, password, phone, aff })
            .then(response => {
                console.log('response: ', response);
                if (get('status', response) === 200) {
                    setIsRegistered(true);
                    // history.push('/login');
                }
            })
            .catch(err => {
                const errorMessage = get('response.data.message', err);
                setErrorMessage(errorMessage);
            })
            .finally(() => {
                setIsLoading(false);
            })
    };

    useEffect(() => {
        setErrorMessage('');
    }, [firstName, lastName, email, password, phone])


    if (isRegistered) {
        return (
            <ContentWrapper withButton>
            <h1>{ t('page.title.registration')}</h1>
            <p style={ { width: '300px', margin: '0 auto',textAlign: 'center' } }>
                { t('thanks')}.<br/><br/>
                { t('signup.email.sent.with.link') }
                <br/><br/>
            </p>
            </ContentWrapper>
        );
    }


    return (
        <React.Fragment>
            <Loader active={ isLoading }>
        <ContentWrapper withButton>
            <h1>{ t('page.title.registration') }</h1>

            <form onSubmit={handleSubmit} style={ {width: '300px', margin: '0 auto'}} noValidate={false}>
            <FlexRow>
                <InputField label={ t('first.name') } value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder={ t('first.name') } required />
            </FlexRow>

            <FlexRow>
                <InputField label={ t('last.name') } value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder={ t('last.name') } required />
            </FlexRow>

            <FlexRow>
                <InputField label={ t('email') } dir="ltr" value={email} onChange={(e) => setEmail(e.target.value)} placeholder={ 'Email' } type="email" required />
            </FlexRow>

            <FlexRow>
                <InputField label={ t('password') } dir="ltr" type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder={ 'Password' } required />
            </FlexRow>

            <FlexRow>
                <InputField label={ t('phone') } dir="ltr" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder={ 'Phone' } required />
            </FlexRow>

            <FlexRow>
                <ErrorMessage>{errorMessage} &nbsp;</ErrorMessage>
            </FlexRow>

            <ButtonWrapper>
                <RoundButton type="submit" value="submit" width={190} text={ t('submit') } />
            </ButtonWrapper>
            </form>

        </ContentWrapper>

        <Links>
            <StyledLink to="/" >{ t('page.title.home')}</StyledLink>
            <StyledLink to="/forgot-password" >{ t('page.title.forgot.password') }</StyledLink>
            <StyledLink to="/login" >{ t('page.title.login')}</StyledLink>
        </Links>
        </Loader>
        </React.Fragment>
    )
}

export default Signup;