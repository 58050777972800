import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ContentWrapper from '../components/common/ContentWrapper';
import RoundButton from '../components/common/RoundButton';

const CodeHolder = styled.div`
    // text-align: center;
`;



const PageText = styled.div`
    padding-bottom: 20px; 
`;
function ExampleGame(props) {

    const { t } = useTranslation();
    const [gameCode, setGameCode] = useState('');

    const getExampleGameCode = () => {
        setGameCode('xxx');
    }

    return (
        <ContentWrapper>
            <h1>{t('page.example.example.game')}</h1>
            <div>
                {/* <br />
            <a href="mailto:xxx@xxx.com" >שלחו לי אימייל</a> */}
            לחצו כאן על מנת לקבל קוד הרצה למשחק לדוגמא.
            <RoundButton width='250' text={`קבלת קוד משחק`} onClick={() => getExampleGameCode()} >XXX</RoundButton>


                {
                    gameCode && (
                        <React.Fragment>
                            <CodeHolder>{gameCode}</CodeHolder>
                            <div>
                                יש להעתיק את הקוד (העתק/הדבק) כדי להשתמש בו להפעלת המשחק בעמוד הבא
                        </div>
                            <Link to={`/game/5ec698879986d52426bdc238`}></Link>
                        </React.Fragment>
                    )
                }
            </div>
        </ContentWrapper>
    )
}

export default ExampleGame;