import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import useGlobal from "../store";
import { msToTime } from './../utils';

import { getGameGameoverPage, getGameProgress } from '../store/selectors';

const StyledGameSummary = styled.div`
    padding-bottom: 20px;
`;

function GameSummary() {

    const { t } = useTranslation();
    const [globalState, globalActions] = useGlobal();
    const gameProgress = getGameProgress(globalState);

    const { startTime, endTime, questions } = gameProgress;

    if (!questions) {
        return null;
    }

    const totalGameTime = msToTime(new Date(endTime) - new Date(startTime));
    const totalQuestions = questions.length;
    const totalAnswerdQuestions = (questions.filter(q => q.status === 'DONE')).length;
    const allQuestionAnswered = totalAnswerdQuestions === totalQuestions;
    const totalSkippedQuestions = (questions.filter(q => q.status === 'SKIPPED')).length;
    const totalOpenHints = questions.map(q => q.hints ? q.hints.filter(h => h && h.isOpen).length : 0).reduce((a, b) => (a + b), 0);

    return (
        <React.Fragment>
                {

                    true && (

                        <StyledGameSummary>

                            { t('game.summary.finished.with.time') } {totalGameTime}
                            <br />
                            { t('game.summary.wow') },&nbsp;
                            {
                                allQuestionAnswered
                            ? (<span>{ t('game.summary.answered.all.questions', {totalQuestions})}</span>)
                            : (<span>{ t('game.summary.answered.questions', {totalAnswerdQuestions, totalQuestions})}</span>)
                            }
            ,<br />
                            {
                                totalSkippedQuestions
                            ? (<span>{ t('game.summary.skipped.questions', {count: totalSkippedQuestions} ) }</span>)
                            : (<span>{ t('game.summary.no.skipped.questions') }</span>)
                            }
            ,<br />
                            {
                                totalOpenHints
                            ? (<span>{ t('game.summary.hints.used', {count: totalOpenHints}) }</span>)
                            : (<span>{ t('game.summary.no.hints') }</span>)

                            }
                        </StyledGameSummary>
                    )
                }
        </React.Fragment>
    );
}

export default GameSummary;