import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import axios from 'axios';
import { useEffect } from 'react';

const ModalContainer = styled.div`
    text-align: center;
    font-size: 24px;
    font-weight: bold;

    background-color: #e1eef0;
    border: 1px solid #5dbed1;
    border-radius: 20px; 
    padding: 20px;
`;

const ModalTitle = styled.div`
    padding-bottom: 20px; 
`;

const ModalButton = styled.span`
    text-align: center;
    padding: 0 10px; 
    border-radius: 10px; 
    cursor: pointer;
    color: white;
    background-color: #3babab;
    min-width: 75px; 
    display:inline-block;
    margin: 0 10px; 
`;


function ModalReportQuestion({ isOpen, onClose, questionIndex}) {

    const { t } = useTranslation();    
    const defaultText = `${t('question.number')} ${questionIndex+1}\n`;
    
    const [reportMessage, setReportMessage] = useState(defaultText);
    const [errorMessage, setErrorMessage] = useState('');

    const handleSend = () => {
        if ((reportMessage.length < 5) || (reportMessage === defaultText)) {
            setErrorMessage(t('please.fill.details'));
            return; 
        }

        const storageGameProgress = JSON.parse(localStorage.getItem('active-game'));
        const { game_id, game_secret } = storageGameProgress || {};

        const postData = {
            gameId: game_id,
            gameSecret: game_secret,
            questionId: questionIndex+1, 
            message: reportMessage
        }
        axios.post('/api/game/report', postData)
        .then(response => {
            onClose();
        })
        .catch(error => {
            console.log('error: ', error);
        })
    }

    useEffect(() => {
        setReportMessage(defaultText);
    },[questionIndex])

    const handleTextChange = (e) => {
        setErrorMessage('');
        setReportMessage(e.target.value);
    }
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: 'none',
            padding: '0px'
        }
    };

    return (
        <Modal style={customStyles}
            isOpen={isOpen}
            // onAfterOpen={afterOpenModal}
            appElement={document.getElementById('root')}
            onRequestClose={onClose}
            closeModal={onClose}
        >
            <ModalContainer>
                <ModalTitle>דיווח על בעיה</ModalTitle>
                <textarea rows="10" style={ { width: '80vw'}} value={reportMessage} onChange={ handleTextChange} ></textarea>
                <div style={ { color: 'red'}}>
                    {errorMessage}&nbsp;
                </div>
                <ModalButton onClick={onClose} >{t('cancel')}</ModalButton>
                <ModalButton onClick={handleSend} >{t('submit')}</ModalButton>
            </ModalContainer>

        </Modal>
    );
}

export default ModalReportQuestion;