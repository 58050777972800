import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import ContentWrapper from '../components/common/ContentWrapper';
import axios from 'axios';
import FlexRow from '../components/common/FlexRow';
import InputField from '../components/common/InputField';
import RoundButton from '../components/common/RoundButton';

function ValidateEmail(props) {

    const { match: { params: { token } } } = props;

    const { t } = useTranslation();
    const [email, setEmail] = useState('');

    const [emailValidated, setEmailValidated] = useState(false);
    const [linkExpired, setLinkExpired] = useState(false);
    const [resentValidation, setResentValidation] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const validateToken = async () => {
            setIsLoading(true);
            await axios.post(`/api/user/validate-email`, { token })
                .then(result => {
                    if (result.status = 200) {
                        setEmailValidated(true);
                    }
                })
                .catch(error => {
                    console.log('error: ', error);
                    setLinkExpired(true);
                })
                .finally(() => setIsLoading(false));
        };

        if (token !== 'resend') {
            validateToken();
        }


    }, []);

    const resendValidation = async () => {
        setIsLoading(true);
        await axios.post(`/api/user/resend-validation`, { email })
            .then(result => {
                if (result.status = 200) {
                    setResentValidation(true);
                }
            })
            .catch(error => {
                console.log('error: ', error);
            })
            .finally(() => setIsLoading(false));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        resendValidation();
    }

    return (
        <ContentWrapper>
            <h1>{t('validate.email.email.validation')}</h1>
            <div style={ {width: '300px', margin: '0 auto'}} >
            {
                emailValidated && (
                    <div>
                        {t('validate.email.successfully.validation')}.<br />
                        <br />
                        <Link to="/login">
                            {t('click.here.to.continue')}
                        </Link>

                    </div>
                )
            }


            {
                (linkExpired || token === 'resend') && (
                    <div>
                        <form onSubmit={handleSubmit} >
                        {
                            linkExpired && (
                                <div>
                                {t('validate.email.link.not.valid')}
                                <br /><br />
                                </div>
                            )
                        }

                            {t('validate.email.enter.email.to.resend.link')}

                            <FlexRow>
                                <InputField type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                            </FlexRow>
                            <FlexRow style={ {justifyContent: 'center'} }>
                                <RoundButton text={t('submit')} width={190} type={t('submit')} />
                            </FlexRow>
                        </form>
                    </div>
                )
            }
            </div>
        </ContentWrapper>
    )
}

export default ValidateEmail;