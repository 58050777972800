import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import useGlobal from "../store";
import ContentWrapper from '../components/common/ContentWrapper';
import StyledLink from '../components/common/StyledLink';

const Links = styled.div`
    text-align: center;
    margin-top: 50px;
`;

function Logout(props) {

    const { t } = useTranslation();
    const [globalState, globalActions] = useGlobal();

    useEffect(() => {
        localStorage.removeItem('token');
        globalActions.setIsLoggedIn(null);
        globalActions.setLoggedInRole(null);
        globalActions.setUserId(null);
        globalActions.setIsSelfPayment(null);
    }, [])

    return (
        <React.Fragment>
            <ContentWrapper withButton>
                <h1>{t('logout.title')}</h1>
                <br />
                <div style={ {width: '300px', margin: '0 auto', textAlign: 'center'}} >
                    {t('logout.disconnected')}
                </div>
                <br /><br />
            </ContentWrapper>

            <Links>
                <StyledLink to="/" >{ t('page.title.home')}</StyledLink>
                <StyledLink to="/login" >{ t('page.title.login')}</StyledLink>
            </Links>
        </React.Fragment>
    )
}

export default Logout;