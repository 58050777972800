import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';

import useGlobal from "../store";
import { getGameStartTime, getGameEndTime } from '../store/selectors';

const DigitalFont = styled.span`
  font-family: Digital;
  font-size: 34px;
`;
// const MenuWrapper = styled.div``;

function GameTime() {

  // getGameDisplayPage
  const [globalState, globalActions] = useGlobal();
  const startTime = getGameStartTime(globalState);
  const endTime = getGameEndTime(globalState);
  const [gameTime, setGameTime] = useState('');
  
  const make2digits = (number) => number < 10 ? '0' + number : number;
  
  const calculateTime = (startTime, endTime) => {
      const eTime = endTime && new Date(endTime) || new Date(); 
      const sTime = startTime && new Date(startTime);
// console.log('{sTime, eTime}: ', {sTime, eTime});

      const diff = new Date(eTime - sTime);
      if (diff == 'Invalid Date'){
        // setGameTime('Invalid Date');
        return;
      } 
//console.log('diff: ', diff.get);
      
      const hour = diff.getUTCHours();
      const min = diff.getUTCMinutes();
      const sec = diff.getUTCSeconds();
      setGameTime(hour + ":" + make2digits(min) + ":" + make2digits(sec));  
  }

  useEffect(() => {
    const interval = setInterval(() => {
      calculateTime(startTime, endTime);
    }, 1000);
    return () => clearInterval(interval);
  }, [startTime, endTime]);


  if (!startTime) {
    return null; 
  }

  

    return (
        <DigitalFont> {gameTime} </DigitalFont>
    );
  }

  export default GameTime;