import React from 'react';
import { useTranslation } from 'react-i18next';
import useGlobal from '../store';
import { getUserId } from './../store/selectors';
import ContentWrapper from '../components/common/ContentWrapper';
import styled from '@emotion/styled';

const AffCode = styled.span`
    direction: ltr;
    color: black;
    font-weight:bold;
`;

function Reports(props) {

    const { t } = useTranslation();
    const [globalState, globalActions] = useGlobal();

    const userId = getUserId(globalState);
    const affLink = `https://www.street-hunters.com?aff=${userId}`;

    return (
        <ContentWrapper>
            <h1>מידע כללי</h1>
            {/* קוד השותף שלך הוא: 
            <AffCode>aff={userId}</AffCode>
            <br /><br />
            קישור לאתר עם קוד השותף שלך: <br />
            <a href={affLink} target='_blank'>{affLink}</a> */}

        </ContentWrapper>
    )
}

export default Reports;