import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import App from './App';
import './i18n';
import * as serviceWorker from './serviceWorker';

import axios from 'axios';

const href = document.location.href;
if (!href.toLocaleLowerCase().startsWith('http://localhost')) {
  if (href.toLocaleLowerCase().startsWith('https://street-hunters.com')) {
    document.location.href = href.replace('https://street-hunters.com', 'https://www.street-hunters.com');
  }
}

axios.interceptors.request.use(
    config => {
    //   if (config.baseURL === baseApiAddress && !config.headers['x-access-token']) {
        const token = localStorage.getItem('token') || ''; // getToken();
        if (token) {
          // config.headers.Authorization = `Bearer ${token}`;
          config.headers['x-access-token'] = token;
        }
    //   }
  
      return config;
    },
    error => Promise.reject(error)
  );


ReactDOM.render(
  <Suspense fallback="Loading..."><App /></Suspense>, 
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
