import React from 'react';
import styled from '@emotion/styled';
import FlexRow from './common/FlexRow';

const StyledFlexRow = styled(FlexRow)`
    /* padding: 0 10px; */
    background-color: #ddf1f0;
    /* border: 1px solid silver; */
    border-radius: 5px; 
    box-shadow: inset 1px 1.5px 10px 0px rgba(0,0,0,0.15);
    height: 10px;
    width: 100%;
    margin: 0 auto; 
    position: relative;
`;

const Step = styled.div`
    width: ${props => `${props.width}%`};
    background-color: #ddf1f0;
    border-radius: 5px; 
    box-shadow: inset 1px 1.5px 1px 0px rgba(0,0,0,0.45);
    height: 10px;
    position: absolute;
`;

const Progress = styled.div`
    width: ${props => props.progress ? `${props.progress}%` : '100%'};
    background-color: #5dbed1;
    height: 10px; 
    border-radius: 5px; 
    position: absolute;
`;


function FeedbackProgress({ feedbackIndex, totalFeedbacks }) {
const progress = (feedbackIndex + 1) / totalFeedbacks * 100;

    return (
        <React.Fragment>
            <StyledFlexRow>
                {
                     [...Array(totalFeedbacks)].map((e, i) => (
                        <Step key={i} width={(totalFeedbacks-i)/totalFeedbacks*100} />
                    ))
                }
                <Progress progress={progress}>&nbsp;</Progress>
            </StyledFlexRow>
        </React.Fragment>
    )
}

export default FeedbackProgress;