import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";

const MapAutoComplete = ({onSelect, style}) => {
  const [address, setAddress] = React.useState("");
  const [coordinates, setCoordinates] = React.useState({
    lat: null,
    lng: null
  });

  const handleSelect = async value => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    setCoordinates(latLng);
    onSelect(latLng);
  };

  const handleError = (status) => {
    console.log('PlacesAutocomplete Error: ', status);
  }

  return (
      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
        onError={handleError}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div style={ { position: 'absolute', zIndex: '10'} }>
            <input {...getInputProps({ placeholder: "Type address" })} />

            <div>
              {loading ? <div>...loading</div> : null}

              {suggestions.map(suggestion => {
                const style = {
                  backgroundColor: suggestion.active ? "#50a5b7" : "#fff"
                };

                return (
                  <div {...getSuggestionItemProps(suggestion, { style })} key={suggestion.id}>
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
  );
}

export default MapAutoComplete;