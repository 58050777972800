import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncDebounce } from 'react-table'
import { exportTableToCSV } from './../../../utils';
import downloadIcon from './../../../images/icons/download-icon.png';
import styled from '@emotion/styled';

const GlobalFiterWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const DownloadImage = styled.img`
    margin-right: 4px;
    width: 12px; 
`;

const SimpleTableGlobalFilter = ({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    tableName
}) => {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const { t } = useTranslation();
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <GlobalFiterWrapper>
            <div>
                {t('simple.table.search')}:
            <input
                    className="form-control"
                    value={value || ""}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={`${count} records...`}
                />
            </div>
            <div>
                <button onClick={() => exportTableToCSV(tableName || 'street-hunters.csv')}>
                    <span>Download</span>
                    <DownloadImage src={downloadIcon} alt='Download' />
                </button>
            </div>
        </GlobalFiterWrapper>
    )
}

export default SimpleTableGlobalFilter;