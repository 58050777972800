import React, { useEffect, useState, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';
import { get } from 'lodash/fp';
import Loader from './../components/common/Loader';
import { getMyGames, getIsSelfPayment } from '../store/selectors';
import useGlobal from '../store';
import styled from '@emotion/styled';
import SimpleTable from '../components/common/simple-table/SimpleTable';

const StyledLink = styled(Link)`
    text-decoration: 'none';

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;

const CenteredDiv = styled.div`
    text-align: center;
`;

function MyGames(props) {

    const gameStatuses = [
        { value: null, label: 'הכל' },
        { value: 'APPROVED', label: 'Approved' },
        { value: 'DRAFT', label: 'Drafts' }
    ];


    const [isLoading, setIsLoading] = useState(false);
    const [gamesFilter, setGamesFilter] = useState();
    const [globalState, globalActions] = useGlobal();
    const history = useHistory();
    const myGames = getMyGames(globalState);
    const isSelfPayment = getIsSelfPayment(globalState);

    const handleGenerateSecretCode = async (gameId) => {
        const postData = {
            gameId
        };
        const result = await axios.post('/api/game/secret-code', postData);
        window.prompt('קוד משחק', get('data.game_secret', result));
    }

    const columns = useMemo(() => ([
        {
            Header: '#',
            accessor: 'index',
            Cell: ({ row }) => (row.index + 1)
        },
        {
            Header: 'שם המשחק',
            accessor: 'metadata.name',
        },
        {
            Header: 'עיר',
            accessor: 'metadata.locationCity',
        },
        {
            Header: 'מחיר',
            accessor: 'metadata.price',
        },
        {
            Header: 'מטבע',
            accessor: 'metadata.currencyCode',
        },
        {
            Header: 'סטטוס',
            accessor: 'status',
        },
        {
            Header: 'ערוך משחק',
            accessor: 'edit',
            Cell: ({ row }) => (
                <StyledLink to={`/game-editor/${row.original._id}`} >
                    <button>
                        ערוך משחק
                    </button>
                </StyledLink>
            )
        },
        {
            Header: 'צור קוד',
            accessor: 'code',
            Cell: ({ row }) => (
                <button onClick={() => handleGenerateSecretCode(row.original._id)} >
                    צור קוד משחק
                </button>
            )
        },
        {
            Header: 'קישור למשחק',
            accessor: 'link',
            Cell: ({ row }) => (
                <a href={`/game/${row.original._id}`} target="_blank">קישור בדף חדש</a>
            )
        }
    ]), [isSelfPayment]);

    useEffect(() => {
        const loadMyGames = async () => {
            const token = localStorage.getItem('token') || '';
            setIsLoading(true);
            // const result = await axios('/api/my-games');
            const result = await axios('/api/games/my-games')
                .catch(error => {
                    console.log('error!: ', error);
                    if (error.response.status === 401) {
                        history.push(`/login?url=${window.location.pathname}${encodeURIComponent(window.location.search)}`);
                    }
                    return [];
                });
            setIsLoading(false);
            // console.log(result.data);
            globalActions.setMyGames(result.data || []);
        };

        loadMyGames();
    }, []);


    const { value: gamesFilterValue } = gamesFilter || {};
    const gamesToShow = myGames && gamesFilterValue ? myGames.filter((game) => game.status === gamesFilterValue) : myGames;
    // const gamesToShow = myGames;
    // console.log('gamesFilter:', gamesFilter);
    // console.log('gamesToShow:', gamesToShow);

    const initialState = { hiddenColumns: isSelfPayment ? [] : ['code'] };

    return (
        <div>
            <Loader active={isLoading} text='Loading Your Games...' >
                <Select
                    value={gamesFilter}
                    options={gameStatuses}
                    onChange={(selectedOption) => setGamesFilter(selectedOption)}
                    placeholder={'בחירה'}
                />
                <br /><br />
                {
                    gamesToShow && (<SimpleTable columns={columns} data={gamesToShow} initialState={initialState} />)
                }

                {
                    !isLoading && (
                        <CenteredDiv>
                            <StyledLink to={`/game-editor-new-game`}>
                                <button>צור משחק חדש</button>
                            </StyledLink>
                        </CenteredDiv>
                    )

                }
            </Loader>
        </div>
    )


}

export default MyGames;