import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import useGlobal from "../store";
import { msToTime } from './../utils';
import axios from 'axios';

import { getGameGameoverPage, getGameProgress } from '../store/selectors';
import Content from './Content';
import GameSummary from './GameSummary';
import ContentWrapper from './common/ContentWrapper';

import { useEffect } from 'react';
import GameFeedback from './GameFeedback';
import { useState } from 'react';

const PageTitle = styled.div`
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    padding: 10px 0;
`;

function GameOverPage() {

    const { t } = useTranslation();

    const [globalState, globalActions] = useGlobal();
    const [gameId, setGameId] = useState();
    const gameoverPage = getGameGameoverPage(globalState);
    const gameProgress = getGameProgress(globalState);

    const { content: gameoverPageContent } = gameoverPage || {};
    const { hideGameSummary } = gameoverPage || {};
    const { endTime } = gameProgress;

    useEffect(() => {

        const storageGameProgress = JSON.parse(localStorage.getItem('active-game') || '{}');
        const { game_id, game_secret } = storageGameProgress || {};
        setGameId(game_id);

        const setEndTime = async () => {
            await axios.post(`/api/game-progress`, {
                "action": "set-end-time",
                "gameId": game_id,
                "secretCode": game_secret
            });  // TODO catch error (Server 500)
        }

        if (!!!endTime) {
            setEndTime();
        }
    }, [])


    return (
        <React.Fragment>
            <ContentWrapper>
                <PageTitle>
                    { t('gameover.congrats') }
                    <br />
                    { t('gameover.finished.game') }
                </PageTitle>
                {
                    !!!hideGameSummary && (<GameSummary />)
                }
                <GameFeedback gameId={ gameId } />
                {
                    <Content content={gameoverPageContent} />
                }

            </ContentWrapper>
        </React.Fragment>
    );
}

export default GameOverPage;