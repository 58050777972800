import React, { useState, useRef, useCallback } from "react";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { GoogleMap, Polygon, Marker } from "@react-google-maps/api";

import { useEffect } from "react";

import useGlobal from "../store";
import { getLocation } from '../store/selectors';
import EditorButton from "./common/EditorButton";
import MapAutoComplete from "./MapAutoComplete";

const TEL_AVIV_LOCATION = { lat: 32.085744, lng: 34.795196 };

const GoogleMapWrapper = styled.div`

    .App-map {
        width: 400px; 
    height: 400px;

    }
`;

const ButtonsContainer = styled.div`
    padding-top: 4px; 
    text-align: end;
`;

const GameMapEditor = ({locationPath, enablePolygon}) => {
// console.log('GameMapEditor! ', locationPath);

    const { t } = useTranslation();
    const [globalState, globalActions] = useGlobal();
    
    // Store Polygon path in state
    const [path, setPath] = useState([
        // { lat: 52.52549080781086, lng: 13.398118538856465 },
        // { lat: 52.48578559055679, lng: 13.36653284549709 },
        // { lat: 52.48871246221608, lng: 13.44618372440334 }
    ]);
    const [ mapCenter, setMapCenter ] = useState()

    useEffect(() => {
        let location = getLocation(globalState, locationPath);
        if (!location || Array.isArray(location) && location.length === 0 ){
            location = TEL_AVIV_LOCATION;
        }
        const locationArray = Array.isArray(location) ? location : [location];
        setPath(locationArray);
        setMapCenter(locationArray[0]);
        
    },[locationPath])

    // Define refs for Polygon instance and listeners
    const polygonRef = useRef(null);
    const listenersRef = useRef([]);

    // Call setPath with new edited path
    const onEdit = useCallback(() => {
        if (polygonRef.current) {
            const nextPath = polygonRef.current
                .getPath()
                .getArray()
                .map(latLng => {
                    return { lat: latLng.lat(), lng: latLng.lng() };
                });
            setPath(nextPath);
        }
    }, [setPath]);

    // Bind refs to current Polygon and listeners
    const onLoad = useCallback(
        polygon => {
            polygonRef.current = polygon;
            const path = polygon.getPath();
            listenersRef.current.push(
                path.addListener("set_at", onEdit),
                path.addListener("insert_at", onEdit),
                path.addListener("remove_at", onEdit)
            );
        },
        [onEdit]
    );

    // Clean up refs
    const onUnmount = useCallback(() => {
        listenersRef.current.forEach(lis => lis.remove());
        polygonRef.current = null;
    }, []);

////////////////////////


    const handleMapClick = (e) => {
        if (enablePolygon) {
            setPath([...path, { lat: e.latLng.lat(), lng: e.latLng.lng() }])
        } else {
            setPath([{ lat: e.latLng.lat(), lng: e.latLng.lng() }])
        }
        
        
    }

    const onDragEnd = (e) => {
        if (polygonRef && polygonRef.current && polygonRef.current.map && polygonRef.current.map.center) {
            setMapCenter({ lat: polygonRef.current.map.center.lat(), lng: polygonRef.current.map.center.lng()});
        }else{
            setMapCenter(TEL_AVIV_LOCATION);
        }
    }
    
    const handleClear = () => {
        setPath([]);
    }

    const handleSave = () => {
        let locationToSave = []
        if (path.length && path.length < 3) {
            locationToSave = path[0];
        }else{
            locationToSave = path;
        }
        globalActions.gameEditorSetLocation(locationPath, locationToSave);
        
    }

    const onAutoCompleteSelected = (latLng) => {
        setMapCenter(latLng);
        if (path.length === 0) {
            setPath([latLng]);
        }
    }

    if (!locationPath) {
        return null; 
    }

    return (
        <React.Fragment>
            <GoogleMapWrapper>
                <MapAutoComplete onSelect={onAutoCompleteSelected} 
                />
                
                <GoogleMap
                    mapContainerClassName="App-map"
                    // center={{ lat: 52.52047739093263, lng: 13.36653284549709 }}
                    center={ mapCenter }
                    zoom={18}
                    version="weekly"
                    onClick={ handleMapClick }
                    onDragEnd={onDragEnd}
                    on
                >
                    <Polygon
                        // Make the Polygon editable / draggable
                        editable
                        draggable
                        path={path}
                        // Event used when manipulating and adding points
                        onMouseUp={onEdit}
                        // Event used when dragging the whole Polygon
                        onDragEnd={onEdit}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                    />


                    {
                        path && path.length === 1 && (<Marker position={path[0]}/>)
                    }
                    
                </GoogleMap>
            {/* </LoadScript> */}
            </GoogleMapWrapper>
            <ButtonsContainer>
            <EditorButton size="small" onClick={handleClear}>{t('clear')}</EditorButton>
            <EditorButton size="small" onClick={handleSave}>{t('save')}</EditorButton>
            </ButtonsContainer>

        </React.Fragment>
    );
}

export default GameMapEditor;