import React, { Children } from 'react';
import { useTranslation } from 'react-i18next';

import Content from './Content';
import useGlobal from "../store";
import { getGameQuestionHints } from '../store/selectors';
import axios from 'axios';

import styled from '@emotion/styled';

function Hints({ onClick, isOpen, hintContent, hintTitle, hintIndex }) {

    const { t } = useTranslation();

    const StyledHint = styled.div`
        border-bottom: 2px solid #3babab;

        &:first-of-type {
            border-top: 2px solid #3babab;
        }
    `;

    const HintButton = styled.div`
        min-height: 24px; 
        padding: 5px; 
        
        text-align: center;
        font-size: 14px;
        background-color: #ddf1f0;
        color: #3babab;
    `;

    const HintContent = styled.div`
        background-color: white;
    `;

    const HintContentSpacer = styled.div`
        height: 10px; 
    `;


    return (
        <StyledHint>
                            <HintButton onClick={onClick}>
                                {
                                    hintTitle || `${t('hint')} ${hintIndex + 1}`
                                }
                            </HintButton>

                            <HintContent>
                                {
                                    isOpen && (
                                    <React.Fragment>
                                    <Content content={hintContent} isInnerContent={true} />
                                    <HintContentSpacer />
                                    </React.Fragment>
                                    )
                                }
                            </HintContent>
        </StyledHint>
    )
}

export default Hints;