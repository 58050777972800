import React from 'react';
import styled from '@emotion/styled';

const StyledButton = styled.button`
    background-color: #2090a4;
    margin: 0 2px;
    padding: 0 15px;
    border: none;
    box-shadow: 2px 2px 5px 0px #17798b inset;
    border-radius: 20px;
    height: 34px; 
    // min-width: 123px; 
    ${props => props.width && `min-width: ${props.width};`}
    font-size: 20px;
    font-weight: bold;
    color: white; 
    cursor: pointer;
    outline: none;

    ${props => props.size==='small' && `height: 20px;`}
    ${props => props.size==='small' && `font-size: 12px;`}
    ${props => props.size==='small' && `border-radius: 12px;`}
    

    &:hover {
        background-color: #17798b;
    }

`;

function EditorButton({text, onClick, size = 'large', width, children}) {

    return (
        <StyledButton onClick={ onClick } width={width} size={size}>{text || children}</StyledButton>
    );
  }

  export default EditorButton;