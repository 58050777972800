import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom'
import useGlobal from "../store";
import axios from 'axios';
import { get } from 'lodash/fp';
import FlexRow from '../components/common/FlexRow';
import ContentWrapper from '../components/common/ContentWrapper';
import RoundButton from '../components/common/RoundButton';
import InputField from '../components/common/InputField';
import StyledLink from '../components/common/StyledLink';

const ErrorMessage = styled.div`
    font-weight: bold;
    color: red; 
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    top: 25px;
    position: relative;
`;

const Links = styled.div`
    text-align: center;
    margin-top: 50px;
`;

function ResetPassword(props) {

    const { t } = useTranslation();

    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const history = useHistory();
    const [globalState, globalActions] = useGlobal();


    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setErrorMessage('');
    };

    const handlePassword2Change = (e) => {
        setPassword2(e.target.value);
        setErrorMessage('');
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password !== password2) {
            setErrorMessage( t('reset.password.password.not.same'));
            return;
        }
        const urlParts = document.location.pathname.split('/');
        if (urlParts.length < 2) {
            console.log('Error parsing url with token...');
        }
        const id = urlParts[urlParts.length - 2];
        const token = urlParts[urlParts.length - 1];

        // 

        axios.post('/api/update-password', { password, id, token })
            .then(response => {
                console.log('/api/update-password: ', response);
                if (get('data.status', response) === 'OK') {
                    setErrorMessage(response.data.message);
                    setTimeout(() => {
                        history.push('/login');
                    }, 3000);
                }

                if (get('data.status', response) === 'ERROR') {
                    setErrorMessage(response.data.message);
                    setTimeout(() => {
                        history.push('/forgot-password');
                    }, 3000);
                }
            })
            .catch(err => {
                const errorMessage = get('data.message', err);
                setErrorMessage(errorMessage);
            })
    };

    return (
        <React.Fragment>
            <ContentWrapper withButton >
                <h1>{ t('reset.password.new.password')}</h1>
                <form onSubmit={handleSubmit} style={{ width: '300px', margin: '0 auto' }} >
                    <FlexRow>
                     { t('reset.password.please.enter.twice') }

                    </FlexRow>
                    <br /><br />
                    
                    <FlexRow>
                        <InputField label={ t('reset.password.new.password')} value={password} onChange={handlePasswordChange} placeholder={ t('reset.password.new.password')} required />
                    </FlexRow>
                    
                    <FlexRow>
                        <InputField label={ t('reset.password.new.password.again') } value={password2} onChange={handlePassword2Change} placeholder={ t('reset.password.new.password.again')} required />
                    </FlexRow>
                    
                    <ErrorMessage>
                        {errorMessage} &nbsp;
                    </ErrorMessage>
                    
                    <ButtonWrapper>
                        {
                            <RoundButton type="submit" value="submit" width={190} text={ t('submit') } />
                        }
                    </ButtonWrapper>
                </form>
            </ContentWrapper>

            <Links>
                <StyledLink to="/" >{ t('page.title.home') }</StyledLink>
                <StyledLink to="/login" >{ t('page.title.login') }</StyledLink>
                <StyledLink to="/signup" >{ t('page.title.registration')}</StyledLink>

            </Links>
        </React.Fragment>
    )
}

export default ResetPassword;