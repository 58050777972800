import React from 'react';
import IntroPage from '../components/IntroPage';
import Question from './Question';
import PreviewMetadata from './PreviewMetadata';
import GameOverPage from './GameOverPage';
import Answer from './Answer';
import ContentWrapper from './common/ContentWrapper';
import GameMapEditorPreview from './GameMapEditorPreview';


function EditGamePreview({gameId, previewPage, questionIndex, gameProgress}) {
    // previewPage: 'details', 'intro', 'questions', 'answer', 'gameover', 'map'
    return (
        <React.Fragment>
            { previewPage === 'metadata' && (<PreviewMetadata />)}
            { previewPage === 'intro' && (<IntroPage />)}
            { previewPage === 'questions' && (<Question gameId={ gameId } questionIndex={questionIndex} />)}
            { previewPage === 'answer' && (<ContentWrapper withButton><Answer gameId={ gameId } questionIndex={questionIndex} /></ContentWrapper>)}
            { previewPage === 'gameover' && (<GameOverPage />)}
            { previewPage === 'map' && (<GameMapEditorPreview />)}
        </React.Fragment>
    );
}

export default EditGamePreview;