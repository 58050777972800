import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import axios from 'axios';

import Feedback from './Feedback';
import FeedbackProgress from './FeedbackProgress';
import FeedbackOpen from './FeedbackOpen';

const Wrapper = styled.div`
    max-width: 400px; 
    display: block;
    margin: 0 auto; 
`;

function GameFeedback(props) {

    // const storageGameProgress = JSON.parse(localStorage.getItem('active-game') || '{}');
    // const { game_id, game_secret } = storageGameProgress || {};

    const { t } = useTranslation();
    const [ feedbackIndex, setFeedbackIndex ] = useState(0);
    const [ showOpenQuestion, setShowOpenQuestion ] = useState(false);
    const [ feedbackResults, setFeedbackResults ] = useState({});
    const [ hideFeedback, setHideFeedback ] = useState(false);
    
    const { gameId } = props; 
    if (!gameId) return null; 

    const feedbackQuestions = [
        {
            id: 'general',
            header: t('feedback.questions.game.header'),
            text: t('feedback.questions.game.text'),
            optionsText: [t('feedback.questions.options.didnt.like'), t('feedback.questions.options.great')]
        },
        {
            id: 'questions',
            header: t('feedback.questions.questions.header'),
            text: t('feedback.questions.questions.text'),
            optionsText: [t('feedback.questions.options.very.easy'), t('feedback.questions.options.very.hard')]
        },
        {
            id: 'path',
            header: t('feedback.questions.path.header'),
            text: t('feedback.questions.path.text'),
            optionsText: [t('feedback.questions.options.very.easy'), t('feedback.questions.options.very.hard')]
        }        
    ]

    const addAnswer = (key, val) => {
        const newFeedbackResults = {...feedbackResults, [key]: val  };
        setFeedbackResults(newFeedbackResults);
        return newFeedbackResults;
    }

    const handleFeedback = (val) => {
        addAnswer(feedbackQuestions[feedbackIndex].id, val);

        if (feedbackIndex < feedbackQuestions.length - 1){
            setFeedbackIndex(feedbackIndex+1);
        }else{
            setShowOpenQuestion(true);
        }  
    };
    const handleFeedbackOpen = async (openAnswer) => {
        const feedbackData = addAnswer('openAnswer', openAnswer);

        await axios.post(`/api/feedback/game/${gameId}`, feedbackData)
        .then((result) => {
            console.log('game feedback result: ', result);
            setHideFeedback(true);
            
        })
        .catch(error => console.log('game feedback error: ', error));
    }

    const { header, text, optionsText } = feedbackQuestions[feedbackIndex];

    //console.log('feedbackResults:', feedbackResults);
    if (hideFeedback) {
    return (<h1>{t('thanks')}</h1>); 
    }
    
    return (
        <Wrapper>
            <FeedbackProgress totalFeedbacks={ feedbackQuestions.length } feedbackIndex={feedbackIndex} />
            {
                showOpenQuestion
                ? (<FeedbackOpen onClick={ handleFeedbackOpen} />)
                : (
                    <Feedback 
                    header={ header }
                    text={ text }
                    optionsText={ optionsText }
                    onClick={ handleFeedback }
                />    
                )
            }
        </Wrapper>
    )
  }

  export default GameFeedback;