import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import ContentWrapper from '../components/common/ContentWrapper';

const StyledWrapper = styled.div`
    width: 600px;
    max-width: 100vw;
    margin: 0 auto;
    text-align: center;
`;


function Page404(props) {

    const { t } = useTranslation();

    return (
        <StyledWrapper>
            <ContentWrapper>
                <h1>{t('page404.title')}</h1>

                <br />
                {t('page404.text')}
            <br /><br />
                <Link to="/">{t('page404.back.to.main.page')}</Link>
            </ContentWrapper>
        </StyledWrapper>
    )
}

export default Page404;