import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { getGameGameoverPage } from './../store/selectors';
import useGlobal from '../store';
import EditContent from './EditContent';

const CheckboxLabel = styled.label`
    cursor: pointer;
`;

function EditGameOverPage() {

    const { t } = useTranslation();

    const [globalState, globalActions] = useGlobal();
    const gameOverPage = getGameGameoverPage(globalState);
    const hideGameSummary = gameOverPage && gameOverPage.hideGameSummary || false;

    const handleHideGameSummaryChange = (e) => {
        globalActions.gameEditorSetHideSummary(e.target.checked);
    }

    return (
        <React.Fragment>
            <EditContent path={'gameoverPage.content'} />
            
            <CheckboxLabel>
            <input type="checkbox" checked={hideGameSummary} onChange={ handleHideGameSummaryChange } />
            {t('ge.gaomeover.hide.summary')}
            </CheckboxLabel>
        </React.Fragment>
    );
}

export default EditGameOverPage;