import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import FlexRow from './common/FlexRow';
import useGlobal from '../store';
import Modal from 'react-modal';
import FileUploader from '../components/common/FileUploader';
import InputField from './common/InputField';
import { useEffect } from 'react';
import EditorButton from './common/EditorButton';
import SelectField from './common/SelectField';

const InputLabel = styled.div`
    width: 100px;
`;

const InputData = styled.div`
    
`;

const CloseButton = styled.span`
    padding: 0 10px;
    float: left;
    border-radius: 5px;
    cursor: pointer;

    &:hover{
        background-color: #dddddd;
    }
`;



function EditMetadata({ metadata = {} }) {

    const { t } = useTranslation();
    const [globalState, globalActions] = useGlobal();
    const [isFileUploaderModalOpen, setIsFileUploaderModalOpen] = useState(false);

    const metadataName = metadata.name || '';
    const metadataLocationCity = metadata.locationCity || '';
    const metadataLocationText = metadata.locationText || '';
    const metadataPrice = metadata.price || '';
    const metadataCurrencyCode = metadata.currencyCode || 'ILS';
    const metadataLanguage = metadata.language || '';
    const metadataMap = metadata.map;

    

    const openFileUploaderModal = () => {
        setIsFileUploaderModalOpen(true);
    }
    const closeFileUploaderModal = () => {
        setIsFileUploaderModalOpen(false);
    }
    const handleOnSelectCoverImage = (url) => {
        console.log(url);
        globalActions.setMetadataCoverImage(url);
        closeFileUploaderModal();
    }

    const languagesOptions = [
        {value: 'he', label: t('language.he')},
        {value: 'en', label: t('language.en')}
    ];

    const mapOptions = [
        {value: true, label: t('map.true')},
        {value: false, label: t('map.false')}
    ];

    useEffect(() => {
        globalActions.setMetadataCurrencyCode('ILS'); //TEMP
    },[])

    return (
        <React.Fragment>
             <div style={ {width: '300px', margin: '0 auto'}} >
                 <br />

                <FlexRow>
                    <InputField label={t('metadata.game.name')} value={metadataName} onChange={(e) => globalActions.setMetadataName(e.target.value)} placeholder={t('metadata.game.name')}  />
                </FlexRow>
                <FlexRow>
                    <InputField label={t('metadata.location.city')} value={metadataLocationCity} onChange={(e) => globalActions.setMetadataLocationCity(e.target.value)} placeholder={t('metadata.location.city')}  />
                </FlexRow>
                <FlexRow>
                    <InputField label={t('metadata.location.text')} value={metadataLocationText} onChange={(e) => globalActions.setMetadataLocationText(e.target.value)} placeholder={t('metadata.location.text')}  />
                </FlexRow>
                <FlexRow>
                    <InputField label={t('metadata.price')} value={metadataPrice} onChange={(e) => globalActions.setMetadataPrice(e.target.value)} placeholder={t('metadata.price')}  />
                </FlexRow>
                <FlexRow>
                    <InputField label={t('metadata.currency')} value={metadataCurrencyCode} onChange={(e) => globalActions.setMetadataCurrencyCode(e.target.value)} placeholder={t('metadata.currency')} readOnly />
                </FlexRow>
                <FlexRow>
                    <SelectField label={t('metadata.language')} value={metadataLanguage} onChange={(selectedval) => globalActions.setMetadataLanguage(selectedval && selectedval.value)} options={languagesOptions} placeholder={t('metadata.language')} />
                </FlexRow>
                <FlexRow>
                    <SelectField label={t('metadata.map')} value={metadataMap} onChange={(selectedval) => {globalActions.setMetadataMap(selectedval && selectedval.value)} } options={mapOptions} placeholder={t('metadata.map')} />
                </FlexRow>

                <FlexRow>
                <InputLabel>{t('image')}</InputLabel>
                <InputData>
                    <EditorButton size="small" onClick={() => openFileUploaderModal()}>{t('select')}</EditorButton>

                </InputData>
            </FlexRow>


                {/* <ButtonWrapper>
                    <RoundButton type="submit" value="submit" width={190} text="שלח" />
                </ButtonWrapper> */}
            </div>

            <Modal
                        isOpen={isFileUploaderModalOpen}
                        ariaHideApp={false}
                        // onAfterOpen={afterOpenModal}
                        onRequestClose={closeFileUploaderModal}
                    >
                        <h1>{t('media.management')}
                        <CloseButton onClick={closeFileUploaderModal} style={{ float: 'left' }}>X</CloseButton>
                        </h1>
                        <FileUploader onSelect={handleOnSelectCoverImage} />
                    </Modal>


        </React.Fragment>
    );
}

export default EditMetadata;