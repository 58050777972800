import React from 'react';
import styled from '@emotion/styled';

const StyledButton = styled.button`
    border: 2px solid black;
    font-size: 1.5em;
    font-weight: bold;
    display: block;
    background-color: cyan;
    margin: 0 auto; 
    min-width: 50%;
`;

function Button({text, onClick, size, children}) {

    return (
        <React.Fragment>
            <StyledButton onClick={ onClick }>{text || children}</StyledButton>
        </React.Fragment>
    );
  }

  export default Button;