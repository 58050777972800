import React from 'react';
import { useTranslation } from 'react-i18next';

import useGlobal from '../store';
import { getGameQuestionHintTitle } from './../store/selectors';


import EditContent from './EditContent';
import InputField from './common/InputField';

function EditQuestionHint({questionIndex, hintIndex}) {

    const { t } = useTranslation();
    const [globalState, globalActions] = useGlobal();


    const path = `questions[${questionIndex}].hints[${hintIndex}].content`;
    const title = getGameQuestionHintTitle(globalState, questionIndex, hintIndex) || '';
    
    const handleTitleChange = (e) => {
        // e.target.value
        const hintTitle = e.target.value;
        globalActions.gameEditorUpdateHintTitle(questionIndex, hintIndex, hintTitle);
    }
    
    return (
        <React.Fragment>
            <EditContent path={path} />
            <br />
            <InputField value={ title } onChange={handleTitleChange} label={t('ge.add.hint.title')}/>
        </React.Fragment>
    );
}

export default EditQuestionHint;