import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import Loader from './../components/common/Loader';

// import defaultGameCover from './../images/placeholder-150x150.png';
import { getGames, getUser } from '../store/selectors';
import useGlobal from '../store';
import styled from '@emotion/styled';
import GameRow from '../components/GameRow';

const GamesContainer = styled.div`
    width: 600px;
    margin: 0 auto;
    max-width: 100vw;
`;

const StyledLink = styled(Link)`
    text-decoration: 'none';

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;


function Games(props) {

    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingUser, setIsLoadingUser] = useState(false);
    const [globalState, globalActions] = useGlobal();
    const games = getGames(globalState);
    const user = getUser(globalState);

    const userId = new URLSearchParams(props.location.search).get("user")

    useEffect(() => {
        const loadGames = async () => {
            setIsLoading(true);
            const result = await axios('/api/games');
            setIsLoading(false);
            // console.log(result.data);
            globalActions.setGames(result.data);
        };
        if (!games) {
            loadGames();
        }
    }, []);

    useEffect(() => {
        const loadUser = async () => {
            setIsLoadingUser(true);
            const result = await axios(`/api/user/${userId}`);
            setIsLoadingUser(false);
            globalActions.setUser(result?.data?.data);
        };
        
        globalActions.setUser(null);
        if (userId) {
            loadUser();
        }
        
    }, [userId]);

    const gamesToDisplay = userId
        ? games && games.filter(game => game.userId === userId)
        : games;

        return (
        <div>
            <Loader active={isLoading || isLoadingUser} text='Loading Game...' >
                {
                    // isLoading && (<div>Loading...</div>)
                }
                <GamesContainer>
                {
                    user && user.firstName && (
                        <div>
                            <h1>{t('games.of')} {user.firstName}</h1>
                        </div>
                    )
                }
                    {
                        gamesToDisplay && gamesToDisplay.map((game, index) => {
                            // const { metadata: {coverImage}} = game;
                            const { metadata, _id } = game;
                            const { coverImage, name, locationText, locationCity, price, currencyCode } = metadata || {};

                            return (
                                <StyledLink key={index} to={`/game/${_id}`}>
                                    <GameRow
                                        coverImage={coverImage}
                                        name={name}
                                        locationCity={locationCity}
                                        locationText={locationText}
                                        price={price}
                                        currencyCode={currencyCode}
                                    />
                                </StyledLink>
                            );
                        })
                    }
                </GamesContainer>
            </Loader>
        </div>
    )


}

export default Games;