import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import { get } from 'lodash/fp';

import ContentWrapper from './common/ContentWrapper';
import FlexRow from './common/FlexRow';
import InputField from './common/InputField';
import RoundButton from './common/RoundButton';

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    top: 25px;
    position: relative;
`;


function GamePayment(props) {

  const { gameId } = props;

  const { t } = useTranslation();

  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [paymentUrl, setPaymentUrl] = useState('');
  

  if (!gameId) {
    console.log('Oops, Missing gameId for GamePayment!');
    return null; 
  }

  const handleFullNameChange = (e) => {
    setFullName(e.target.value);
  }
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }
  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log('handleSubmit() !!!!');
    axios.post(`/api/payment/get-url/${gameId}`, { fullName, email, phone })
    .then(response => {
        console.log('/api/reset-password: ', response);
        const url = get('data.URL', response);
        if (url){
          setPaymentUrl(url)
            // setErrorMessage( t('forgot.password.email.sent') );
        }
    })
    .catch(err => {
        console.log('err: ', err);
        // console.log('err.message: ', get('message', err));
        
        // let errorMessage = get('response.data.message', err);
        // if (!errorMessage) {
        //     errorMessage = get('message', err);
        // }
        // setErrorMessage(errorMessage);
    })    
    // alert('submit');

  }
  if (!!paymentUrl) {
    window.document.location.href = paymentUrl;
    return null; 
  }

  return (
    <ContentWrapper withButton={!paymentUrl}>
      <h1>{t('game.payment.purchase.game.code')}</h1>
      {
        !!paymentUrl && (
          <iframe src={paymentUrl} width='100%' />
        )
      }

      {
        !!!paymentUrl && (
          <form onSubmit={handleSubmit} style={{ width: '300px', margin: '0 auto' }} >

          <FlexRow>
            <InputField label={t('full.name')} value={fullName} onChange={handleFullNameChange} placeholder={t('full.name')} type="text" required />
          </FlexRow>
  
          <FlexRow>
            <InputField label={t('email')} value={email} onChange={handleEmailChange} placeholder={t('email')} type="email" dir="ltr" required />
          </FlexRow>
  
          <FlexRow>
            <InputField label={t('phone')} value={phone} onChange={handlePhoneChange} placeholder={t('phone')} type="text" dir="ltr" required />
          </FlexRow>

          <FlexRow>
            <br />
            {t('game.payment.check.details')}
          </FlexRow>

          <ButtonWrapper>
            {/* <RoundButton width={200} type="submit" value="submit" text={t('continue')} ></RoundButton> */}
            <RoundButton width={300} type="submit" value="submit" text={t('game.payment.to.secured.payment')} ></RoundButton>
          </ButtonWrapper>
  
        </form>
  
        )
      }  


    </ContentWrapper>
  );
}

export default GamePayment;