import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Content from './Content';
import useGlobal from "../store";
import { getGameQuestionHints } from '../store/selectors';
import Hint from './Hint';

import axios from 'axios';

import styled from '@emotion/styled';
import ModalConfirmation from './common/ModalConfirmation';

function Hints({ questionIndex }) {

    const { t } = useTranslation();

    const [isHintModalOpen, setIsHintModalOpen] = useState(false);
    const [hintIndexToOpen, setHintIndexToOpen] = useState();
    const [globalState, globalActions] = useGlobal();
    const hints = getGameQuestionHints(globalState, questionIndex);

    // const showHint = async (index) => {
        const showHint = async () => {
        const storageGameProgress = JSON.parse(localStorage.getItem('active-game'));
        const { game_id, game_secret } = storageGameProgress || {};

        await axios.post(`/api/game-progress`, {
            "action": "show-question-hint",
            "gameId": game_id,
            "secretCode": game_secret,  // "qweqwe"
            "questionIndex": questionIndex,
            "hintIndex": hintIndexToOpen

        });  // TODO catch error (Server 500)
        setIsHintModalOpen(false);
    };

    const openHintModal = (hintIndex) => {
        setHintIndexToOpen(hintIndex);
        setIsHintModalOpen(true);
    };

    const closeHintModal = () => {
        setIsHintModalOpen(false);
    }

    if (!!!hints) {
        return null;
    };

    return (
        <React.Fragment>
            <ModalConfirmation
                isOpen={isHintModalOpen}
                text={ t('open.hint?') }
                onApprove={showHint}
                onCancel={closeHintModal} 
            />            
            <div>
                {
                    hints.map((hint, index) => (
                        <Hint key={index}
                            onClick={() => !hint.isOpen && openHintModal(index)}
                            hintIndex={index}
                            hintTitle={hint.title}
                            hintContent={hint.content}
                            isOpen={hint.isOpen} />
                    ))
                }
            </div>
        </React.Fragment>
    )
}

export default Hints;