import React from 'react';
import GameMapEditor from './GameMapEditor';
import { isNumber } from './../utils';


function EditGameMap({questionId}) {

    let locationPath = 'metadata.location';
    let enablePolygon = false; 
    if (isNumber(questionId)) {
        locationPath = `questions[${questionId}].location`;
        enablePolygon = true;
    }

    return (
        <React.Fragment>
            <GameMapEditor locationPath={locationPath} enablePolygon={enablePolygon} style={ { 'width': '400px'} } />
        </React.Fragment>
    );
}

export default EditGameMap;