import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import GoogleMap, { Polygon } from 'google-map-react';
import { usePosition } from 'use-position';

import useGlobal from "../store";
import { getGame, getGameLocation, getGameQuestionsLocation, getSelectedQuestion } from '../store/selectors';
import MapPin from './common/MapPin';
import MyLocationPointer from './MyLocationPointer';
import { useEffect } from 'react';
import MyLocationIcon from './../images/my-location-icon.png';
import ContentWrapper from './common/ContentWrapper';
import { get } from 'lodash/fp';
import GameMapOnline from './GameMapOnline';


const MapWrapper = styled.div`
    width: calc(100vw-20px);
    height: 400px;
`;

const CenterMapButton = styled.div`
    background-color: white;
    border: 1px solid silver;
    border-radius: 2px;
    width: 40px;
    height: 40px;
    z-index: 1;
    cursor: pointer;

    position: relative;
    right: 9px;
    top: -155px;
`;

const MyLocationWrapper = styled.span`
    position: relative;
    img {
        max-width: 100%;
    }
    /* top: 8px;
    right: 8px; */
`;

function GameMap({ onStartQuestion = ()=>{} }) {

    const { t } = useTranslation();
    const [globalState, globalActions] = useGlobal();
    const game = getGame(globalState);
    const gameLocation = getGameLocation(globalState);
    const gameQuestionsLocation = getGameQuestionsLocation(globalState);
    const selectedQuestion = getSelectedQuestion(globalState);

    // console.log('gameLocation: ', gameLocation);
    // console.log('selectedQuestion: ', selectedQuestion);
    
    const { latitude, longitude, error: myLocationError } = usePosition(true);
    const [mapCenter, setMapCenter] = useState([gameLocation.lat, gameLocation.lng]);

    function centerMap(latitude, longitude) {
        if (latitude && longitude) {
            // Force "chnage" in order to recenter map even if location is the same.
            setMapCenter([latitude, longitude + 0.00001]);
            setTimeout(() => setMapCenter([latitude, longitude]), 200);
        }
    }

    useEffect(() => {
        const loc = (selectedQuestion && selectedQuestion !== -1)
                    ? getQuestionCenterLocation(gameQuestionsLocation[selectedQuestion]) 
                    : { lat: latitude, lng: longitude };
        centerMap(loc.lat, loc.lng);
    },[]);

    const onGoogleApiLoaded = (map, maps) => {
        gameQuestionsLocation.forEach((locations, index) => {
            if (Array.isArray(locations)) {
                const polygonColor = index === selectedQuestion ? '#af9ef5' : 'silver';
                const questionPolygon = new maps.Polygon({
                    paths: locations,
                    strokeColor: polygonColor,
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: polygonColor,
                    fillOpacity: 0.35
                })
                questionPolygon.setMap(map);
                // questionPolygon.addListener('click', (e) => { alert(e) });
            }
        });
    };

    const getQuestionCenterLocation = (questionLoc) => {
        if (!questionLoc) return;
        const qLoc = Array.isArray(questionLoc) ? questionLoc : [ questionLoc ];
        const latArray = qLoc.map(loc => loc.lat); 
        const lngArray = qLoc.map(loc => loc.lng); 
        const qLocLat = latArray.reduce((p,c) => p + c, 0) / latArray.length;
        const qLocLng = lngArray.reduce((p,c) => p + c, 0) / lngArray.length;
        return { lat: qLocLat, lng: qLocLng };
    }

    if (!get('metadata.map', game)) {
        return (<GameMapOnline />)
    }

    return (
        <React.Fragment>
            {
                myLocationError && (
                    <ContentWrapper withButton>
                        <div>
                            <h1>
                                {t('game.map.oops')}
                                <br />
                                {t('game.map.location.disabled')}
                            </h1>
                            {t('game.map.location.please.enable')}
                        </div>
                        <br />
                        <div style={ { textAlign: 'center'} }>[ {myLocationError} ]</div>
                        <br />
                    </ContentWrapper>
                )
            }
            
            <MapWrapper>
                <GoogleMap
                    // apiKey={'AIzaSyDJlxQsaG5L0jd9V6_90gPmcNunotVSO6Y'} // set if you need stats etc ...
                    bootstrapURLKeys={{ key: 'AIzaSyDJlxQsaG5L0jd9V6_90gPmcNunotVSO6Y' }}
                    center={mapCenter}
                    zoom={15}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => onGoogleApiLoaded(map, maps)}
                // hoverDistance={K_SIZE / 2}
                // onBoundsChange={this._onBoundsChange}
                // onChildClick={this._onChildClick}
                // onChildMouseEnter={this._onChildMouseEnter}
                // onChildMouseLeave={this._onChildMouseLeave}
                >
                    {latitude && longitude && <MyLocationPointer lat={latitude} lng={longitude} />}
                    <MapPin lat={gameLocation.lat} lng={gameLocation.lng} text="S" color="silver" />
                    {
                        gameQuestionsLocation.map((questionLoc, index) => {
                            const qPinLocation = getQuestionCenterLocation(questionLoc);
                            if (!qPinLocation) return;  
                            if (!qPinLocation.lat || !qPinLocation.lng) return;

                            return (
                                <MapPin
                                    key={index}
                                    lat={qPinLocation.lat}
                                    lng={qPinLocation.lng}
                                    text={index + 1}
                                    onClick={ () => { selectedQuestion === index && onStartQuestion();}} 
                                    color={selectedQuestion === index ? '#af9ef5' : 'silver'}
                                />
                            )
                        }
                        )
                    }

                </GoogleMap>
                <CenterMapButton onClick={() => centerMap(latitude, longitude)} alt="Center Map">
                    <MyLocationWrapper>
                        <img src={ MyLocationIcon } alt="My Location" />
                    </MyLocationWrapper>
                </CenterMapButton>
            
            </MapWrapper>
        </React.Fragment>
    );
}

export default GameMap;