import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
// import useFetch from 'use-http'
import axios from 'axios';
import { get, orderBy } from 'lodash/fp';
import utils from './../utils';
import Loader from './../components/common/Loader';

// import defaultGameCover from './../images/placeholder-150x150.png';
import { getMyGames, getMyGamesFeedbacks } from '../store/selectors';
import useGlobal from '../store';
import styled from '@emotion/styled';
import SimpleTable from '../components/common/simple-table/SimpleTable';

function MyGamesFeedbacks(props) {


    const [isLoading, setIsLoading] = useState(false);
    const [globalState, globalActions] = useGlobal();
    const [selectedGameOption, setSelectedGameOption] = useState();
    const myGames = getMyGames(globalState);
    const myGamesFeedbacks = getMyGamesFeedbacks(globalState);

    const handleGameChanged = (gameOption) => {
        setSelectedGameOption(gameOption);
    }

    const columns = [
        {
            Header: '#',
            accessor: 'index',
            Cell: ({ row }) => (row.index + 1)
        },
        {
            Header: 'תאריך',
            accessor: 'update',
            style: {whiteSpace: 'nowrap'},
            Cell: ({row}) => (
                utils.getHumanDateTime(row.original.update)
              )
        },
        {
            Header: 'כללי',
            accessor: 'general'
        },
        {
            Header: 'שאלות',
            accessor: 'questions'
        },
        {
            Header: 'מסלול',
            accessor: 'path'
        },
        {
            Header: 'טקסט',
            accessor: 'openAnswer'
        },
    ];

    useEffect(() => {
        const loadMyGames = async () => {
            setIsLoading(true);
            const result = await axios('/api/games/my-games')
                .catch(error => { console.log('error: ', error); return []; });
            setIsLoading(false);
            globalActions.setMyGames(result.data || []);
        };

        loadMyGames();
    }, []);

    useEffect(() => {
        const loadMyGamesFeedbacks = async () => {
            const token = localStorage.getItem('token') || '';
            setIsLoading(true);
            const result = await axios('/api/games/my-games/feedbacks')
                .catch(error => { console.log('error: ', error); return []; });
            setIsLoading(false);
            // console.log(result.data);
            const feedbacks = result.data.map(g => g.feedbacks).flat();
            // console.log('feedbacks: ', feedbacks);
            globalActions.setMyGamesFeedbacks(orderBy('update', 'desc', feedbacks) || []);
        };

        loadMyGamesFeedbacks();
    }, []);


    const gamesOptions = myGames && myGames.map((game) => ({ value: game._id, label: get('metadata.name', game) || game._id }));
    const gamesOptionsWithSelect = gamesOptions && [{ value: -1, label: 'Select' }, ...gamesOptions];
    const gameFeedbacks = myGamesFeedbacks && selectedGameOption && myGamesFeedbacks.filter(g => g.gameId === selectedGameOption.value);
    const feedbacksToDisplay = gameFeedbacks;
    //const feedbacksToDisplay = orderBy('update', gameFeedbacks);

    return (
        <div>
            <Loader active={isLoading} text='Loading Your Games...' >
                <Select
                    value={selectedGameOption}
                    options={gamesOptionsWithSelect}
                    onChange={handleGameChanged}
                    placeholder={'בחירה'}
                />
                <br /><br />

                {
                    feedbacksToDisplay && (<SimpleTable columns={columns} data={feedbacksToDisplay} />)
                }
            </Loader>
        </div>
    )


}

export default MyGamesFeedbacks;