import React from 'react';
import styled from '@emotion/styled';
import homeIcon from './../../images/icons/home-icon.png';
import aboutIcon from './../../images/icons/about-icon.png';
import gamesIcon from './../../images/icons/games-icon.png';
import gameIntroIcon from './../../images/icons/game-intro-icon.png';
import gameMapIcon from './../../images/icons/game-map-icon.png';
import gamePlayIcon from './../../images/icons/game-play-icon.png';


const StyledCircle = styled.div`
    height: ${props => `${props.height}px;`};
    width: ${props => `${props.width}px;`};
    padding: 5px;
    border-radius: ${props => `${props.height/2}px;`};
    background: #c79ef5;
    background: linear-gradient(135deg, #c79ef5 0%, #6eabdb 25%, #5dbed1 75%, #adf0b8 100%);
    box-shadow: 3.5px 5px 10px 0px rgba(0,0,0,0.25);
`;

const StyledInnerCircle = styled.div`
    text-align: center;
    height: ${props => `${props.height-10}px;`};
    width: ${props => `${props.width-10}px;`};
    border-radius: ${props => `${(props.height-10)/2}px;`};
    background: #5f66b0;
    background: linear-gradient(135deg, #5f66b0 0%, #50a5b7 60%, #91d5d1 100%);
    box-shadow: inset 1px 1.5px 10px 0px rgba(0,0,0,0.15);

`;

const ButtonText = styled.button`
    font-size: 22px;
    font-weight: bold;
    color: white;
    line-height: 33px;
    text-align: center;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
`;

const getIcon = (icon) => {
    const iconImage = getIconImage(icon);

    if (!iconImage) return null; 

    return (<img width="40px" src={iconImage} alt={icon} />);
}

const getIconImage = (icon) => {
    switch (icon) {
        case 'home':
            return homeIcon;
        case 'games':
            return gamesIcon;
        case 'about':
            return aboutIcon;

        case 'game-intro':
            return gameIntroIcon;
        case 'game-map':
            return gameMapIcon;
        case 'game-play':
            return gamePlayIcon;
            
        default:
            return null;
    }
}

function RoundButton({text, width = 50, onClick, icon, ...rest}) {
    const height = text ? 46 : width;
    return (
        <React.Fragment>
            <StyledCircle onClick={ onClick } width={ width } height={height}>
                <StyledInnerCircle width={ width } height={height}>
                {getIcon(icon)}
                <ButtonText>{ text }</ButtonText>
                </StyledInnerCircle>
            </StyledCircle>
        </React.Fragment>
    );
  }

  export default RoundButton;