import { merge, get } from 'lodash/fp';

const getGameWithProgress = (globalState) => {
    return merge(globalState && globalState.gameProgress || {}, globalState && globalState.game);
}

export const getGameProgress = (globalState) => globalState && globalState.gameProgress || {};

export const getGames = (globalState) => globalState && globalState.games;

export const getGame = (globalState) => globalState && globalState.game;

export const getGameDisplayPage = (globalState) =>
    // globalState &&
    //     globalState.gameProgress &&
    //     globalState.gameProgress.displayPage;

    (globalState &&
            globalState.ui &&
            globalState.ui.displayPage) || 'intro';

    
export const getGameIntroPage = (globalState) =>
    globalState &&
    globalState.game &&
    globalState.game.introPage;

export const getGameGameoverPage = (globalState) =>
    globalState &&
    globalState.game &&
    globalState.game.gameoverPage;

export const getGameStartTime = (globalState) =>
    globalState &&
    globalState.gameProgress &&
    globalState.gameProgress.startTime;

export const getGameEndTime = (globalState) =>
    globalState &&
    globalState.gameProgress &&
    globalState.gameProgress.endTime;

export const getGameQuestions = (globalState) =>
    globalState &&
    globalState.game &&
    globalState.game.questions;

export const getGameQuestion = (globalState, questionIndex) =>
    globalState &&
    globalState.game &&
    globalState.game.questions &&
    globalState.game.questions[questionIndex];

export const getGameQuestionStatus = (globalState, questionIndex) =>
    globalState &&
    globalState.gameProgress &&
    globalState.gameProgress.questions &&
    globalState.gameProgress.questions[questionIndex] &&
    globalState.gameProgress.questions[questionIndex].status;

export const getGameQuestionAnswer = (globalState, questionIndex) =>
    globalState &&
    globalState.game &&
    globalState.game.questions &&
    globalState.game.questions[questionIndex] &&
    globalState.game.questions[questionIndex].answer;

export const getGameName = (globalState) =>
    globalState &&
    globalState.game &&
    globalState.game.metadata &&
    globalState.game.metadata.name;


export const getGameLocation = (globalState) =>
    globalState &&
    globalState.game &&
    globalState.game.metadata &&
    globalState.game.metadata.location;

export const getGameMetadata = (globalState) =>
    globalState &&
    globalState.game &&
    globalState.game.metadata;

export const getGameQuestionsLocation = (globalState) =>
    globalState &&
    globalState.game &&
    globalState.game.questions &&
    globalState.game.questions.map(question => question && question.location);

export const getGameQuestionHints = (globalState, questionIndex) => {

    const gameWithProgress = getGameWithProgress(globalState);

    return gameWithProgress &&
        gameWithProgress.questions &&
        gameWithProgress.questions[questionIndex] &&
        gameWithProgress.questions[questionIndex].hints;
}

export const getGameQuestionHint = (globalState, questionIndex, hintIndex) =>
    globalState &&
    globalState.game &&
    globalState.game.questions &&
    globalState.game.questions[questionIndex] &&
    globalState.game.questions[questionIndex].hints &&
    globalState.game.questions[questionIndex].hints[hintIndex];

export const getGameQuestionHintTitle = (globalState, questionIndex, hintIndex) =>
    globalState &&
    globalState.game &&
    globalState.game.questions &&
    globalState.game.questions[questionIndex] &&
    globalState.game.questions[questionIndex].hints &&
    globalState.game.questions[questionIndex].hints[hintIndex] && 
    globalState.game.questions[questionIndex].hints[hintIndex].title;


export const getSelectedQuestion = (globalState) => {
    const gameWithProgress = getGameWithProgress(globalState);

    return gameWithProgress &&
        gameWithProgress.questions &&
        gameWithProgress.questions.findIndex(question => !question.status || (question.status !== 'DONE' && question.status !== 'SKIPPED'));
}
//     globalState && 
//     globalState.game && 
//     globalState.game.questions && 
//     // globalState.game.questions.findIndex(question => !question.status || question.status === 'STARTED' || question.status === 'ANSWERED');
//     globalState.game.questions.findIndex(question => !question.status || question.status !== 'DONE');

// export const getQuestionStatus = (globalState, questionId) =>
//     globalState &&
//     globalState.game &&
//     globalState.game.questions &&
//     globalState.game.questions[questionId].status;

// ================ USER ====================

export const getUser = (globalState) =>
    globalState &&
    globalState.user;

export const getIsLoggedIn = (globalState) =>
    globalState &&
    globalState.isLoggedIn;

export const getIsLoggedInRole = (globalState) =>
    globalState &&
    globalState.isLoggedInRole;

export const getUserId = (globalState) =>
    globalState &&
    globalState.userId;

export const getIsSelfPayment = (globalState) =>
    globalState &&
    globalState.isSelfPayment;

export const getIsAdmin = (globalState) =>
    (globalState &&
    globalState.isLoggedInRole) === 'admin';


// ================ USERS ====================
export const getUsers = (globalState) => globalState && globalState.users;


// ================ MENU ====================

export const getIsMenuOpen = (globalState) => globalState && globalState.ui && globalState.ui.isMenuOpen;

//  ================ Editor ==================== 

export const getMyGames = (globalState) => globalState && globalState.myGames;
export const getMyGamesFeedbacks = (globalState) => globalState && globalState.myGamesFeedbacks;
export const getMyActiveGames = (globalState) => globalState && globalState.myActiveGames;

export const getContent = (globalState, path ) => get(`game.${path}`, globalState) || '';

export const getLocation = (globalState, path ) => get(`game.${path}`, globalState) || [];
export const getAnswer = (globalState, questionId ) => get(`game.questions[${questionId}].answer.value`, globalState) || '';



