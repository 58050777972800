
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

const StyledLink = styled(Link)`
    padding: 0 10px; 
    border-right: 1px solid gray;
    &:last-child {
        border: none;
    }
    
    &:hover {
        background-color: #ddf1f0;
    }
    
    div[dir="rtl"] & {
        border-right: initial;
        border-left: 1px solid gray;
        &:last-child {
           border: none;
        }
    }
`;

export default StyledLink;