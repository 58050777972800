import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom'
import useGlobal from "../store";
import axios from 'axios';
import { get } from 'lodash/fp';
import FlexRow from '../components/common/FlexRow';
import ContentWrapper from '../components/common/ContentWrapper';
import StyledLink from '../components/common/StyledLink';
import RoundButton from '../components/common/RoundButton';
import InputField from '../components/common/InputField';

const ErrorMessage = styled.div`
    font-weight: bold;
    color: red; 
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    top: 25px;
    position: relative;
`;

const Links = styled.div`
    text-align: center;
    margin-top: 50px;
`;


function ForgotPassword(props) {

    const { t } = useTranslation();

    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isEmailSent, setIsEmailSent] = useState(false);
    const history = useHistory();
    const [globalState, globalActions] = useGlobal();


    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setErrorMessage('');
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('/api/reset-password', { email })
            .then(response => {
                console.log('/api/reset-password: ', response);
                if (get('data.status', response) === 'OK'){
                    setErrorMessage( t('forgot.password.email.sent') );
                    setIsEmailSent(true)
                }
            })
            .catch(err => {
                console.log('err: ', err);
                console.log('err.message: ', get('message', err));
                
                let errorMessage = get('response.data.message', err);
                if (!errorMessage) {
                    errorMessage = get('message', err);
                }
                setErrorMessage(errorMessage);
            })
    };

    return (
        <React.Fragment>
            <ContentWrapper withButton={!isEmailSent}>
                <h1>{ t('forgot.password.password.recovery') }</h1>
                <form onSubmit={handleSubmit} style={ {width: '300px', margin: '0 auto'}} >
                <FlexRow>
                { t('forgot.password.please.enter.email') }
                <br /><br />
                </FlexRow>

                <FlexRow>
                    <InputField label={ t('email') } value={email} onChange={handleEmailChange} placeholder={ t('email') } type="email" required />
                </FlexRow>

                <ErrorMessage>
                    {errorMessage} &nbsp;
                </ErrorMessage>

                <ButtonWrapper>
                    { 
                    !isEmailSent && (<RoundButton type="submit" value="submit" width={190} text={ t('submit') } />)
                    }
                </ButtonWrapper>
                </form>

            </ContentWrapper>

            <Links>
                <StyledLink to="/" >{ t('page.title.home') }</StyledLink>
                <StyledLink to="/login" >{ t('page.title.login') }</StyledLink>
                <StyledLink to="/signup" >{ t('page.title.registration') }</StyledLink>
            </Links>
        </React.Fragment>
    )
}

export default ForgotPassword;