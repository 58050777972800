import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import useGlobal from "../store";
import { Link } from "react-router-dom";

import { getIsLoggedIn, getIsAdmin } from '../store/selectors';


const MenuWrapper = styled.div`
    position:absolute;
    z-index: 2;
    width: 100%;
    max-width: 400px;
    margin: 20px -10px;
    transition: opacity 1.5s;
    opacity: 1;
    background-color: #ddf1f0;

    box-shadow: 3.5px 5px 10px 0px rgba(0,0,0,0.25);
    
    &.hide-menu {
        opacity: 0;
        pointer-events:none;
    }

`;

const MenuLink = styled(Link)`
    display:block;
    min-height: 40px; 
    line-height: 40px; 
    text-decoration: none;
    color: #3b3b3c;
    font-size: 18px; 
    padding: 0 20px; 
    /* border-bottom: 1px solid #3babab; */

    &:hover {
        background-color: #bbe5ee;
    }
`;

const MenuSpacer = styled.div`
    height: 1px;
    border-bottom: 1px solid #3babab;
`;


function Menu({ isOpen }) {

    const [globalState, globalActions] = useGlobal();
    const isLoggedIn = getIsLoggedIn(globalState);
    const isAdmin = getIsAdmin(globalState);
    const { t, i18n } = useTranslation();

    const classes = isOpen ? '' : 'hide-menu'

    const changeLanguage = (lang, e) => {
        e.stopPropagation();
        e.preventDefault();
        i18n.changeLanguage(lang);
        globalActions.setIsMenuOpen(false);
    }
// console.log('isAdmin:', isAdmin);

    return (
        <MenuWrapper className={classes}>
            {
                isLoggedIn && (
                    <React.Fragment>
                        <MenuLink to="/my-games" title="My Games">{t('menu.my.games')}</MenuLink>
                        <MenuLink to="/my-active-games" title="My Active Games">{t('menu.my.activities')}</MenuLink>
                        <MenuLink to="/my-games/feedbacks" title="My Active Games">{t('menu.my.feedbacks')}</MenuLink>

                        <MenuSpacer />
                        <MenuLink to="/update-profile?" title="Profile Update">{t('menu.profile.update')}</MenuLink>
                        <MenuLink to="/update-payment-details?" title="P">{t('menu.profile.bank.account')}</MenuLink>
                        <MenuLink to="/reports?" title="Login">{t('menu.profile.finance')}</MenuLink>
                        {
                            isAdmin && (
                                <React.Fragment>
                                    <MenuSpacer />
                                    <MenuLink to="/admin-users" title="Users">{t('menu.admin.affiliates')}</MenuLink>
                                    <MenuLink to="/admin-pending-games" title="Pending Games">{t('menu.admin.pending.games')}</MenuLink>
                                    <MenuLink to="/admin-generate-codes" title="Generate Codes">{t('menu.admin.generate.codes')}</MenuLink>
                                </React.Fragment>
                            )
                        }
                    </React.Fragment>
                )
            }
            {
                !!!isLoggedIn && (
                    <React.Fragment>
                        <MenuLink to="/login" title="Login">{t('menu.connect')}</MenuLink>
                    </React.Fragment>
                )
            }
            <MenuSpacer />
            <MenuLink to="/affiliates" title="Affiliates">{t('menu.partners.affiliates')}</MenuLink>
            <MenuLink to="/builders" title="Game Builders">{t('menu.partners.game.builders')}</MenuLink>
            <MenuSpacer />
            <MenuLink to="#" title="">
                <span onClick={(e) => { changeLanguage('en', e) }}>EN</span>
                &nbsp;&nbsp;
                <span onClick={(e) => { changeLanguage('he', e) }}>HE</span>
            </MenuLink>
            <MenuSpacer />
            <MenuLink to="/about" title="About">{t('menu.about')}</MenuLink>
            <MenuLink to="/terms" title="About">{t('menu.terms.and.policy')}</MenuLink>

            {
                isLoggedIn && (
                    <React.Fragment>
                        <MenuSpacer />
                        <MenuLink to="/logout" title="Login">{t('menu.disconnect')}</MenuLink>
                    </React.Fragment>
                )
            }
        </MenuWrapper>
    );
}

export default Menu;