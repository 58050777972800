import React, { useEffect, useState } from 'react';
import useGlobal from '../store';
import { getContent } from './../store/selectors';


import styled from '@emotion/styled';
import HtmlEditor from './common/HtmlEditor';

const EditorWrapper = styled.div`
    background-color: white;
    width: 400px;
`;

function EditContent({path}) {

    const [globalState, globalActions] = useGlobal();
    const content = getContent(globalState, path);

    const onChange = (value, delta, source, editor) => {
        if (source === 'user'){
            globalActions.gameEditorUpdateContent(path, value);
        }        
    }

    return (
        <React.Fragment>
            <EditorWrapper>
                <HtmlEditor value={content} onChange={ onChange } />
            </EditorWrapper>
        </React.Fragment>
    );
}

export default EditContent;