import React from 'react';
// import React, { useState } from 'react';
// import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

// import GoogleMap, { Polygon } from 'google-map-react';
// import { usePosition } from 'use-position';

// import useGlobal from "../store";
// import { getGame, getGameLocation, getGameQuestionsLocation, getSelectedQuestion } from '../store/selectors';
// import MapPin from './common/MapPin';
// import MyLocationPointer from './MyLocationPointer';
// import { useEffect } from 'react';
// import MyLocationIcon from './../images/my-location-icon.png';
import ContentWrapper from './common/ContentWrapper';
// import { get } from 'lodash';


function GameMapOnline() {

    const { t } = useTranslation();
    // const [globalState, globalActions] = useGlobal();
    // const game = getGame(globalState);
    // const gameLocation = getGameLocation(globalState);
    // const gameQuestionsLocation = getGameQuestionsLocation(globalState);
    // const selectedQuestion = getSelectedQuestion(globalState);

    return (
        <React.Fragment>
            <ContentWrapper>
                <div>
                    <h1>
                        {t('game.map.online')}
                    </h1>
                    {t('game.map.online.no.map')}
                </div>
            </ContentWrapper>
        </React.Fragment>
    );
}

export default GameMapOnline;