import React from 'react';

function Content({ content, isInnerContent = false }) {

    const handleDoubleClick = (e) => {
        if (e.target.nodeName === 'IMG') {
            if (e.target.style.maxWidth === ''){
                e.target.style.maxWidth = 'inherit';
            }else{
                e.target.style.maxWidth = '';
            }
        }
    }

    return (
        <React.Fragment>
            <div dangerouslySetInnerHTML={ {__html: content}} onDoubleClick={handleDoubleClick} />
        </React.Fragment>
    )
  }

  export default Content;