import React from 'react';
import styled from '@emotion/styled';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { get } from 'lodash/fp';


import ContentWrapper from '../components/common/ContentWrapper';
import RoundButton from '../components/common/RoundButton';


const PageTitle = styled.div`
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    padding: 10px 0;
`;

const PageText = styled.div`
    padding-bottom: 20px; 

    ul {
        padding-right: 20px;
    }
    ul, ol, li {
        list-style: initial;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    top: 25px;
    position: relative;
`;


const handleCreateNewGame = () => {
    axios.post('/api/games/new', {} )
    .then(response => {
        console.log('/api/games/new: ', response);
        const gameId = get('data._id', response);
        if (gameId){
            window.location.href = `/game-editor/${gameId}`;
        }
    })
    .catch(err => {
        let errorMessage = get('response.data.message', err);
        if (!errorMessage) {
            errorMessage = get('message', err);
        }
        // setErrorMessage(errorMessage);
    })

}


function GameEditorNewGame() {

    const location = useLocation();

    return (
        <ContentWrapper withButton>
            <PageTitle>
                יצירת משחק חדש
                <br />
                 הוראות
            </PageTitle>
            <PageText>
                <section>
                    כל אחד יכול לבנות משחק, אבל לא כל אחד יכול לבנות משחק מוצלח.
                    <br />
                    על מנת לבנות משחק רחוב מוצלח, יש לעבוד ב-2 מישורים.
                    <br />
                    המישור הראשון הוא <b>הסיפור</b>, והמישור השני הוא <b>החידות</b>.
                    <br /><br />

                    <u><b>סיפור המשחק</b></u>: - זהו סיפור שמלווה את השחקנים לאורך כל ההרפתקאה. הסיפור למעשה מכניס את השחקנים לאווירה,
                    וחלקים ממנו אמורים להופיע בכל שאלה ושאלה.
                    <br />
                    למשל, הסיפור יכול להיות שצריך להציל מישהו, ואז בכל שאלה יש התקדמות לקראת ההצלה,
                    או שהסיפור יכול להיות נושא כלשהו והשחקנים יודעים שהחידות השונות הן סביב אותו נושא.
                    <br /><br />

                    <u><b>החידות</b></u>: - החידות עצמן לא חיבות להיות קשורות אחת לשנייה, כל חידה אמורה להיות במיקום אחר,
                    וההמערכת מובילה את השחקנים מנקודה לנקודה על פני המפה.
                    החידות יכולות להיות חידות הגיון, חידות של רצפים, חידות של ידע כללי, ולמעשה כל מה שנראה לך,
                    אבל הן צריכות להיות ברורות ועם פתרון ברור כדי לא "לתסכל" את השחקנים.
                    <br />
                    לכל חידה יש מאפיינים קבועים, שכדאי להכין אותם מראש, ורק אז להתחיל את בנית המשחק פה במערכת עריכת המשחקים.
                    <br /><br />
                </section>
                <section>
                    <h1>מבנה המשחק</h1>

                    לכל המשחקים יש מבנה זהה, והתוכן הוא מה שמבדיל ביניהם
                    <br />
                    <ul>
                        <li><u>נתונים כלליים</u></li>
                        <ul>
                            <li>שם המשחק</li>
                            <li>מיקום. (עיר + תיאור). תיאור יכול להיות: "המרכז הגדול" או "בית ספר המילניום"</li>
                            <li>מחיר + מטבע (אנחנו עובדים עם ש"ח מן הסתם)</li>
                            <li>נקודת המפגש. המיקום המדוייק בו מתחיל המשחק. רצוי שזה יהיה במקום ברור ונגיש, רצוי אפילו מקום שאפשר לחנות לידו ולהשאיר את הרכב למשך המחשק.</li>
                        </ul>

                        <li><u>עמוד מקדים</u></li>
                        <ul>
                            <li>
                                עמוד זה מופיעה כשעוברים על המשחקים השונים באתר, וגם כאשר מתחילים את המשחק.
                                <br />
                                זה המקום לכתוב את "סיפור המשחק". לאיזה גילאים הוא מיועד, כמה זמן הוא אמור לקחת,
                                האם יש דברים מיוחדים שכדאי לקחת (פנס? פנקס קטן ועט? מים?)
                            </li>
                        </ul>

                        <li><u>עמודי שאלות</u></li>
                        לכל השאלות יש מבנה קבוע שכולל:
                        <ul>
                            <li>מיקום - יכולה להיות נקודה על המפה, או שטח תחום על המפה (פוליגון).</li>
                            <li>השאלה עצמה - השאלה יכולה להכיל טקסט, תמונות, ו/או וידאו</li>
                            <li>תשובה - התשובה הנכונה שאותה יצטרכו השחקנים להקליד במערכת, ורק היא תעביר אותם שלב.</li>
                            <li>רמזים - לא חובה, אבל בהחלט כדאי.
                            הרעיון הוא שהשאלה תהיה קשה, (אבל פתירה), ועם רמזים יהיה יותר ברור איך לפתור אותה.
                            נהוג להכין מספר רמזים מהקל לקשה, ונהוג גם שהרמז האחרון יהיה כבר ממש הפתרון המלא לשאלה,
                            כדי שהשחקנים לא יתקעו על השאלה במידה והם לא הבינו אותה.
                            </li>
                        </ul>

                        <li><u>עמוד סוף משחק</u></li>
                        עמוד זה מופיע לאחר השאלה האחרונה, והוא כולל את הדברים הבאים:
                        <ul>
                            <li>
                                תוצאת המשחק - שכוללת זמן כולל, ונתונים סטטיסטיים.
                                (כמה שאלות נענו, בכמה רמזים השתמשו וכו')
                                 <br />
                                החלק הזה אוטומטי.
                            </li>
                            <li>דירוג המשחק - כמה היה מהנה, כמה היה קשה, כמה ימליצו עליו לחברים</li>
                            <li>טקסט חופשי, תמונות, סרטון - מה שבא לך לכתוב לשחקנים שכרגע סיימו לשחק את המשחק שהכנת.</li>
                        </ul>
                    </ul>
                    <br /><br />
                </section>
                <section>
                    <h1>אישור משחק</h1>

                    לאחר שסיימת לערוך את המשחק פה באתר, הוא עדיין לא יופיע ברשימת המשחקים.
                    <br />
                    בשלב הזה את/ה יכולים לשחק במשחק שבנית, ולראות שהכל עובד כמו שתכננת.
                    <br />
                    במידה והכל נראה תקין, יש לשלוח אותו לאישור שלנו ע"י לחיצה על "שלח לאישור",
                    וברגע שהוא יאושר ישלח אלייך אימייל שהמשחק פעיל וזמין לרכישה.
                    <br /><br />
                </section>


                לעריכת משחק חדש - לחצו &nbsp;
                <Link to="/games" >כאן</Link>
            </PageText>

            <ButtonWrapper>
                <RoundButton onClick={handleCreateNewGame} width={190} text="צור משחק חדש" />
            </ButtonWrapper>

        </ContentWrapper>
    )
}

export default GameEditorNewGame;