import React from 'react';
import { getFormattedCurrency } from './../utils';
import styled from '@emotion/styled';
import logoHeaderImage from './../images/logo_header_2_300.png';

const GameRowWrapper = styled.div`
    position: relative;
    overflow: hidden;
    background-color: white;
    border: 1px solid #a7a9ac;
    border-radius: 175px;
    margin: 0 10px 20px 10px;

    box-shadow: 3.5px 5px 10px 0px rgba(0,0,0,0.25);

    :hover {
        background-color: #eeeeee;
    }
 `;

const GameCoverImage = styled.img`
    height: 104px;
    width: 104px;
    border-radius: 50%;
`;

const GameContent = styled.div`
    flex: 1;
    padding: 15px 5px;
    color: #3b3b3c;
`;

const GameName = styled.div`
    font-size: 14px;
`;

const GameLocation = styled.div`
    padding-top: 10px;
    font-size: 10px;
`;

const GamePrice = styled.div`
    max-height: 54px;
    color: white;
    width: 58px;
    font-size: 20px;
    overflow: hidden;
    align-self: center;
    line-height: 54px;
    /* padding-right: 10px; */
    
    background-color: #0099b6;

    padding: 0 0 0 10px;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;


    div[dir="rtl"] & {
        padding: 0 10px 0 0;

        border-top-left-radius: initial;
        border-bottom-left-radius: initial;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;

    }


`;

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
`;

function GameRow(props) {
    const {coverImage, name, locationText, locationCity, price, currencyCode} = props;
    const safeCoverImage = coverImage || logoHeaderImage;

    return (
        <GameRowWrapper>
            <FlexRow>
                <GameCoverImage src={ safeCoverImage } />
                <GameContent>
                    <GameName>{name}</GameName>

                    <GameLocation>{locationCity}<br />{locationText}</GameLocation>
                </GameContent>
                <GamePrice>{getFormattedCurrency(price, currencyCode)}</GamePrice>
            </FlexRow>
        </GameRowWrapper>
    );


}

export default GameRow;