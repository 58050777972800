import React from 'react';
import styled from '@emotion/styled';

import useGlobal from "../store";
import { getGameDisplayPage } from '../store/selectors';
import GameTime from './GameTime';

import { Link } from "react-router-dom";
import RoundButton from './common/RoundButton';

const HeaderWrapper = styled.div`
  position: relative;
  margin-bottom: 45px;
`;

const LogoContainer = styled.div`
  height: 100px; 
  background-color: #162447;
  background: linear-gradient(245deg,#162447 0%,#2a2446 100%);
  border-bottom-left-radius: 150px 15px;
  border-bottom-right-radius: 150px 15px;
  color: white;
`;

const LogoWrapper = styled.div`
  height: 105px; 
  background-color: #c79ef5;
  background: linear-gradient(135deg,#c79ef5 0%, #6eabdb 30%, #5dbed1 60%, #adf0b8 100%);
  border-bottom-left-radius: 150px 15px;
  border-bottom-right-radius: 150px 15px;
`;

const HeaderButtons = styled.div`
  display: flex;
  justify-content: center;
  direction: ltr;
  text-align: left;
  padding: 0 0 10px 0;
  font-size: 2em;
  width: 100%;
  position: absolute;
  top: 70px;
    
  > * {
    margin: 0 10px;
  } 
`;

const MenuText = styled.div`
  font-size: 10px; 
  text-align: center;
  padding-top: 3px;
`;

const StyledLink = styled(Link)`
color: white;
`;

function GameMenu() {

  // getGameDisplayPage
  const [globalState, globalActions] = useGlobal();
  const currentPage = getGameDisplayPage(globalState);

  const changePage = (page) => {
    globalActions.setDisplayPage(page);
  }

  // https://www.w3schools.com/css/css_navbar.asp

  return (
    <React.Fragment>
      <HeaderWrapper>
        <LogoWrapper>
        <LogoContainer>
          {
            // isLoggedIn && (<StyledLink to="/game-editor" title="Edit Game">Edit Games</StyledLink>)
          }
          {
            // !!!isLoggedIn && (<StyledLink to="/login" title="Login">IN</StyledLink>)
          }
          {
            // isLoggedIn && (<StyledLink to="/logout" title="Login">OUT</StyledLink>)
          }
        </LogoContainer>
        </LogoWrapper>
        <HeaderButtons>
          <div onClick={() => changePage('map')} title="Map">
            <RoundButton icon='game-map' />
            <MenuText>מפה</MenuText>
          </div>
          <div onClick={() => changePage('questions')} title="Questions">
            <RoundButton icon='game-play' />
            <MenuText>משחק</MenuText>
          </div>
          <div onClick={() => changePage('intro')} title="Intro">
            <RoundButton icon='game-intro' />
            <MenuText>הקדמה</MenuText>
          </div>
        </HeaderButtons>
      </HeaderWrapper>
    </React.Fragment>
  );
}

export default GameMenu;