export const getCurrency = (currencyCode) => {
    switch (currencyCode) {
        case "ILS":
            return "₪";
        case "USD":
            return "$";
        default: 
            return "";
    }
};

export const getFormattedCurrency = ( price, currencyCode) => {
    if (!price || price === '0') {
        return "חינם"; 
    }

    return currencyCode === 'ILS'
    ? `${ getCurrency(currencyCode)}${ price  }`
    : `${ price }${ getCurrency(currencyCode) }`;
}

export const msToTime = (duration) => {
    var milliseconds = parseInt(duration%1000)
        , seconds = parseInt((duration/1000)%60)
        , minutes = parseInt((duration/(1000*60))%60)
        , hours = parseInt((duration/(1000*60*60))%24);

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
}

const TwoDigits = (val) => {
    return ('00' + val).substr(-2);
}

export const getHumanFullTime = (dt) => dt && `${TwoDigits(dt.getHours())}:${TwoDigits(dt.getMinutes())}:${TwoDigits(dt.getSeconds())}`;
export const getHumanShortTime = (dt) => dt && `${TwoDigits(dt.getHours())}:${TwoDigits(dt.getMinutes())}`;
// export const getHumanDate = (dt) => dt && `${dt.getFullYear()}-${TwoDigits(dt.getMonth())}-${TwoDigits(dt.getDate())}`;
// export const getHumanDateTime = (dt) => dt && `${getHumanDate(dt)} ${getHumanShortTime}`;

export const getHumanDate = (dt) => {
    const dtObj = new Date(dt); 
    return isValidDate(dtObj) && `${dtObj.getFullYear()}-${TwoDigits(dtObj.getMonth()+1)}-${TwoDigits(dtObj.getDate())}`
};

export const getHumanDateTime = (dt) => {
    const dtObj = new Date(dt); 
    return isValidDate(dtObj)&& `${getHumanDate(dtObj)} ${getHumanShortTime(dtObj)}`;
}

export const isNumber = (val) => !isNaN(parseInt(val));

export const isValidEmail = (email) => {
    const regEx = /^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regEx.test(email);
}

export const getTimeDiff = (startTime, endTime) => {
    const eTime = endTime && new Date(endTime); 
    const sTime = startTime && new Date(startTime);

    const diff = new Date(eTime - sTime);
    if (diff == 'Invalid Date'){
      return null;
    } 
    
    const hour = diff.getUTCHours();
    const min = diff.getUTCMinutes();
    const sec = diff.getUTCSeconds();

    return (hour + ":" +min.toString().padStart(2, '0') + ":" + sec.toString().padStart(2, '0'));
}

export const calculateGameScore = (activeGameData) => {
    const {questions, startTime, lastUpdate} = activeGameData;

    if (!questions) {
        return 0;
    }

    const datesDiff = new Date(lastUpdate) - new Date(startTime);
    const totalMinutes = Math.floor(datesDiff / 60000);
    
    const doneQuestions = questions && questions.filter(q => q && q.status === 'DONE');
    // console.log('doneQuestions: ', doneQuestions);
    const openHints = doneQuestions && doneQuestions.reduce((acc, q) => (q.hints ? acc + (q.hints.filter(h => h && h.isOpen)).length : acc), 0);
    // console.log('openHints: ', openHints);
    
    const score = (100 * doneQuestions.length) - (openHints * 10) -  totalMinutes;
    return score < 0 ? 0 : score;
}

export const isValidDate = (d) => d instanceof Date && !isNaN(d);

export const downloadCSV = (csv, filename) => {
    // const csvFile;
    // const downloadLink;

    // CSV file
    const csvFile = new Blob([csv], {type: "text/csv"});

    // Download link
    const downloadLink = document.createElement("a");

    // File name
    downloadLink.download = filename;

    // Create a link to the file
    downloadLink.href = window.URL.createObjectURL(csvFile);

    // Hide download link
    downloadLink.style.display = "none";

    // Add the link to DOM
    document.body.appendChild(downloadLink);

    // Click download link
    downloadLink.click();
}
export const exportTableToCSV = (filename) => {
    const csv = [];
    const rows = document.querySelectorAll("table tr");
    
    for (var i = 0; i < rows.length; i++) {
        var row = [], cols = rows[i].querySelectorAll("td, th");
        
        for (var j = 0; j < cols.length; j++) {
            const innerText = cols[j].innerText || '';
            const safeText = innerText.replace('"', '""');
            row.push(`"${safeText}"`);
        }
        csv.push(row.join(","));        
    }

    // Download CSV file
    downloadCSV(csv.join("\n"), filename);
}

export default {
    getCurrency,
    getFormattedCurrency,
    isNumber,
    isValidEmail,
    getHumanDate,
    getHumanDateTime,
    getTimeDiff,
    calculateGameScore,
    downloadCSV,
    exportTableToCSV
};