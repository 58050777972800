import React, { useState } from 'react';
import styled from '@emotion/styled';
import Tree, { TreeNode } from 'rc-tree';

const NodeWraper = styled.div`
    background-color: ${props => props.selected ? '#b3dae3' : '#ffffff'};
    border-color: ${props => props.selected ? '#5dbed1' : '#ffffff'};
    height: 32px;
    line-height: 32px;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    margin: 2px 0 3px 0;
    margin-right: ${props => props.treeLevel * 20}px;
    font-size: 24px; 
    padding-right: 30px; // ?
    &:hover {
        background-color: #e1eef0;
        border-color: #5dbed1;
    }

    img {
        padding: initial;
    }
`;

const MyNode = (props) => {
    console.log('props: ', props);
    const {selected, nodeTitle, pos, nodeIcon} = props; 
    const treeLevel = pos.split('-').length - 1;
    // console.log('treeLevel: ', nodeTitle, treeLevel);
    
    return (
        <NodeWraper selected={selected} treeLevel={treeLevel-1}>
            {/* {props.children} */}
            { nodeIcon ? <img src={nodeIcon} /> : <span style={ {width: '24px'}}>&nbsp;</span>}
            <span>{nodeTitle}</span>
        </NodeWraper>
    );
}
function MyTree(props) {

    const [expandedKeys, setExpandedKeys] = useState([]);

    // const handleOnExpand = (...arg) => {
    //     console.log('handleOnExpand: ', ...arg);
    // }
    const handleOnSelect = (selectedKey, event) => {
        // console.log('expanedKeys: ', expandedKeys);
        const key = event.node.key;
        setExpandedKeys([key]);
        // let newExpanedKeys;
        // if (expandedKeys.includes(key)) {
        //     newExpanedKeys = expandedKeys.filter(element => element !== key);
        // }else{
        //     newExpanedKeys = [...expandedKeys, key];
        // }
        // console.log('newExpanedKeys: ', newExpanedKeys);

        // setExpandedKeys(newExpanedKeys);
    }
    const handleOnCheck = (...arg) => {
        console.log('handleOnCheck: ', ...arg);
    }

    console.log('expandedKeys:', expandedKeys);


    const treeData = [
        { key: 'metadata', title: null, nodeTitle: 'נתונים כלליים', nodeIcon: 'xxx.jpg', children: [] },
        { key: 'intro', title: null, nodeTitle: 'עמוד מקדים', children: [] },
        {
            key: 'q-0', title: null, nodeTitle: 'שאלה 1', children: [
                { key: 'qh-0-0', title: null, nodeTitle: 'רמז 1', children: [] },
                { key: 'qh-0-1', title: null, nodeTitle: 'רמז 2', children: [] },
                { key: 'qh-0-2', title: null, nodeTitle: 'רמז 3', children: [] },
            ]
        },
        {
            key: 'q-1', title: null, nodeTitle: 'שאלה 2', children: [
                { key: 'qh-1-0', title: null, nodeTitle: 'רמז 1', children: [] },
                { key: 'qh-1-1', title: null, nodeTitle: 'רמז 2', children: [] },
                { key: 'qh-1-2', title: null, nodeTitle: 'רמז 3', children: [] },

            ]
        },
        { key: 'q-2', title: null, nodeTitle: 'שאלה 3', children: [] },
        { key: 'gameover', title: null, nodeTitle: 'עמוד סוף משחק', children: [] },
    ];

    const loop1 = data => (
        data.map((item) => {
            //  console.log('item:', item);

            return (<TreeNode 
                // title={item.title} 
                key={item.key} 
                items={(item.children && item.children.length) ? loop1(item.children) : null}
                icon={ MyNode }
                >
            </TreeNode>);
        }
        )
    )

    const loop = (data, identity=0) => (
        data.map((item, identity) => {
            console.log('item:', item);
            const childrensItentity = item.children && item.children.length && identity++;
            return (
                <div key={item.key}>
                    <MyNode key={item.key}>
                        {identity} - {item.title}
                </MyNode>
                {(item.children && item.children.length) ? loop(item.children, childrensItentity) : null}
                </div>
            );
        }
        )
    )



    // console.log('loop:', loop(treeData));

    return (
        <React.Fragment>
            <Tree style={ { width: '300px', backgroundColor: '#e9e9eb', padding: '10px'}  }
                onSelect={handleOnSelect}
                onCheck={handleOnCheck}
                defaultExpandedKeys={[]}
                expandedKeys={expandedKeys}
                autoExpandParent={true}
                treeData={treeData}
                icon={MyNode}

            >
                {/* {loop1(treeData)} */}
            </Tree>

            <div>
                {/* {loop1(treeData)} */}
            </div>
        </React.Fragment>

    );
}

export default MyTree