import React, { useState, useEffect } from 'react';
import SocketContext from './../utils/socket-context';
import useGlobal from '../store';
import { getSelectedQuestion, getGameDisplayPage, getGameStartTime, getGameQuestionStatus, getGame } from './../store/selectors';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import isEmpty from 'lodash/fp/isEmpty';
import Loader from './../components/common/Loader';

import IntroPage from '../components/IntroPage';
import Question from '../components/Question';
import GameMap from '../components/GameMap';
import PaymentPage from '../components/PaymentPage';
import get from 'lodash/fp/get';
import styled from '@emotion/styled';

const GameWrapper = styled.div`
    max-width: 600px;
    margin: 0 auto;
`;

const Game = (props) => (
    <SocketContext.Consumer>
        {socket => <GameWithSocket {...props} socket={socket} />}
    </SocketContext.Consumer>
)

function GameWithSocket(props) {

    const { match: { params: { gameId } }, socket } = props;
    const { i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [globalState, globalActions] = useGlobal();
    window.globalState = globalState;

    const game = getGame(globalState);

    const selectedQuestion = game && getSelectedQuestion(globalState);
    const displayPage = game && getGameDisplayPage(globalState);
    const gameStartTime = game && getGameStartTime(globalState);
    const questionStatus = game && getGameQuestionStatus(globalState, selectedQuestion);

    
    // console.log('selectedQuestion: ', selectedQuestion);
    // console.log('displayPage: ', displayPage);
    // console.log('gameStartTime: ', gameStartTime);
    
    // Socket
    useEffect(() => {
        socket.on('connect', function(){ 
//             console.log('SOCKET-IO: Clinet Connected'); 
        });
        socket.on('event', function(data){ console.log('SOCKET-IO: data: ', data);});
        socket.on('game-progress', function(data){
            console.log('SOCKET-IO: game-progress - data: ', data);

            const storageGameProgress = JSON.parse(localStorage.getItem('active-game'));
            const { game_id, game_secret } = storageGameProgress || {};
            if ((game_id === (data && data.game_id)) && 
                (game_secret === (data && data.game_secret))){
                globalActions.setGameProgress(data);
            }

            

            // if (isOldGame(get('endTime', data)) || data.status === 'ERROR'){
            //     globalActions.setGameProgress({});
            //     localStorage.setItem('active-game', '');
            // }else{
            //     globalActions.setGameProgress(data);
            // }
            
        });
        socket.on('disconnect', function(){ console.log('SOCKET-IO: Clinet Disconnected'); });
    }, []);

    const changeLanguage = (lang) => {
        if (i18n.language !== lang){
            i18n.changeLanguage(lang);
        }
    }

    useEffect(() => {
        let isCancelled = false;
        const loadGame = async () => {

            const storageGameProgress = JSON.parse(localStorage.getItem('active-game') || '{}');
            const {game_id, game_secret} = storageGameProgress || {};

            setIsLoading(true);
            const result = await axios(`/api/games/${gameId}`);  // TODO catch error (Server 500)
            setIsLoading(false);
            if (!isCancelled) {
                globalActions.setGame(result.data);
                const gameLanguage = get('metadata.language', result.data);
                if (gameLanguage) {
                    changeLanguage(gameLanguage)
                }
                
            }

            
            if (gameId === game_id) {
                await axios.post(`/api/game-progress`, {
                    "action": "get-game-progress",
                    "gameId": game_id,
                    "secretCode": game_secret // "qweqwe"

                });  // TODO catch error (Server 500)
            }else{
                localStorage.setItem('active-game', '{}');
                globalActions.setGameProgress({});
                globalActions.setDisplayPage('intro');
            }

            
        };
        loadGame();

        return () => { isCancelled = true; }
    }, [gameId]);
    if (!game || isEmpty(game)) { return null }

    return (
        <GameWrapper>
            <Loader active={isLoading} text='Loading Game...' >
                {
                    // isLoading && (<div>Loading...</div>)
                }

                {
                    displayPage === 'intro' && (<IntroPage />)
                }
                
                {
                    displayPage === 'payment' && (<PaymentPage gameId={ gameId } />)
                }

                {
                    displayPage === 'questions' && gameStartTime && (
                        <Question gameId={ gameId } questionIndex={selectedQuestion} />
                    )
                }

                {
                    displayPage === 'map' && (<GameMap />)
                }
            </Loader>
        </GameWrapper>
    )


}

export default Game;