import React from 'react';
import styled from '@emotion/styled';
import useGlobal from '../store';
import { getGame } from './../store/selectors';
import optionDelete from './../images/tree/options/options-delete.png';
import optionUp from './../images/tree/options/options-up.png';
import optionDown from './../images/tree/options/options-down.png';
import { useState } from 'react';
import { useEffect } from 'react';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;

`;

const NodeWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    background-color: ${props => props.selected ? '#b3dae3' : '#ffffff'};
    border-color: ${props => props.selected ? '#5dbed1' : '#ffffff'};
    height: 32px;
    line-height: 32px;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    margin: 2px 0 3px 0;
    font-size: 24px; 

    &.error {
        background-color: #fcdedf;
        border-color: #c80000;
    }
    &:hover {
        background-color: #e1eef0;
        border-color: #5dbed1;
    }

`;

const getTreeIcon = (key) => {

    if (key.startsWith('map-')) {
        return 'location';
    } else if (key.startsWith('qh-')) {
        return 'hint';
    } else if (key.startsWith('q-')) {
        return 'question';
    } else if (key.startsWith('answer-')) {
        return 'answer';
    } else if (key.startsWith('add-h')) {
        return 'hint';
    } else if (key.startsWith('add-q')) {
        return 'question';
    }

    return 'page';
};


const getIsAddNode = (key) => {

    if (key.startsWith('add-')) {
        return true;
    }
    return false;
};

const IconWrapper = styled.div`
    padding: 4px 0 0 4px;
    img {
        width: 20px;
        height: 24px
    }
`;

const AddIconWrapper = styled.div`
    padding-top: 4px;
    img {
        height: 24px;
        width: 12px;
    }
`;

const OptionsContainer = styled.span`
    padding: 0 5px;
    direction: ltr;
`;

const OptionButton = styled.span`
    padding: 0 2px;
    border: 1px solid transparent;

    &:hover{
        cursor: pointer;
    }
    & img {
        width: 150x;
        height: 15px;
    }
`;

const MenuLines = styled.span`

    & img {
        width: 22px; 

        content: url('/images/icons/tree-menu/ident.png');

        &.child {
            content: url('/images/icons/tree-menu/child-ltr.png');
        }
        &.last-child {
            content: url('/images/icons/tree-menu/last-child-ltr.png');
        }
    }
    
    div[dir="rtl"] & {
        & img {
            &.child {
                content: url('/images/icons/tree-menu/child-rtl.png');
            }
            &.last-child {
                content: url('/images/icons/tree-menu/last-child-rtl.png');
            }        
        }
    }

`;


const NodeIdentity = ({ treeLevel, isLastChild, isChild }) => {
    if (treeLevel < 2) { return null; };

    const arr = new Array(treeLevel - 2).fill(1);
    return (
        <React.Fragment>
            {
                arr.map((val, index) => (
                    <MenuLines key={index}><img className={'ident'} /></MenuLines>
                ))
            }
            <MenuLines><img className={isLastChild ? 'last-child' : 'child'} /></MenuLines>
        </React.Fragment>
    );
}


const NodeIcon = ({ icon }) => {
    if (!icon) { return null; }

    return (
        <IconWrapper>
            <img src={`./../images/icons/tree-menu/${icon}.png`} />
        </IconWrapper>
    );
}

const AddIcon = () => {
    return (
        <AddIconWrapper>
            <img src={`./../images/icons/tree-menu/add.png`} />
        </AddIconWrapper>
    );
}


const NodeActions = ({ dataKey }) => {

    const [elementType, setElementType] = useState();
    const [questionIndex, setQuestionIndex] = useState();
    const [hintIndex, setHintIndex] = useState();

    const [globalState, globalActions] = useGlobal();
    const game = getGame(globalState);

    const handleOptionDelete = (e) => {
        e.stopPropagation();
        elementType === 'q' 
        ? globalActions.gameEditorDeleteQuestion(questionIndex) 
        : globalActions.gameEditorDeleteHint(questionIndex, hintIndex) ;
    }

    const handleOptionDown = (e) => {
        e.stopPropagation();
        elementType === 'q' 
        ? globalActions.gameEditorMoveQuestion(questionIndex, 'down') 
        : globalActions.gameEditorMoveHint(questionIndex, hintIndex, 'down') 
    }

    const handleOptionUp = (e) => {
        e.stopPropagation();
        elementType === 'q' 
        ? globalActions.gameEditorMoveQuestion(questionIndex, 'up') 
        : globalActions.gameEditorMoveHint(questionIndex, hintIndex, 'up') 
    }

//     console.log('dataKey: ', dataKey);
    useEffect(() => {
        const keyParts = dataKey.split('-');
        setElementType(keyParts[0]);
        setQuestionIndex(keyParts[1] && Number(keyParts[1]));
        setHintIndex(keyParts[2] && Number(keyParts[2]));
    }, [dataKey])

    return (
        <OptionsContainer>
            <OptionButton onClick={ handleOptionDelete }><img src={ optionDelete} alt="Delete" /></OptionButton>
            <OptionButton onClick={ handleOptionDown }><img src={ optionDown} alt="Down" /></OptionButton>
            <OptionButton onClick={ handleOptionUp }><img src={ optionUp} alt="Up"/></OptionButton>
        </OptionsContainer>
    )
}

const AddIconSpacer = styled.div`
    width: 12px;
`;

function TreeNode(props) {
    // console.log('props: ', props);
    const { selected, nodeTitle, pos, data } = props;
    const treeLevel = pos.split('-').length - 1;
    // console.log('treeLevel: ', nodeTitle, treeLevel);
    const dataKey = data && data.key;
    const icon = getTreeIcon(data && data.key);
    const isAddNode = getIsAddNode(data && data.key);
    const isLastChild = data && data.isLastChild;
    const isChild = data && data.isChild;
    const className = data && data.className;

    return (
        <React.Fragment>
            <Wrapper>
                <NodeIdentity treeLevel={treeLevel} isLastChild={isLastChild} isChild={isChild} />
                <NodeWrapper selected={selected} className={ className }>
                    {
                        isAddNode ? (<AddIcon />) : (<AddIconSpacer />)
                    }
                    {
                        icon && (<NodeIcon icon={icon} />)
                    }
                    <span style={{ flex: 1 }}>{nodeTitle}</span>
                    {
                        dataKey && (dataKey.startsWith('q-') || dataKey.startsWith('qh-')) && (
                            <NodeActions dataKey={dataKey} >X</NodeActions>
                        )
                    }

                </NodeWrapper>
            </Wrapper>
        </React.Fragment>
    );
};

export default TreeNode;