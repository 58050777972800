import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
// import useFetch from 'use-http'
import axios from 'axios';
import utils from './../utils';
import { get } from 'lodash/fp';

import { getFormattedCurrency } from './../utils';

import Loader from './../components/common/Loader';

// import defaultGameCover from './../images/placeholder-150x150.png';
import { getUsers } from '../store/selectors';
import useGlobal from '../store';
import styled from '@emotion/styled';
import GameRow from '../components/GameRow';
import FlexRow from '../components/common/FlexRow';
import SimpleTable from '../components/common/simple-table/SimpleTable';

const StyledLink = styled(Link)`
    text-decoration: 'none';

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;


function AdminUsers(props) {

    const [isLoading, setIsLoading] = useState(false);
    const [globalState, globalActions] = useGlobal();
    // const myGames = getMyGames(globalState);
    const users = getUsers(globalState);

    const columns = useMemo(() => ([
        {
            Header: '#',
            accessor: 'index',
            Cell: ({ row }) => (row.index + 1)
        },
        {
            Header: 'שם פרטי',
            accessor: 'firstName',
        },
        {
            Header: 'שם משפחה',
            accessor: 'lastName',
        },
        {
            Header: 'אימייל',
            accessor: 'email',
        },
        {
            Header: 'טלפון',
            accessor: 'phone',
        },
        {
            Header: 'תאריך רישום',
            accessor: 'createdAt',
            Cell: ({row}) => (
                utils.getHumanDate(row.original.createdAt)
              )             
        },
        // {
        //     Header: 'אשר משחק',
        //     accessor: 'approve',
        //     Cell: ({ row }) => (
        //         <button onClick={() => handleApproveGame(row.original._id)} >
        //             אשר משחק
        //         </button>)
        // },
        {
            Header: 'הצג משחקים',
            accessor: 'games',
            Cell: ({ row }) => (
                <StyledLink to={`/games?user=${row.original._id}`} >
                    <button>
                        הצג משחקים
                    </button>
                </StyledLink>
            )
        },
        // {
        //     Header: 'צור קוד',
        //     accessor: 'code',
        //     Cell: ({ row }) => (
        //         <button onClick={() => handleGenerateSecretCode(row.original._id)} >
        //             צור קוד משחק
        //         </button>
        //     )
        // }
    ]), []);

    useEffect(() => {
        const loadUsers = async () => {
            setIsLoading(true);
            const result = await axios('/api/users/')
                .catch(error => { console.log('error: ', error); return []; });
            setIsLoading(false);
            // console.log(result.data.data);
            globalActions.setUsers(result?.data?.data || []);
            console.log('FIX LINE ABOVE?');

        };

        loadUsers();
    }, []);


    return (
        <div>
            <Loader active={isLoading} text='Loading Users...' >
                {
                    users && (<SimpleTable columns={columns} data={users} />)
                }
            </Loader>
        </div>
    )


}

export default AdminUsers;