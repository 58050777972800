import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import FlexRow from './common/FlexRow';
import useGlobal from '../store';
import { getAnswer } from '../store/selectors';
import EditContent from './EditContent';
import InputField from './common/InputField';

const EditorRemark = styled.div`
    color: #999999;
`;

function EditAnswerPage({ questionId, answerMode }) {

    const { t } = useTranslation();
    const [globalState, globalActions] = useGlobal();

    const contentPath = `questions[${questionId}].answer.content`;
    const contentAfterAnswerPath = `questions[${questionId}].answer.contentAfterAnswer`;
    const answer = getAnswer(globalState, questionId);

    if (answerMode === 'answer') {
        return (
            <React.Fragment>
                <div style={ {width: '300px', margin: '0 auto'}} >
                    <br />
                <FlexRow>
                    <InputField label={t('ge.ansewr.right.answer')} value={answer} onChange={(e) => globalActions.gameEditorSetAnswer(questionId, e.target.value)} placeholder={t('ge.ansewr.right.answer')}  />
                </FlexRow>
                <EditorRemark>
                    {t('ge.ansewr.right.ansewr.text1')}<br /><br />
                    {t('ge.ansewr.right.ansewr.text2')}<br />
                    {t('ge.ansewr.right.ansewr.text3')}
                    </EditorRemark>

                </div>
            </React.Fragment>
        )
    }

    if (answerMode === 'answer-before') {
        return (
            <React.Fragment>
                
                <EditContent path={contentPath} />
                <EditorRemark>
                    {t('ge.ansewr.text1')}<br />
                    {t('ge.ansewr.text2')}<br />
                    {t('ge.ansewr.text3')}
                    </EditorRemark>
            </React.Fragment>
        )
    }

    if (answerMode === 'answer-after') {
        return (
            <React.Fragment>
                <EditContent path={contentAfterAnswerPath} />
                <EditorRemark>
                    {t('ge.ansewr.after.text1')}<br/>
                    {t('ge.ansewr.after.text2')}
                    </EditorRemark>
            </React.Fragment>
        )
    }

    return null; 
}

export default EditAnswerPage;