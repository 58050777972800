import React, {useEffect} from 'react';
import styled from '@emotion/styled';
import { useParams, useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { Link } from "react-router-dom";


import logoSplashWithText from './../images/logo_splash_with_text.png';


const Wrapper = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    background-color: #162447;

    img {
        width: 273px;
    }
`;

function Splash(props) {

    const location = useLocation();

    useEffect(() => {
        const timer = setTimeout( () => {
            document.location.href="/games";
        }, 2000);

        return () => clearTimeout(timer);
    },[location]);

    return (
        <Wrapper>
            <Link to="/games" >
            <img src={ logoSplashWithText } />
            </Link>
        </Wrapper>
    )
}

export default Splash;