import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Modal from 'react-modal';

// import useFetch from 'use-http'
import axios from 'axios';
import { get, orderBy } from 'lodash/fp';
import utils, { calculateGameScore } from './../utils';

import { getFormattedCurrency } from './../utils';

import Loader from './../components/common/Loader';

// import defaultGameCover from './../images/placeholder-150x150.png';
import { getMyGames, getMyActiveGames } from '../store/selectors';
import useGlobal from '../store';
import styled from '@emotion/styled';
import GameRow from '../components/GameRow';
import FlexRow from '../components/common/FlexRow';
import SimpleTable from '../components/common/simple-table/SimpleTable';
import GameStatistic from '../components/GameStatistic';

const StyledLink = styled(Link)`
    text-decoration: 'none';

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;


function MyActiveGames(props) {

  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTrigger, setSearchTrigger] = useState(0);
  const [progressType, setProgressType] = useState('');
  const [selectedGameOption, setSelectedGameOption] = useState();
  const [selectedActiveGame, setSelectedActiveGame] = useState();

  const [globalState, globalActions] = useGlobal();
  const myGames = getMyGames(globalState);
  const myActiveGames = getMyActiveGames(globalState);


  const handleGameChanged = (gameOption) => {
    //        console.log('handleGameChanged() gameOption: ', gameOption);
    setSelectedGameOption(gameOption);
  }

  const handleProgressTypeChanged = (e) => {
    setProgressType(e.target.value);
  }

  const handleSelectedActiveGame = (rowData) => {
    setSelectedActiveGame(rowData);
    setIsModalOpen(true);
  }

  const columns = useMemo(() => ([
    {
      Header: '#',
      accessor: 'index',
      Cell: ({ row }) => (row.index + 1)
    },
    {
      Header: 'Game',
      accessor: 'game_name',
    },
    {
      Header: 'Start Time',
      accessor: 'startTime',
      Cell: ({ row }) => (
        utils.getHumanDateTime(row.original.startTime)
      )
    },
    {
      Header: 'End Time',
      accessor: 'endTime',
      Cell: ({ row }) => (
        utils.getHumanDateTime(row.original.endTime)
      )
      //   Cell: ({ row }) => (
      //       <img src={row.original.company_logo} width="90" height="90" alt=""/>
      //   )
    },
    {
      Header: 'Total Time',
      accessor: 'totalTime',
      Cell: ({ row }) => (
        utils.getTimeDiff(row.original.startTime, row.original.endTime || row.original.lastUpdate)
      )
    },
    {
      Header: 'Game Secret',
      accessor: 'game_secret'
    },
    {
      Header: 'Last Update',
      accessor: 'lastUpdate',
      Cell: ({ row }) => (
        utils.getHumanDateTime(row.original.lastUpdate)
      )
    },
    {
      Header: '# Questions',
      accessor: 'questions',
      Cell: ({ row }) => (row.original.questions ? row.original.questions.length : 0)
    },
    {
      Header: 'Score',
      accessor: 'score',
      Cell: ({ row }) => (row.original.score || 0)
    },
    {
      Header: 'Details',
      accessor: 'data',
      Cell: ({ row }) => (<div onClick={() => handleSelectedActiveGame(row.original)}>Details</div>)
    }
  ]), []);

  const closeModal = () => {
    setIsModalOpen(false);
  }

  useEffect(() => {
    const loadMyGames = async () => {
      setIsLoading(true);
      const result = await axios('/api/games/my-games')
        .catch(error => { console.log('error: ', error); return []; });
      setIsLoading(false);
      globalActions.setMyGames(result.data || []);
    };

    loadMyGames();
  }, []);

  useEffect(() => {
    const loadMyActiveGames = async () => {
      setIsLoading(true);
      setTimeout(() => { setIsLoading(false); }, 1000);
      const gameId = selectedGameOption && selectedGameOption.value || '';
      const url = `/api/games/my-active-games?gameId=${gameId}&progressType=${progressType}`;
      const result = await axios(url)
        .catch(error => { console.log('error: ', error); return []; });
      setIsLoading(false);
      const enrichedActivities = result.data && result.data.map(d => ({ ...d, score: calculateGameScore(d) }));
      globalActions.setMyActiveGames(orderBy('startTime', 'desc', enrichedActivities) || []);
    };
    if (searchTrigger !== 0) {
      loadMyActiveGames();
    }

  }, [searchTrigger]);


  const gamesOptions = myGames && myGames.map((game) => ({ value: game._id, label: get('metadata.name', game) || game._id }));
  const gamesOptionsWithSelect = gamesOptions && [{ value: null, label: 'Select' }, ...gamesOptions]

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        appElement={document.getElementById('root')}
      >
        <GameStatistic activeGameData={selectedActiveGame} />
      </Modal>

      <Loader active={isLoading} text='Loading Your Games...' >
        <label>
          <input type="radio" name="progressType" value="" onChange={handleProgressTypeChanged} />
          <span>הכל</span>
        </label>
        <label>
          <input type="radio" name="progressType" value="started" onChange={handleProgressTypeChanged} />
          <span>התחילו</span>
        </label>
        <label>
          <input type="radio" name="progressType" value="not_started" onChange={handleProgressTypeChanged} />
          <span>לא התחילו</span>
        </label>
        <label>
          <input type="radio" name="progressType" value="ended" onChange={handleProgressTypeChanged} />
          <span>הסתיימו</span>
        </label>

        <br />
        <Select
          value={selectedGameOption}
          options={gamesOptionsWithSelect}
          onChange={handleGameChanged}
          placeholder={'בחירה'}
        />


        <button onClick={() => setSearchTrigger(searchTrigger + 1)} >חפש</button>
        <br /><br />

        {
          myActiveGames && (<SimpleTable columns={columns} data={myActiveGames} />)
        }
      </Loader>
    </div>
  )


}

export default MyActiveGames;