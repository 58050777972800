import React, { useEffect, useState, useMemo } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { get } from 'lodash/fp';
import Loader from './../components/common/Loader';
import { getMyGames, getIsSelfPayment } from '../store/selectors';
import useGlobal from '../store';
import styled from '@emotion/styled';
import AdminInputField from '../components/common/AdminInputField';
import SimpleTable from '../components/common/simple-table/SimpleTable';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
    width: 300px; 
    display: flex;
    flex-direction: column;
    padding: 32px;
`;


function GenerateCodes(props) {

    const [isLoading, setIsLoading] = useState(false);
    const [gameOptions, setGameOptions] = useState([]);
    const [selectedGame, setSelectedGame] = useState();
    const [code, setCode] = useState('');
    const [qty, setQty] = useState('');
    const [newCodes, setNewCodes] = useState([]);
    const { t } = useTranslation();



    const [globalState, globalActions] = useGlobal();
    const myGames = getMyGames(globalState);

    const isSelfPayment = getIsSelfPayment(globalState);

    const handleGenerateSecretCode = async (gameId) => {
        setNewCodes([]);
        const postData = {
            code,
            qty
        };
        const result = await axios.post(`/api/games/generate-codes/${gameId}`, postData);
        const codes = get('data.data', result);
        setNewCodes(codes);
        // console.log('codes: ', codes);
    }


    useEffect(() => {
        const loadAllGames = async () => {
            setIsLoading(true);
            // const result = await axios('/api/my-games');
            const result = await axios('/api/games/all-games')
                .catch(error => { console.log('error: ', error); return []; });
            setIsLoading(false);
            // console.log(result.data);
            globalActions.setMyGames(result.data || []);
        };

        loadAllGames();
    }, []);

    useEffect(() => {
        const gameOptions = myGames && myGames.map(game => ({ value: game._id, label: game.metadata.name }))
        setGameOptions(gameOptions);
    }, [myGames]);

    const handleQtyChange = (e) => {
        const newValue = e.target.value;
        const regex = /^[1-9]\d*$/;
        if (newValue === "" || regex.test(newValue)) {
            setQty(newValue);
        }
    }

    const handleSend = () => {
        const gameId = selectedGame?.value;
        if (!gameId) {
            window.alert('Please select game');
            return;
        }

        const confirmText = `You are about to generate ${qty} secret code/s for the following game: 
Game: ${selectedGame?.label} 
${code ? `With the following prefix: ${code}` : ''}
Are you sure?`;
        if (window.confirm(confirmText)) {
            handleGenerateSecretCode(gameId);    
        }
    }

    const origin = document.location.origin;
    const columns = [
        {
            Header: 'Label',
            accessor: 'label'
        },
        {
            Header: 'Data',
            accessor: 'value'
        }
    ];
    
    const gameData = selectedGame ? [
        {label: 'Game Name', value: `${selectedGame?.label}`},
        {label: 'Game URL', value: `${origin}/game/${selectedGame?.value}`}
    ]
    : [];
    const tableData = gameData.concat(newCodes && newCodes.map((value, index) => ({label: `Code-${index+1}`, value})));
    return (
        <div>
            <Loader active={isLoading} text='Loading Your Games...' >
                <Wrapper>
                <Select
                    value={selectedGame}
                    options={gameOptions}
                    onChange={(selectedOption) => setSelectedGame(selectedOption)}
                    placeholder={t('select')}
                />

                <AdminInputField label={t('bo.prefix')} value={code} dir="ltr" onChange={(e) => setCode(e.target.value)} />
                <AdminInputField label={t('bo.number.of.codes')} value={qty} onChange={handleQtyChange} />
                <AdminInputField type='button' value={t('generate')} onClick={handleSend} />
                </Wrapper>
                {
                    newCodes && newCodes.length !==0 && (
                        <div dir='ltr'>
                        <SimpleTable  columns={columns} data={tableData} />
                        </div>
                    )
                }
            </Loader>
        </div>
    )


}

export default GenerateCodes;