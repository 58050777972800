import React from 'react';
import styled from '@emotion/styled';

// const StyledButton = styled.button`
//     border: 2px solid black;
//     font-size: 1.5em;
//     font-weight: bold;
//     display: block;
//     background-color: cyan;
//     margin: 0 auto; 
//     min-width: 50%;
// `;

const Wrapper = styled.span`
    min-height: 50px; 
    line-height: 50px; 
    word-wrap: wrap none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    margin: 10px 0; 

    @media screen and (max-width: 600px) {
        width: 100%
    }

`;

const ArrowText = styled.span`
    display: inline-block;
    ${props => props.backgroundColor ? `background-color: ${props.backgroundColor};` : ''}
    height: 100%;
    padding: 0 10px; 
    
    @media screen and (max-width: 600px) {
        width: 100%;
        text-align: center;
    }
`;

const ArrowTail = styled.span`
    display: inline-block;
    position: relative;
    right: 1px;
    width: 10px; 
    height: 100%;
    ${props => props.backgroundColor ? `background-color: ${props.backgroundColor};` : ''}
    clip-path: polygon(100% 0%, 0% 50%, 100% 100%, 0% 100%, 0% 0%);
`;

const ArrowHead = styled.span`
    display: inline-block;
    position: relative;
    left: 1px;
    width: 10px; 
    height: 100%;
    ${props => props.backgroundColor ? `background-color: ${props.backgroundColor};` : ''}
    clip-path: polygon(100% 0%, 100% 100%, 0% 50%, 100% 0%);
`;

function ArrowDiv({children, backgroundColor}) {

    return (
        <React.Fragment>
            <Wrapper>
                <ArrowTail backgroundColor={backgroundColor}>&nbsp;</ArrowTail>
                <ArrowText backgroundColor={backgroundColor}>{children}</ArrowText>
                <ArrowHead backgroundColor={backgroundColor}>&nbsp;</ArrowHead>
            </Wrapper>
        </React.Fragment>
    );
  }

  export default ArrowDiv;