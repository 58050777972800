import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

const ModalContainer = styled.div`
    text-align: center;
    font-size: 24px;
    font-weight: bold;

    background-color: #e1eef0;
    border: 1px solid #5dbed1;
    border-radius: 20px; 
    padding: 20px;
`;

const ModalTitle = styled.div`
    padding-bottom: 20px; 
`;

const ModalButton = styled.span`
    text-align: center;
    padding: 0 10px; 
    border-radius: 10px; 
    cursor: pointer;
    color: white;
    background-color: #3babab;
    min-width: 75px; 
    display:inline-block;
    margin: 0 10px; 
`;


function ModalAlert({ isOpen, text, onClose, approveText}) {

    const { t } = useTranslation();

    const transApproveText = approveText ? approveText : t('alert.ok');
    
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: 'none',
            padding: '0px'
        }
    };

    return (
        <Modal style={customStyles}
            isOpen={isOpen}
            // onAfterOpen={afterOpenModal}
            appElement={document.getElementById('root')}
            onRequestClose={onClose}
            closeModal={onClose}
        >
            <ModalContainer>
                <ModalTitle>{text}</ModalTitle>
                <ModalButton onClick={onClose} >{transApproveText}</ModalButton>
            </ModalContainer>

        </Modal>
    );
}

export default ModalAlert;