import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import Content from './Content';
import Button from './common/Button';

import useGlobal from "../store";
import { getGameMetadata, getGameIntroPage, getGameStartTime, getGameEndTime } from '../store/selectors';
import RoundButton from './common/RoundButton';
import ContentWrapper from './common/ContentWrapper';

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    top: 25px;
    position: relative;
    /* text-align: center; */
    /* margin-bottom: 20px;  */
`;

function IntroPage() {

    const { t } = useTranslation();
    const [globalState, globalActions] = useGlobal();
    const gameMetadata = getGameMetadata(globalState);
    const introPage = getGameIntroPage(globalState);
    const startTime = getGameStartTime(globalState);
    const endTime = getGameEndTime(globalState);

    const introPageContent = introPage && introPage.content;
    const { name: gameName } = gameMetadata || {};


    const handleStartGame = () => {
        // globalActions.setGameStartTime();
        // globalActions.setDisplayPage('questions');
        globalActions.setDisplayPage('payment');
    }

    const handleContinueGame = () => {
        globalActions.setDisplayPage('questions');        
    }

    return (
        <React.Fragment>
            <ContentWrapper withButton>
            <h1>{gameName || t('intro.page')}</h1>
            <Content content={ introPageContent } />
            <ButtonWrapper>
            { startTime && (<RoundButton width={ 200 } text={ t('game.continue')} onClick={ handleContinueGame }></RoundButton>)}
            { !startTime && (<RoundButton width={ 200 } text={ t('game.lets.play')} onClick={ handleStartGame }></RoundButton>)}
            </ButtonWrapper>
            </ContentWrapper>
        </React.Fragment>
    );
  }

  export default IntroPage;