import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import Hints from './Hints';
import Content from './Content';
import RoundButton from './common/RoundButton';


import useGlobal from "../store";
import { getGame, getGameQuestion, getGameQuestionStatus } from '../store/selectors';
import axios from 'axios';

import Answer from './Answer';
import GameOverPage from './GameOverPage';
import Button from './common/Button';
import GameMap from './GameMap';
import { useEffect } from 'react';
import ContentWrapper from './common/ContentWrapper';
import { get } from 'lodash/fp';

const QuestionHeader = styled.div`
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    padding: 10px 0;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    top: 25px;
    position: relative;
    /* text-align: center; */
    /* margin-bottom: 20px;  */
`;

const PageTitle = styled.div`
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    padding: 10px 0;
`;

const PageText = styled.div`
    padding-bottom: 20px; 
    text-align: center;
`;

function Question({ gameId, questionIndex }) {

    const { t } = useTranslation();
    const [globalState, globalActions] = useGlobal();
    const game = getGame(globalState);
    const question = getGameQuestion(globalState, questionIndex);

    const questionStatus = getGameQuestionStatus(globalState, questionIndex);
    const [questionStatusToUse, setQuestionStatusToUse] = useState('');

    const withMap = get('metadata.map', game);

    useEffect(() => {
        // let isMounted = true;
        const setQuestionStatus = async () => {
            const storageGameProgress = JSON.parse(localStorage.getItem('active-game'));
            const { game_id, game_secret } = storageGameProgress || {};

            if (gameId === game_id) {
//                console.log('POST: set-question-status', questionStatusToUse);

                // if (isMounted){
                await axios.post(`/api/game-progress`, {
                    "action": "set-question-status",
                    "gameId": game_id,
                    "secretCode": game_secret,  // "qweqwe"
                    "questionIndex": questionIndex,
                    "questionStatus": questionStatusToUse

                });  // TODO catch error (Server 500)
                setQuestionStatusToUse('')
                // }
            }
        }

        if (questionStatusToUse && questionStatusToUse !== '') {
            setQuestionStatus();
        }

        return () => {
            // isMounted = false;
        };
    }, [questionStatusToUse])

    if (questionIndex === -1) {
        return <GameOverPage />
    }
    // console.log('withMap: ', withMap);
    // console.log('RENDER questionStatus: ', questionStatus);
    // console.log('questionIndex+1 :', questionIndex+1);
    

    if (withMap && !questionStatus) {
        return (
            <React.Fragment>
                <ContentWrapper withButton>
                    <PageTitle>{ t('question.number') } {questionIndex + 1}</PageTitle>

                    <PageText>
                        <div>{ t('go.to.question.area')}.</div>
                        <div>{ t('use.game.map')}</div>
                    </PageText>
                    <GameMap onStartQuestion={ () => setQuestionStatusToUse('STARTED') } />
                    <ButtonWrapper>
                        <RoundButton width={240} text={ t('click.to.continue')} onClick={() => { setQuestionStatusToUse('STARTED'); }}></RoundButton>
                    </ButtonWrapper>
                </ContentWrapper>
                
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <ContentWrapper withButton>
                <QuestionHeader>{ t('question.number') } {questionIndex + 1}</QuestionHeader>
                <Content content={question && question.content} />
                <Hints questionIndex={questionIndex} />
                <Answer questionIndex={questionIndex} />
            </ContentWrapper>
        </React.Fragment>
    );
}

export default Question;