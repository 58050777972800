import { get, set } from 'lodash/fp';
import Axios from 'axios';

// export const addToCounter = (store, amount) => {
//     const counter = store.state.counter + amount;
//     store.setState({ counter });
//   };

// export const subToCounter = (store, amount) => {
//     const counter = store.state.counter - amount;
//     store.setState({ counter });
// };


export const getLocalStorage = (item) => {
    try {
        return JSON.parse(localStorage.getItem(item));
    } catch (err) {
        console.log('Can\'t parse item data to JSON. item: ', item);
        return {};
    }
}

export const showQuestionHint = (store, questionIndex, hintIndex) => {
    const newStore = set(`state.gameProgress.questions[${questionIndex}].hints[${hintIndex}].isOpen`, true, store);
    const gameProgress = newStore.state.gameProgress;
    store.setState({ gameProgress });
};

export const setQuestionStatus = (store, questionIndex, questionStatus) => {
    const newStore = set(`state.gameProgress.questions[${questionIndex}].status`, questionStatus, store);
    const gameProgress = newStore.state.gameProgress;
    store.setState({ gameProgress });
};

export const setState = (store, state) => {
    store.setState(state);
};


export const setGames = (store, gamesList) => {
    const newStore = set('state.games', gamesList, store);
    const games = newStore.state.games;
    store.setState({ games });
};

export const setGame = (store, gameData) => {
    const newStore = set('state.game', gameData, store);
    const game = newStore.state.game;

    const gameProgress = {
        "startTime": "2020-04-02T21:36:15.240Z",
        "displayPage": "questions",
        "questions": [
          {
            "status": "DONE",
            "hints": [
                { "isOpen": true }
            ]
          },
          {
            "status": "SKIPPED"
          },
          {
            "status": "DONE"
          },
          {
            "status": "DONE"
          },
          {
            "status": "DONE"
          },
          {
            "status": "DONE"
          },
          {
            "status": "DONE"
          },
          {
            "status": "DONE"
          },
          {
            "status": "DONE"
          },
          {
            "status": "DONE"
          }
        ],
        "endTime": "2020-04-02T21:36:26.332Z"
      };
    store.setState({ game });
    // console.log('REMOVE NEXT LINE !!!');
    // store.setState({ game, gameProgress });
};

export const setGameProgress = (store, gameProgressData) => {
    const newStore = set('state.gameProgress', gameProgressData, store);
    const gameProgress = JSON.parse(JSON.stringify(newStore.state.gameProgress)) ;
    store.setState({ gameProgress });
};


export const setSelectedQuestion = (store, questionIndex) => {
    const newStore = set('state.game.selectedQuestion', questionIndex, store);
    const game = newStore.state.game;
    store.setState({ game });
};

export const setGameStartTime = (store) => {
    const newStore = set('state.gameProgress.startTime', new Date(), store);
    const gameProgress = newStore.state.gameProgress;
    store.setState({ gameProgress });
};

export const setGameEndTime = (store) => {
    const newStore = set('state.gameProgress.endTime', new Date(), store);
    const gameProgress = newStore.state.gameProgress;
    store.setState({ gameProgress });
};

export const setDisplayPage = (store, page) => {
    const newStore = set('state.ui.displayPage', page, store);
    const ui = newStore.state.ui;
    store.setState({ ui });
};


export const setIsLoggedIn = (store, isLoggedInUser) => {
    const newStore = set('state.isLoggedIn', isLoggedInUser, store);
    const isLoggedIn = newStore.state.isLoggedIn;
    store.setState({ isLoggedIn });
}

export const setLoggedInRole = (store, role) => {
  const newStore = set('state.isLoggedInRole', role, store);
  const isLoggedInRole = newStore.state.isLoggedInRole;
  store.setState({ isLoggedInRole });
}

export const setUserId = (store, id) => {
  const newStore = set('state.userId', id, store);
  const userId = newStore.state.userId;
  store.setState({ userId });
}

export const setIsSelfPayment = (store, isSelfPaymentUser) => {
  const newStore = set('state.isSelfPayment', isSelfPaymentUser, store);
  const isSelfPayment = newStore.state.isSelfPayment;
  store.setState({ isSelfPayment });
}



// Game - Metadata 

export const setMetadataName = (store, name) => {
    const newStore = set('state.game.metadata.name', name, store);
    const game = newStore.state.game;
    store.setState({ game });
}

export const setMetadataLocationCity = (store, locationCity) => {
    const newStore = set('state.game.metadata.locationCity', locationCity, store);
    const game = newStore.state.game;
    store.setState({ game });
}

export const setMetadataLocationText = (store, locationText) => {
    const newStore = set('state.game.metadata.locationText', locationText, store);
    const game = newStore.state.game;
    store.setState({ game });
}

export const setMetadataPrice = (store, price) => {
    const newStore = set('state.game.metadata.price', price, store);
    const game = newStore.state.game;
    store.setState({ game });
}

export const setMetadataCurrencyCode = (store, currencyCode) => {
    const newStore = set('state.game.metadata.currencyCode', currencyCode, store);
    const game = newStore.state.game;
    store.setState({ game });
}

export const setMetadataLanguage = (store, language) => {
  const newStore = set('state.game.metadata.language', language, store);
  const game = newStore.state.game;
  store.setState({ game });
}

export const setMetadataMap = (store, usingMap) => {
  const newStore = set('state.game.metadata.map', usingMap, store);
  const game = newStore.state.game;
  store.setState({ game });
}

export const setMetadataCoverImage = (store, url) => {
  const newStore = set('state.game.metadata.coverImage', url, store);
  const game = newStore.state.game;
  store.setState({ game });
}



// Game - Intro Page

export const setIntroPageContent = (store, content) => {
    const newStore = set('state.game.introPage.content', content, store);
    const game = newStore.state.game;
    store.setState({ game });
}



// Menu 

export const setIsMenuOpen = (store, isOpen) => {
  const newStore = set('state.ui.isMenuOpen', isOpen, store);
  const ui = newStore.state.ui;
  store.setState({ ui });
}


// Editor 

function array_move(arr, old_index, new_index) {
  if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
          arr.push(undefined);
      }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return [...arr]; // for testing
};

export const setUser = (store, userData) => {
  const newStore = set('state.user', userData, store);
  const user = newStore.state.user;
  store.setState({ user });
};

export const setUsers = (store, usersList) => {
  const newStore = set('state.users', usersList, store);
  const users = newStore.state.users;
  store.setState({ users });
};

export const setMyGames = (store, gamesList) => {
  const newStore = set('state.myGames', gamesList, store);
  const myGames = newStore.state.myGames;
  store.setState({ myGames });
};

export const setMyGamesFeedbacks = (store, feedbacksList) => {
  const newStore = set('state.myGamesFeedbacks', feedbacksList, store);
  const myGamesFeedbacks = newStore.state.myGamesFeedbacks;
  store.setState({ myGamesFeedbacks });
};

export const setMyActiveGames = (store, activeGamesList) => {
  const newStore = set('state.myActiveGames', activeGamesList, store);
  const myActiveGames = newStore.state.myActiveGames;
  store.setState({ myActiveGames });
};

export const gameEditorUpdateContent = (store, path, content) => {
  const newStore = set(`state.game.${path}`, content, store);
  const game = newStore.state.game;
  store.setState({ game });
}

export const gameEditorDeleteQuestion = (store, questionIndex) => {
  const questions = get('state.game.questions', store) || [];
  const newQuestions = questions.filter((q, index) => (index !== questionIndex));
  const newStore = set('state.game.questions', newQuestions, store);
  const game = newStore.state.game;
  store.setState({ game });
}

export const gameEditorMoveQuestion = (store, questionIndex, direction) => {
  const questions = get('state.game.questions', store) || [];
  if (direction === 'up' && questionIndex < 1) {
    return;
  }
  if (direction !== 'up' && questionIndex > questions.length -2 ) {
    return;
  }
  const newQuestions = array_move(questions, questionIndex, direction === 'up' ? questionIndex-1 : questionIndex+1);
  const newStore = set('state.game.questions', newQuestions, store);
  const game = newStore.state.game;
  store.setState({ game });
}

export const gameEditorUpdateHintTitle = (store, questionIndex, hintIndex, hintTitle) => {
  // const newStore = set(`state.game.questions[${questionIndex}].hints[${hintIndex}].title`, hintTitle, store);

  const newStore = set(`state.game.questions[${questionIndex}].hints[${hintIndex}].title`, hintTitle, store);
  const game = newStore.state.game;
  store.setState({ game });
}


export const gameEditorDeleteHint = (store, questionIndex, hintIndex) => {
  const hints = get(`state.game.questions[${questionIndex}].hints`, store) || [];
  const newHints = hints.filter((h, index) => (index !== hintIndex));
  const newStore = set(`state.game.questions[${questionIndex}].hints`, newHints, store);
  const game = newStore.state.game;
  store.setState({ game });
}

export const gameEditorMoveHint = (store, questionIndex, hintIndex, direction) => {
  const hints = get(`state.game.questions[${questionIndex}].hints`, store) || [];
  if (direction === 'up' && hintIndex < 1) {
    return;
  }
  if (direction !== 'up' && hintIndex > hints.length -2 ) {
    return;
  }
  const newHints = array_move(hints, hintIndex, direction === 'up' ? hintIndex-1 : hintIndex+1);
  const newStore = set(`state.game.questions[${questionIndex}].hints`, newHints, store);
  const game = newStore.state.game;
  store.setState({ game });
}


// export const gameEditorMoveUpElement = (store, path, index) => {
//   if (index > 0) {
//     const elements = get(`state.game.${path}`, store);
//     const newElements = array_move(elements, index, index-1);
//     const newStore = set(`state.game.${path}`, newElements, store);
//     const game = newStore.state.game;
//     store.setState({ game });
//   }
// }

// export const gameEditorMoveDownElement = (store, path, index) => {
//   const elements = get(`state.game.${path}`, store);
//   if (index < elements.length-1) {
//     const newElements = array_move(elements, index, index+1);
//     const newStore = set(`state.game.${path}`, newElements, store);
//     const game = newStore.state.game;
//     store.setState({ game });
//   }
// }

// export const gameEditorRemoveElement = (store, path, index) => {
//   const elements = get(`state.game.${path}`, store);
//   elements.splice(index, 1);
//   const newStore = set(`state.game.${path}`, elements, store);
//   const game = newStore.state.game;
//   store.setState({ game });
// }

export const gameEditorSetLocation = (store, path, locationData) => {
  const newStore = set(`state.game.${path}`, locationData, store);
  const game = newStore.state.game;
  store.setState({ game });
}

export const gameEditorSetAnswer = (store, questionId, answer) => {
  const newStore = set(`state.game.questions[${questionId}].answer.value`, answer, store);
  const game = newStore.state.game;
  store.setState({ game });
}

export const gameEditorSetHideSummary = (store, hide) => {
  const newStore = set(`state.game.gameoverPage.hideGameSummary`, hide, store);
  const game = newStore.state.game;
  store.setState({ game });
}

export const gameEditorSetStatus = (store, status) => {
  const newStore = set(`state.game.status`, status, store);
  const game = newStore.state.game;
  store.setState({ game });
}

