import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import EditContent from './EditContent';

const EditorRemark = styled.div`
    color: #999999;
`;
function EditIntroPage() {

    const { t } = useTranslation();

    return (
        <React.Fragment>
            <EditContent path={'introPage.content'} />
        <EditorRemark>{t('ge.content.intro')}</EditorRemark>
        </React.Fragment>
    );
}

export default EditIntroPage;