import React from 'react';
// import GameFeedback from '../components/GameFeedback';
import ContentWrapper from '../components/common/ContentWrapper';
// import LandingPageForm from '../components/LandingPageForm';


function Test(props) {

    return (
        <ContentWrapper>
            <h1>עמוד בדיקות - פידבק</h1>
            {/* <GameFeedback gameId="test" /> */}
            {/* <GameFeedback gameId="test" /> */}
            {/* <LandingPageForm /> */}
        </ContentWrapper>
    )


}

export default Test;