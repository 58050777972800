import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const ReportQuestionWrapper = styled.div`
    text-align: left;

    & span {
        padding: 0 10px; 
        border-radius: 10px; 
        cursor: pointer;
        font-weight: bold; 
        color: white;
        background-color: #3babab;

        min-width: 125px;
        display: inline-block;
        text-align: center;

    }
`;


function ReportQuestion({ onClick }) {

    const { t } = useTranslation();

    return (
        <ReportQuestionWrapper>
            <span onClick={ onClick }>{ t('report.problem')}</span>
        </ReportQuestionWrapper>
    )
  }

  export default ReportQuestion;