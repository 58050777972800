import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import ContentWrapper from '../components/common/ContentWrapper';
const PageText = styled.div`
    padding-bottom: 20px; 
`;

const SectionTitle = styled.div`
    font-weight: bold;
    text-decoration: underline;
    padding-top: 20px; 
`;


function SiteTerms(props) {

    return (
        <ContentWrapper>
            <h1>תנאי שימוש</h1>

            <section>
                <SectionTitle>הקדמה</SectionTitle>
            אתר “Street-Hunters.com” (להלן “האתר” או “האפליקציה”) הינו אתר המשתמש כאתר המשמש להפעלה ומכירה של סיורי תוכן ומשחקי חידות שטח,
            וכל שימוש בו הינו בכפוף להסכמתך לתנאי השימוש אשר יפורטו להלן.
            <br />
            הנהלת האתר שומרת לעצמה הזכות לעדכן את תנאי השימוש המוצגים להלן (בשם “תנאי השימוש”) מעת לעת.
            התכנים באתר יכולים גם הם להשתנות ולהתעדכן מעת לעת.
            במהלך השימוש באתר ובמוצריו, יש צורך בקליטה וגישה לאינטרנט.
            <br />
            למען הסר ספר, אתר זה עושה שימוש ב-Cookies, על מנת לתת שירות טוב יותר לגולשים בו,
            ועל מנת לשמור מידע על התנהגות הגולשים באתר ולא רק.
        </section>

            <section>
                <SectionTitle>תוכן האתר</SectionTitle>
            התכנים המוצעים באתר מועלים ע”י ספקי תוכן, (להלן “ספק תוכן” / “ספקי תוכן),
            ולאחר אישורם ע”י מפעילי האתר הם עוברים לבעלותם הבלעדית של “Street-Hunter.com”
            אין לעשות בהם שימוש אשר נוגד את האמור בתקנון זה.
            <br />
            יש לציין כי מרבית התכנים באתר, מועלים ע”י ספקי תוכן,
            והאחריות הבלעדית על תכנים אלה הינה על ספקי התוכן,
            ולא על האתר או מפעיליו. מפעילי האתר רשאים להסיר ככל תוכן אשר נוגד את חוקי האתר,
            ו/או עלול לפגוע בצד שלישי כלשהו ו/או לפגוע בזכויות יוצרים.
            <br />
            במידה ונתקלתם בבעיה בתוכן, יש ליידע את מפעילי האתר בהקדם.
        </section>

            <section>
                <SectionTitle>בטיחות בשטח</SectionTitle>
            מהלך הפעילות, הסיורים והמשחקים מבוצעים בשטח פתוח.
            יש לנקוט משנה זהירות במהלך המשחק כולו.
            ובכלל זה (ולא רק) יש לשים לב לאן הולכים,
            אין להיכנס לשטחים פרטיים,
            כמו-כן, יש לתת תשומת לב במהלך חציית כבישים,
            ולא לנוע מבלי לשים לב לתנאי השטח. (עיניים בשטח, ולא בטלפון/אפליקציה)
            <br />
            היציאה לפעילות והשמירה על הבטיחות היא באחריותכם הבלעדית
            ולא תהיה ל”Street-Hunters.com” כל אחריות על המשתתפים או המשתמשים במוצר.
            השימוש במוצרי האתר מחייב ליווי של מבוגר [מעל גיל 18] במידה ומדובר בילדים עד גיל 14.
            בכל מקרה לא תהיה כל תביעה, טענה או דרישה כלפי צוות האתר “Street-Hunters.com”.
            <br />
            יש להתנהג בצורה נאותה התואמת למקום בו מתבצע הסיור או המשחק, לא להרעיש, לא ללכלך או לפגוע בסביבה.
        </section>

        <section>
            <SectionTitle>ניהול משתמשים</SectionTitle>
            במקרים בהם יש צורך בהזנה של פרטיך האישיים כדוגמת שם או כתובת הדואר האלקטרוני שלך. 
            מםעילי האתר עשויים להשתמש במידע אישי זה בהתאם למדיניות הפרטיות שלהאתר ועל-פי הוראות כל דין.
            <br />
            מפעילי האתר שומרים לעצמם את הזכות לחסום כל משתמש ובכל דרך ללא צורך לספק תירוץ אשר מקובל על הגולש. 
            וזאת במטרה להטיב עם פעילות האתר. 
            <br />
            מפעילי האתר יעשו כל שביכולתם להגן על פרטי המשתמשים הרשומים באתר והמשתמשים באתר. 
            במקרים בהם יעלה בידיו של צד שלישי להשיג גישה למידע הקשור לאתר זה, 
            מוסכם בזאת כי לא למשתמשים תהיה כל תביעה, טענה או דרישה כלפי צוות האתר “Street-Hunters.com”.
        </section>

        <section>
            <SectionTitle>שותפים עסקיים - בוני משחקים</SectionTitle>
            כל אחד יכול לבנות משחק, והבנייה היא חינמית לחלוטין. 
            ברגע שמשחק מאושר לשימוש, ונעשה שימוש במשחק בתשלום, מתבצעת "חלוקת רווחים" בין בונה המשחק ובין האתר. 
            <br />
            מרבית הכסף עובר אליכם, בוני המשחקים, למעט עמלת תפעול. 
            <br />
            עמלת התפעול היא 25% ממחיר המשחק (ללא מע"מ), או 30 ש"ח - הגבוה מביניהם. 
            <br />
            כלומר, עבור משחק במחיר 100 ש"ח (ללא מע"מ), יועברו לבונה המשחק 70  ש"ח.
            <br />
            עבור משחק במחיר של 160 ש"ח (ללא מע"מ), יועברו לבונה המשחק 120 ש"ח.
            <br/><br/>
            במידה והגיעו אל המשחק עם "קוד משווק",
            אז יועברו 10% ממחיר המשחק למשווק, וסכום זה ינוקה מהסכום המועבר לבוני המשחק.
            <br />
            הכסף יועבר בסיומו של כל חודש עוקב, לבונה המשחק. 
            <br/>
            העברות של סכום הקטן מ-100 ש"ח, ידחו לחודש שלאחר מכן. 
            <br />
            בכל שלב ניתן יהיה לראות את מצב החשבון בעמוד המיועד לכך, 
            ואשר הכניסה אליו היא באמצעות סיסמא בלבד. 
        </section>

        <section>
            <SectionTitle>שותפים עסקיים - משווקים</SectionTitle>
            ניתן לפרסם את האתר בצורות חוקיות בלבד, ועם "קוד משווק" יחודי.
            <br />
            כמשווק, בכל פעם שמישהו אשר הגיע לאתר עם קוד המשווק שלך, 
            מבצע רכישה כלשהי, אז 10% מסכום הרכישה (ללא מע"מ) נצבר לזכותך.
            <br />
            הכסף יועבר בסיומו של כל חודש עוקב, למשווק. 
            <br/>
            העברות של סכום הקטן מ-100 ש"ח, ידחו לחודש שלאחר מכן. 
            <br />
            בכל שלב ניתן יהיה לראות את מצב החשבון בעמוד המיועד לכך, 
            ואשר הכניסה אליו היא באמצעות סיסמא בלבד. 
        </section>

        <section>
            <SectionTitle>ביטולים והחזר כספי</SectionTitle>
            במידה ולא השתמשתם בשירותי האתר אשר נרכשו באתר, 
            ב-14 הימים מיום הרכישה ותרצו לבטל את הרכישה, 
            אנחנו נחזיר לכם את כספכם.
            <br />
             שימוש במוצר מוגדר בשלב הפעלת המוצר ע"י קוד הפעלה.
             <br/>
             בכל מקרה של זיכוי דמי תפעול וסליקה של 10 ש”ח ו/או 5% משווי העסקה (הגבוה מביניהם) לא יוחזרו.
        </section>

        <section>
            <SectionTitle>ברירת דין ומקום שיפוט</SectionTitle>
            על תנאי השימוש, לרבות מדיניות הפרטיות יחולו אך ורק דיני מדינת ישראל. 
            <br />
            מקום השיפוט הבלעדי בגין כל דבר ועניין הנובע מהסכם זה ו/או מהאתר  ו/או ממוצריו, 
            הוא בבתי המשפט המוסמכים במחוזות תל-אביב והמרכז בישראל.
        </section>




        </ContentWrapper>
    )
}

export default SiteTerms;