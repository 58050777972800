import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import ContentWrapper from '../components/common/ContentWrapper';
import MyTree from '../components/MyTree';
import FileUploader from '../components/common/FileUploader';
import GameFeedback from '../components/GameFeedback';

const PageText = styled.div`
    padding-bottom: 20px; 
`;


function Home(props) {

    const { t } = useTranslation();

    return (
        <ContentWrapper>
            <h1>{ t('page.title.home')}</h1>
            <PageText>
                { t('page.not.ready') } - { t('click')}&nbsp;
                <Link to="/games" >{ t('here') }</Link>
            </PageText>

{/* <GameFeedback /> */}
            {/* <MyTree /> */}
            {/* <FileUploader onSelect={ (url) => alert(url)} /> */}
        </ContentWrapper>
    )
}

export default Home;